import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AppContext } from "../context";
import { useZxing } from "react-zxing";
import camera from "../img/camera.png";
import {
  addImageToList,
  QRDataUpload,
  setLog,
  userLogs,
  patLogs,
  translateText,
} from "../context/utils";
import Rotate from "./Rotate";
import Button from "./Button";
// import ReactGA from "react-ga4";

let imageCaptured = false;
let image = {};
let resultSent = false;
let cameraStarted = false;
let track = null;

const QR = () => {
  const {
    stepCovered,
    setStepCovered,
    setScreen,
    setCondition,
    condition,
    currentBlock,
    mandatory,
    setMandatory,
    showQRCamera,
    setShowQRCamera,
    previewQRImg,
    setPreviewQRImg,
    isLandscape,
    setLandscape,
    config,
    inspectionId,
  } = useContext(AppContext);

  const constraints = {
    video: Object.keys(config).includes("aspectRatio")
      ? {
          width: Object.keys(config).includes("resolution")
            ? config["resolution"]["width"]
            : 1920,
          height: Object.keys(config).includes("resolution")
            ? config["resolution"]["height"]
            : 1080,
          facingMode: "environment",
          aspectRatio: 4 / 3,
          zoom: {
            ideal: Object.keys(config).includes("zoom")
              ? config["zoom"]
                ? 0.5
                : 1
              : 0.5,
          },
        }
      : {
          width: Object.keys(config).includes("resolution")
            ? config["resolution"]["width"]
            : 1920,
          height: Object.keys(config).includes("resolution")
            ? config["resolution"]["height"]
            : 1080,
          facingMode: "environment",
          zoom: {
            ideal: Object.keys(config).includes("zoom")
              ? config["zoom"]
                ? 0.5
                : 1
              : 0.5,
          },
        },
    audio: false,
  };

  const getTranslateText = (text) => {
    if (Object.keys(config["language"]).includes(text)) {
      return config["language"][text];
    } else {
      // const data = translateText(text, config["language"]["langCode"]);
      return text;
    }
  };

  const videoRef = useRef();
  const canvasRef = useRef();
  const popupRef = useRef();
  const backbtnRef = useRef();

  const [qrResult, setQrResult] = useState("");
  // ReactGA.send({
  //   hitType: "pageview",
  //   page: "QR reader Page",
  //   title: "QR reader Page",
  // });
  userLogs({ position: 8, last_page: "qr", inspectionId });
  // setStepCovered([...stepCovered]);
  patLogs("Entered to QR reader", inspectionId);
  const land = () => window.innerWidth > window.innerHeight * 1.2;

  window.onresize = () => {
    setLandscape(land());
    if (cameraStarted) startCamera();
  };

  const startCamera = () => {
    cameraStarted = true;
    setShowQRCamera(false);
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      window.stream = stream;
      track = stream.getTracks()[0];
      videoRef.current.srcObject = stream;
    });
  };

  const { ref } = useZxing({
    onResult(result) {
      if (resultSent) return;
      resultSent = true;
      patLogs("QRCode detected: " + result.getText(), inspectionId);
      setQrResult(result.getText());
      popupRef.current.style.display = "flex";
      backbtnRef.current.style.display = "none";
    },
    onError(err) {
      console.log(err);
    },
    constraints: constraints,
  });

  const rescan = () => {
    // patLogs("rescan button clicked", inspectionId);
    popupRef.current.style.display = "none";
    backbtnRef.current.style.display = "block";
    setTimeout(() => (resultSent = false), 2000);
  };

  const proceed = () => {
    patLogs("clicked proceed button", inspectionId);
    QRDataUpload(qrResult);
    if (currentBlock.capture) {
      startCamera();
    } else {
      if (currentBlock["mandatory"]) {
        let temp1 = mandatory;
        temp1[currentBlock["id"]] = "completed";
        setMandatory(temp1);
      }
      let temp = condition;
      temp[currentBlock["id"]] = "completed";
      setCondition(temp);
      setLog(`${currentBlock.id} was captured`);
      setScreen("menu");
      resultSent = false;
    }
  };

  const addImage = () => {
    if (imageCaptured) return;
    track.stop();
    window.stream = null;
    cameraStarted = false;
    imageCaptured = true;
    setLog(`${currentBlock.tag} was captured`);
    addImageToList(image);
    setPreviewQRImg(null);
    if (currentBlock["mandatory"]) {
      let temp1 = mandatory;
      temp1[currentBlock["id"]] = "completed";
      setMandatory(temp1);
    }
    let temp = condition;
    temp[currentBlock["id"]] = "completed";
    setCondition(temp);
    setScreen("menu");
  };

  const takePicture = () => {
    patLogs("capture image button clicked", inspectionId);
    const video = videoRef.current;
    const cnv = canvasRef.current;
    cnv.width = video.videoWidth;
    cnv.height = video.videoHeight;
    cnv.getContext("2d").drawImage(video, 0, 0);
    const imgData = cnv.toDataURL("image/jpeg");
    let region = Object.keys(config["video-page"]).includes("clientRegion")
      ? config["video-page"]["clientRegion"]
      : "eu";
    image = {
      name: `${currentBlock.tag}.jpg`,
      imgData: cnv.toDataURL("image/jpeg"),
      tag: currentBlock.tag,
      web_region: null,
      angle: null,
      region: region,
      videoData: null,
      dialStatus: null,
    };
    setPreviewQRImg({ id: showQRCamera, imgData });
  };

  const handleError = (err) => {
    // alert(err);
  };

  const goBack = () => {
    cameraStarted = false;
    setScreen("menu");
  };

  useEffect(() => {
    setLandscape(land());
    if (!showQRCamera) {
      startCamera();
    }
  }, []);

  return (
    <Fragment>
      {showQRCamera ? (
        <div className="qr">
          <video ref={ref} style={{ backgroundColor: "black" }} />
          <div className="qr-border"></div>
          <div ref={backbtnRef} className="back-btn" style={{ zIndex: 90 }}>
            <i className="fas fa-arrow-left fa-2x" onClick={goBack}></i>
          </div>
          <div ref={popupRef} className="qr-popup" style={{ display: "none" }}>
            <h4>
              {config["qr"]["scan"]}: {qrResult}
            </h4>
            <div className="modal-btn-container" style={{ display: "flex" }}>
              <Button text={config["qr"]["rescan-btn-text"]} onclick={rescan} />
              <Button
                text={
                  currentBlock.capture
                    ? config["qr"]["capture-text"]
                    : config["qr"]["proceed-text"]
                }
                onclick={proceed}
              />
            </div>
          </div>
        </div>
      ) : !isLandscape ? (
        <Rotate />
      ) : (
        <>
          <div className="zero-container">
            <video
              ref={videoRef}
              className="zero-video"
              autoPlay
              playsInline
            ></video>
            <div
              className="zero-button-click"
              onClick={takePicture}
              style={{
                backgroundImage: `url(${camera})`,
                backgroundSize: "37px 37px",
                backgroundRepeat: "no-repeat",
                objectFit: "cover",
                backgroundPosition: "center",
              }}
            ></div>
            <div className="back-btn">
              <i className="fas fa-arrow-left fa-2x" onClick={goBack}></i>
            </div>
            <canvas ref={canvasRef} className="zero-canvas"></canvas>
            {previewQRImg && (
              <div className="zero-preview">
                <img
                  className="zero-previewImg zero-img"
                  src={previewQRImg.imgData}
                />
                <div className="zero-previewBtn">
                  <div
                    className="fas fa-times fa-2x vin-btn-retake"
                    onClick={() => setPreviewQRImg(null)}
                  ></div>
                  <div
                    className="fas fa-check fa-2x vin-btn-next"
                    onClick={addImage}
                  ></div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </Fragment>
  );
};

export default QR;
