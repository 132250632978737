import * as React from "react";
import zizoArrow from "../../img/arrow.png";
const CameraRedBox = (props) => {
    return (
        <svg
            width={844}
            height={295}
            viewBox="30 55 844 285"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M844 -50L0 -50L0 450L900 450L900 64ZM405 64L115 64L115 288L715 288V64Z"
                fill="black"
                fillOpacity={0.63}
            />
            <path
                d="M101 131V50L201 50"
                stroke="#FF2A2A"
                strokeWidth={4}
                strokeLinecap="round"
            />
            <path
                d="M101 203V305H201"
                stroke="#FF2A2A"
                strokeWidth={4}
                strokeLinecap="round"
            />
            <path
                d="M591 50L733 50L733 139"
                stroke="#FF2A2A"
                strokeWidth={4}
                strokeLinecap="round"
            />
            <path
                d="M591 305L733 305L733 192"
                stroke="#FF2A2A"
                strokeWidth={4}
                strokeLinecap="round"
            />
            <image
                href={zizoArrow}
                x="590"
                y="-70"
                width="200"
                height="100"
                ref={props["arrowLeft1"]}
                className="zizo_arrow_left-flex"
            />
            <image
                href={zizoArrow}
                x="665"
                y="-70"
                width="200"
                height="100"
                ref={props["arrowLeft2"]}
                className="zizo_arrow_left-flex"
            />
            <image
                href={zizoArrow}
                x="-150"
                y="-340"
                width="200"
                height="100"
                ref={props["arrowRight1"]}
                className="zizo_arrow_right-flex"
            />
            <image
                href={zizoArrow}
                x="-90"
                y="-340"
                width="200"
                height="100"
                ref={props["arrowRight2"]}
                className="zizo_arrow_right-flex"
            />
        </svg>
    );
};
export default CameraRedBox;
