import React, { useContext, useRef, useState, useEffect } from "react";
import { AppContext } from "../context";
import Button from "./Button";
import Rotate from "./Rotate";
import PdfThumbnail from "./PdfThumbnail";
import { addImageToList, pdfUpload, translateText } from "../context/utils";
import * as pdfjsLib from "pdfjs-dist";
import delete_icon from "../img/delete_icon.svg";
import pdf_icon from "../img/pdf_icon.svg";

let count = 0;

const ImageUpload = () => {
  const {
    setLandscape,
    setScreen,
    isLandscape,
    currentBlock,
    storedImage,
    setStoredImage,
    currentImage,
    setCurrentImage,
    setMandatory,
    setCondition,
    condition,
    mandatory,
    config,
    inspectionId,
    clientId,
  } = useContext(AppContext);

  const fileInputRef = useRef(null);
  const popupRef = useRef(null);
  const waringpopuup = useRef(null);

  const land = () => window.innerWidth > window.innerHeight * 1.2;
  const [errorMssg, setError] = useState("");
  const [typeOfUpload, setTypeOfUpload] = useState(
    Object.keys(config).includes("imageUpload")
      ? config["imageUpload"]["type"] === "pdf"
        ? "pdf"
        : "img"
      : "img"
  );
  const [pdfFileData, setPdfFileData] = useState({});
  window.onresize = () => {
    setLandscape(land());
    setStoredImage(storedImage);
  };

  const getTranslateText = (text) => {
    if (Object.keys(config["language"]).includes(text)) {
      return config["language"][text];
    } else {
      // const data = translateText(text, config["language"]["langCode"]);
      return text;
    }
  };

  const validatePdf = async (file) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
      // If the PDF can be loaded without errors, it's valid
      if (pdf.numPages > 0) {
        return true;
      }
      return false;
    } catch (error) {
      console.error("PDF validation error:", error);
      return false;
    }
  };

  const handleFiles = async (event) => {
    const files = event.target.files;
    const maxCount = currentBlock["count"];
    const remainingSlots = maxCount - storedImage.length;
    console.log("files", files);
    if (files.length > 0 && remainingSlots > 0) {
      const filesToUpload = Array.from(files).slice(0, remainingSlots);
      if (["porto_seguros", "ss321gn"].includes(clientId)) {
        for (const file of filesToUpload) {
          if (
            file.type.startsWith("image/") ||
            file.type === "application/pdf"
          ) {
            try {
              if (file.type.startsWith("image/")) {
                // Handle image files
                const imageFile = await convertToJpegBase64(file);
                if (imageFile) {
                  setStoredImage((prevImages) => [
                    ...prevImages,
                    { imgData: imageFile, id: count },
                  ]);
                  count += 1;
                  if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                  }
                }
              } else if (file.type === "application/pdf") {
                console.log("file", file);
                setPdfFileData(file);
                // Handle PDF files
                const isValidPdf = await validatePdf(file);
                if (isValidPdf) {
                  const pdfData = await convertPdfToBase64(file);
                  if (pdfData) {
                    setStoredImage((prevImages) => [
                      ...prevImages,
                      { pdfData, id: count },
                    ]);
                    count += 1;
                  }
                  if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                  }
                } else {
                  setStoredImage([]);
                  setError(
                    Object.keys(config).includes("imageUpload")
                      ? config["imageUpload"]["fileCorrupted"]
                      : getTranslateText("The file is corrupted or invalid.")
                  );
                  setTimeout(() => {
                    setError("");
                  }, 5000);
                }
              }
            } catch (error) {
              setStoredImage([]);
              setError(
                Object.keys(config).includes("imageUpload")
                  ? config["imageUpload"]["fileCorrupted"]
                  : getTranslateText("The file is corrupted or invalid.")
              );
              setTimeout(() => {
                setError("");
              }, 5000);
              console.error(error.message);
            }
          } else {
            setStoredImage([]);
            setError(
              Object.keys(config).includes("imageUpload")
                ? config["imageUpload"]["fileCorrupted"]
                : getTranslateText("The file is corrupted or invalid.")
            );
            setTimeout(() => {
              setError("");
            }, 5000);
          }
        }
      } else {
        for (const file of filesToUpload) {
          if (typeOfUpload === "img") {
            if (file.type.startsWith("image/")) {
              // Handle image files
              const imageFile = await convertToJpegBase64(file);
              if (imageFile) {
                setStoredImage((prevImages) => [
                  ...prevImages,
                  { imgData: imageFile, id: count },
                ]);
                count += 1;
              }
            }
          }
          if (typeOfUpload === "pdf") {
            if (file.type === "application/pdf") {
              // Handle PDF files
              try {
                const isValidPdf = await validatePdf(file);
                if (isValidPdf) {
                  const pdfData = await convertPdfToBase64(file);
                  if (pdfData) {
                    setStoredImage((prevImages) => [
                      ...prevImages,
                      { pdfData, id: count },
                    ]);
                    count += 1;
                  }
                } else {
                  setError(
                    Object.keys(config).includes("imageUpload")
                      ? config["imageUpload"]["fileCorrupted"]
                      : getTranslateText("The file is corrupted or invalid.")
                  );
                  setTimeout(() => {
                    setError("");
                  }, 5000);
                  console.log("The PDF file is corrupted or invalid.");
                }
              } catch (error) {
                setError(
                  Object.keys(config).includes("imageUpload")
                    ? config["imageUpload"]["fileCorrupted"]
                    : getTranslateText("The file is corrupted or invalid.")
                );
                setTimeout(() => {
                  setError("");
                }, 5000);
                console.log("Error processing PDF file:", error);
              }
            }
          }
        }
      }
    }
  };

  const convertPdfToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const convertToJpegBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;

        img.onload = () => {
          //if (img.width < 1920 || img.height < 1080) {
          // if (img.width < 2000 && img.height < 2000) {   //sanchit sir ask to enter 2000 as a raw
          //   setError("The image resolution is too high. The image must be in landscape orientation and its resolution must be under 2000p.")
          //   setTimeout(()=>{setError("")},5000)
          //   resolve(false);
          //   return;
          // }

          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, img.width, img.height);

          const jpegBase64 = canvas.toDataURL("image/jpeg", 1);
          resolve(jpegBase64);
        };

        img.onerror = () => {
          reject(new Error("Failed to load image"));
        };
      };

      reader.onerror = () => {
        reject(new Error("Failed to read file"));
      };
    });
  };

  const openFilePicker = () => {
    fileInputRef.current.click();
  };

  const openPopUp = (e) => {
    setCurrentImage(e);
    if (popupRef.current) {
      popupRef.current.style.display = "flex";
    }
  };

  const removeImage = () => {
    if (popupRef.current) {
      popupRef.current.style.display = "none";
    }
    setStoredImage((prevImages) =>
      prevImages.filter((item) => item.id !== currentImage.id)
    );
  };

  const finish = () => {
    storedImage.map((e) => {
      if (["porto_seguros", "ss321gn"].includes(clientId)) {
        pdfUpload(clientId, inspectionId, e);
      } else if (Object.keys(e).includes("imgData")) {
        const image = {
          name: `${currentBlock["tag"]}.jpg`,
          imgData: e.imgData,
          tag: currentBlock["tag"],
          web_region: null,
          angle: null,
          region: "eu",
          videoData: null,
          dialStatus: null,
          currentBlockName : currentBlock["id"]
        };
        addImageToList(image);
      } else {
        pdfUpload(clientId, inspectionId, e);
      }
    });

    if (currentBlock["mandatory"]) {
      let temp1 = mandatory;
      temp1[currentBlock["id"]] = "completed";
      setMandatory(temp1);
    }
    let temp = condition;
    temp[currentBlock["id"]] = "completed";
    setCondition(temp);
    setScreen("menu");
  };
  const bytesToMB = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2) + " MB";
  };
  useEffect(() => {
    if (
      ["porto_seguros", "ss321gn"].includes(clientId) &&
      storedImage.length != 0
    ) {
      waringpopuup.current.style.display = "none";
    } else {
      waringpopuup.current.style.display = "flex";
    }
    setInterval(() => {
      console.log(storedImage);
    }, 1000);
  }, [storedImage]);

  return (
    <>
      <div
        className="uploadMainDiv"
        style={{
          height: ["porto_seguros", "ss321gn"].includes(clientId)
            ? "90dvh"
            : "100dvh",
        }}
      >
        {!isLandscape && (
          <div
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "100dvw",
              height: "100dvh",
              backgroundColor: "white",
              zIndex: 1,
            }}
          >
            <Rotate />
          </div>
        )}
        <div
          className="ImageDiv"
          style={
            ["porto_seguros", "ss321gn"].includes(clientId)
              ? {
                  overflow: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : {}
          }
        >
          {storedImage.map((e) =>
            Object.keys(e).includes("imgData") &&
            ["porto_seguros", "ss321gn"].includes(clientId) ? (
              <div style={{ position: "relative" }}>
                <img
                  key={e.id}
                  src={e.imgData}
                  alt={`Uploaded ${e.id}`}
                  style={{
                    height: "60dvh",
                    width: "40dvw",
                    margin: "10px",
                    borderRadius: "5px",
                    boxShadow: "2px 2px 7px #8a8686",
                  }}
                />
                <button
                  style={{
                    height: "15dvh",
                    border: "none",
                    width: "30dvw",
                    position: "absolute",
                    top: "80%",
                    left: "50%",
                    background: "#dcdcdc",
                    transform: "translate(-50%, -80%)",
                    borderRadius: "10px",
                    fontSize: "20px",
                  }}
                  onClick={() => {
                    // waringpopuup.current.style.display = "flex";
                    setStoredImage([]);
                  }}
                >
                  <img src={delete_icon} style={{ marginRight: "10px" }} />
                  Excluir Documento
                </button>
              </div>
            ) : ["porto_seguros", "ss321gn"].includes(clientId) ? (
              <div
                style={{
                  boxShadow: "2px 2px 7px #8a8686",
                  height: "60dvh",
                  width: "40dvw",
                  margin: "10px",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  flexDirection: "column",
                }}
              >
                <img src={pdf_icon} />
                <div>
                  <p
                    style={{
                      margin: "0px",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    {pdfFileData.name}
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      fontSize: "12px",
                    }}
                  >
                    Tamanho: {bytesToMB(pdfFileData.size)}
                  </p>
                </div>
                <button
                  style={{
                    height: "15dvh",
                    border: "none",
                    width: "30dvw",
                    background: "#dcdcdc",
                    borderRadius: "10px",
                    fontSize: "20px",
                  }}
                  onClick={() => {
                    // waringpopuup.current.style.display = "flex";
                    setStoredImage([]);
                  }}
                >
                  <img src={delete_icon} style={{ marginRight: "10px" }} />
                  Excluir Documento
                </button>
              </div>
            ) : Object.keys(e).includes("imgData") ? (
              <img
                key={e.id}
                src={e.imgData}
                alt={`Uploaded ${e.id}`}
                style={{
                  height: "80px",
                  width: "130px",
                  margin: "10px",
                  borderRadius: "5px",
                  border: "1px solid black",
                }}
                onClick={() => openPopUp(e)}
              />
            ) : (
              <div
                key={e.id}
                src={e.pdfData}
                alt={`Uploaded ${e.id}`}
                style={{
                  height: "75dvh",
                  width: "90dvw",
                  margin: "10px",
                  borderRadius: "5px",
                  border: "1px solid black",
                }}
                onClick={() => openPopUp(e)}
              >
                <PdfThumbnail
                  base64Pdf={e.pdfData}
                  scale={0.1}
                  width={"130px"}
                  height={"80px"}
                />
              </div>
            )
          )}
        </div>
        <div className="errormssg">
          {errorMssg.length > 0 && (
            <p style={{ color: "red", fontWeight: "bold" }}>{errorMssg}</p>
          )}
        </div>
        <div
          className="buttonDiv"
          style={
            ["porto_seguros", "ss321gn"].includes(clientId)
              ? { justifyContent: "end" }
              : {}
          }
        >
          <Button
            secoundaryBtn="true"
            text={
              Object.keys(config).includes("imageUpload")
                ? config["imageUpload"]["backTxtBtn"]
                : getTranslateText("Back")
            }
            disabled={false}
            onclick={() => {
              setScreen("menu");
              setStoredImage([]);
            }}
            marginDefault={
              ["porto_seguros", "ss321gn"].includes(clientId)
                ? "0px 10px 0px 0px"
                : "0px 0px 0px 0px"
            }
          />
          {!["porto_seguros", "ss321gn"].includes(clientId) && (
            <Button
              text={
                Object.keys(config).includes("imageUpload")
                  ? config["imageUpload"]["addImageTxtBtn"]
                  : getTranslateText("Add Images")
              }
              disabled={storedImage.length >= currentBlock["count"]}
              onclick={openFilePicker}
              marginDefault="0px 0px 0px 0px"
            />
          )}
          <Button
            text={
              Object.keys(config).includes("imageUpload")
                ? config["imageUpload"]["uploadTxtBtn"]
                : getTranslateText("Upload")
            }
            disabled={
              ["porto_seguros", "ss321gn"].includes(clientId)
                ? false
                : storedImage.length === 0
            }
            onclick={finish}
            marginDefault={
              ["porto_seguros", "ss321gn"].includes(clientId)
                ? "0px 10px 0px 0px"
                : "0px 0px 0px 0px"
            }
          />
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFiles}
            accept={
              ["porto_seguros", "ss321gn"].includes(clientId)
                ? "image/*,application/pdf"
                : Object.keys(config).includes("imageUpload")
                ? config["imageUpload"]["type"] === "img"
                  ? "image/*"
                  : "application/pdf"
                : "image/*"
            }
            multiple
            style={{ display: "none" }}
            disabled={storedImage.length >= currentBlock["count"]}
          />
        </div>
        <div ref={popupRef} className="uploadPopup">
          {currentImage &&
            (Object.keys(currentImage).includes("imgData") ? (
              <img
                src={currentImage.imgData}
                style={{
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                }}
                alt="Preview"
              />
            ) : (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                }}
                alt="Preview"
              >
                <PdfThumbnail
                  base64Pdf={currentImage.pdfData}
                  scale={1}
                  width={"100%"}
                  height={"100%"}
                />
              </div>
            ))}
          <div className="popupDiv">
            <Button
              text={
                Object.keys(config).includes("imageUpload")
                  ? config["imageUpload"]["backTxtBtn"]
                  : getTranslateText("Back")
              }
              disabled={false}
              onclick={() => {
                if (popupRef.current) popupRef.current.style.display = "none";
              }}
              marginDefault="0px 0px 0px 0px"
            />
            <Button
              text={
                Object.keys(config).includes("imageUpload")
                  ? config["imageUpload"]["removeTxtBtn"]
                  : getTranslateText("Remove")
              }
              disabled={false}
              onclick={removeImage}
              marginDefault="0px 0px 0px 0px"
            />
          </div>
        </div>

        <div
          ref={waringpopuup}
          className="waringpopuup"
          style={{
            backgroundColor: ["porto_seguros", "ss321gn"].includes(clientId)
              ? "white"
              : "rgba(128, 128, 128, 0.241)",
          }}
        >
          <div className="waringpopuupdiv">
            <p style={{ fontWeight: "bold", textAlign: "center" }}>
              {Object.keys(config).includes("imageUpload")
                ? config["imageUpload"]["warmingPopMssg"]
                : getTranslateText(
                    "You have to upload the front and back of the RC."
                  )}
            </p>
            <Button
              text={
                ["porto_seguros", "ss321gn"].includes(clientId)
                  ? Object.keys(config).includes("imageUpload")
                    ? config["imageUpload"]["addImageTxtBtn"]
                    : getTranslateText("Add Images")
                  : Object.keys(config).includes("imageUpload")
                  ? config["imageUpload"]["continueTxtBtn"]
                  : getTranslateText("Continue")
              }
              disabled={storedImage.length >= currentBlock["count"]}
              onclick={() => {
                if (["porto_seguros", "ss321gn"].includes(clientId)) {
                  openFilePicker();
                } else {
                  waringpopuup.current.style.display = "none";
                }
              }}
              marginDefault="0px 0px 0px 0px"
            />
            {["porto_seguros", "ss321gn"].includes(clientId) && (
              <div className="errormssg">
                {errorMssg.length > 0 && (
                  <p style={{ color: "red", fontWeight: "bold" }}>
                    {errorMssg}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageUpload;
