import carsmall from "../img/carsmall.png";
import * as constants from "../utils_new/Constants";

const getRegionPart = (region) => {
  switch (region) {
    case constants.REGIONS.FRONT:
      return 0;
    case constants.REGIONS.FRONT_ISOMETRIC_RIGHT:
      return 1;
    case constants.REGIONS.SIDE_RIGHT:
      return 2;
    case constants.REGIONS.REAR_ISOMETRIC_RIGHT:
      return 3;
    case constants.REGIONS.REAR:
      return 4;
    case constants.REGIONS.REAR_ISOMETRIC_LEFT:
      return 5;
    case constants.REGIONS.SIDE_LEFT:
      return 6;
    case constants.REGIONS.FRONT_ISOMETRIC_LEFT:
      return 7;
    default:
      return -1;
  }
};

const drawArcVin = (canvas, arcAngle, arcRegions, constantRegion) => {
  let context;
  try {
    context = canvas.getContext("2d");
  } catch (error) {
    console.log("error in draw arc: ", error);
    return;
  }

  let colors = ["rgba(0,0,0,0.2)", "rgba(255,0,0,0.6)", "rgba(0,255,0,0.6)"];

  const x = canvas.width / 2;
  const y = canvas.height / 2;
  const parts = 8;
  const pieAngle = (2 * Math.PI) / parts;
  const segmentDepth = canvas.width / 2 - 10;
  const pointAngle = 90 - arcAngle;

  context.clearRect(0, 0, canvas.width, canvas.height);

  // Draw normal regions
  for (let region in arcRegions) {
    const part = getRegionPart(region);
    if (part === -1) continue;

    const color = colors[arcRegions[region]];
    context.beginPath();
    context.moveTo(x, y);
    context.arc(
      x,
      y,
      segmentDepth,
      (part - 2) * pieAngle - 0.4,
      (part - 1) * pieAngle - 0.4,
      false
    );
    context.lineWidth = segmentDepth;
    context.fillStyle = color;
    context.fill();
    context.lineWidth = 2;
    context.strokeStyle = "#fff";
    context.stroke();
  }

  // Draw constant region with gradient
  if (constantRegion) {
    const part = getRegionPart(constantRegion);
    if (part !== -1) {
      context.beginPath();
      context.moveTo(x, y);
      context.arc(
        x,
        y,
        segmentDepth,
        (part - 2) * pieAngle - 0.4,
        (part - 1) * pieAngle - 0.4,
        false
      );

      const arrowX = x;
      const arrowY = y;
      const arrowSize = 20;

      const gradient = context.createLinearGradient(
        arrowX - arrowSize,
        arrowY,
        arrowX + arrowSize,
        arrowY
      );
      gradient.addColorStop(0, "#2C67FF"); // Start with blue
      gradient.addColorStop(1, "#2C67FF"); // End with blue

      context.lineWidth = segmentDepth;
      context.fillStyle = gradient; // Apply gradient to constant region
      context.fill();
      context.lineWidth = 2;
      context.strokeStyle = "#fff";
      context.stroke();
    }
  }

  // Draw car image in center
  const img = new Image();
  img.src = carsmall;
  img.onload = () => {
    context.drawImage(img, canvas.width / 2.6, canvas.height / 3.8, x / 2, y);
  };

  // Draw an indicator at the calculated point
  const px = x + segmentDepth * Math.cos((-pointAngle * Math.PI) / 180);
  const py = y + segmentDepth * Math.sin((-pointAngle * Math.PI) / 180);
  context.beginPath();
  context.arc(px, py, 8, 0, 2 * Math.PI, false);
  context.fillStyle = "#f00";
  context.fill();
};

export default drawArcVin;
