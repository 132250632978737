import React, {
  useEffect,
  useContext,
  useRef,
  useState,
  cloneElement,
} from "react";
import Button from "./Button";
import { AppContext } from "../context";
import * as tf from "@tensorflow/tfjs";
import { PartDetector } from "../partdetection_new/PartDetector";
import { Guidance } from "../guidance_new/GuidanceMain";
import drawArc from "../utils_new/DrawArc";
import carsmall from "../img/carsmall.png";
import initRedImg from "../img/coverage_red.png";
import initBlueImg from "../img/coverage_blue.png";
import indicator from "../img/indicator.svg";
import initBlueImgDot from "../img/coverage_blue_with_dot.PNG";
import Rotate from "./Rotate";
import {
  addImageToList,
  sendCoveredArea,
  setLog,
  lowLightImage,
  actualRegion,
  userLogs,
  patLogs,
  getModel,
  resumeFlowFinalSubmitHandler,
  setImgCOunt,
  translateText,
} from "../context/utils";

import * as constants from "../utils_new/Constants";
import arrowDown from "../img/down_arrow.gif";
import arrowUp from "../img/up_arrow.gif";
// import ReactGA from "react-ga4";
let fameInterval;
let logbugs = true;
const screenfull = require("screenfull");
var progressBar = require("progressbar.js");
let flowStop = false;
let processCompleted = false;
let minStop = false;
let notCoveredArea = "";
let showOnce = true;
let regionsAndAnglesMap = {};
for (let val of Object.values(constants.REGIONS))
  regionsAndAnglesMap[val] = new Set();
let allActualRegionDataLog = {};
let allActualRegionDataLogsCheck = {};
let finalRegionsMap = {};
let angleStop = true;
let regionsAngleCountThresh = 1;
let lowLightThreshold = 0.75;
let valuesToCountLowLightImage = [1, 2];
let greenStop = true;
let intervalId;
let intervalId2;
let lumaImageCountThreshold = 5;
let lowLightImageData = [];
let captured = 0;
let allActualRegionData = {};
let actualGuidanceRegion;
let seconds = 0;
let minutes = 0;
let WholeLightLumaData = "";
let data = {};
let track = null;
let angleDetectedGyro = [];
let currentUploadedPartCount = null;
let detectMlCheck = true;
let stopShowingNudgesDOWN = true;
let stopShowingNudgesUP = true;
let allGreenGlobal = false;
let hiddenRestrictedCard = false;
let imgValue = 0;
let afterFrameimgValue = 0;
let stopRecordingML = false;
let allowImgURLmain = {};
let regionWiseData = {};
let allFrameUpload = false;
let revolutionRegion = "";
let revolutionRegionlist = [];

const Video = () => {
  const {
    stepCovered,
    setStepCovered,
    setScreen,
    currentBlock,
    condition,
    setCondition,
    config,
    token,
    mandatory,
    setMandatory,
    inspectionId,
    setStopPing,
    setCurrentPage,
    stopPing,
    restrictUserIfallNotGreen,
    setRestrictUserIfallNotGreen,
    clientId,
    caseId,
  } = useContext(AppContext);

  const arrowUpText = useRef();
  const arrowDownText = useRef();
  const upArrow = useRef();
  const downArrow = useRef();
  const timerRef = useRef();
  const textRef = useRef();
  const videoRef = useRef();
  const startBtn = useRef();
  const stopBtn = useRef();
  const canvasRef = useRef();
  const canvas1 = useRef();
  const canvas4 = useRef();
  const canvas6 = useRef();
  const canvas7 = useRef();
  const canvas2 = useRef();
  const screen3 = useRef();
  const canvas5 = useRef();
  const reelsRef = useRef();
  const modalRef = useRef();
  const modalImg = useRef();
  const settingsRef = useRef();
  const cardRef = useRef();
  const reviewRef = useRef();
  const warnRef = useRef();
  const backRef = useRef();
  const imgRef = useRef();
  const startRef = useRef();
  const completeRef = useRef();
  const incompleteRef = useRef();
  const completeImgRef = useRef();
  const completeImgRestrictedRef = useRef();
  const stopRef = useRef();
  const timeUpRef = useRef();
  const angleRef = useRef();
  const angleDetailRef = useRef();
  const lowLightRegion = useRef();
  const lowLightRegion2 = useRef();
  const restrictedReviewRef = useRef();
  const incompleteSecoundText = useRef();
  const ackoReviewRef = useRef();
  const modalGuidanceText = useRef();
  const loaderGuidanceText = useRef();

  // const hRef = useRef();
  // const hRef2 = useRef();
  const partDetector = new PartDetector();
  const guidance = new Guidance(
    config["video-page"]["guidanceMessages"],
    clientId
  );
  const [disableStopRef, setDisableStopRef] = useState(false);
  const [hiddenStopRef, setStopHiddenRef] = useState(false);
  const [recordStopBtnFlag, setRecordStopBtnFlag] = useState(true);
  const [unCaptureList, setUnCaptureList] = useState([]);
  // const modelPromise = partDetector.loadModelFromURL();

  data = {
    text: config["video-page"]["dataText"],
    feedbacks: [],
    regionsCovered: guidance.regionsCoveredMap,
    angle: 0,
    region: "",
    nudges: { partToPoint: "", Nudge: "" },
  };

  const constraints = {
    video: Object.keys(config).includes("aspectRatio")
      ? {
          width: Object.keys(config).includes("resolution")
            ? config["resolution"]["width"]
            : 1920,
          height: Object.keys(config).includes("resolution")
            ? config["resolution"]["height"]
            : 1080,
          facingMode: "environment",
          aspectRatio: 4 / 3,
          zoom: {
            ideal: Object.keys(config).includes("zoom")
              ? config["zoom"]
                ? 0.5
                : 1
              : 0.5,
          },
        }
      : {
          width: Object.keys(config).includes("resolution")
            ? config["resolution"]["width"]
            : 1920,
          height: Object.keys(config).includes("resolution")
            ? config["resolution"]["height"]
            : 1080,
          facingMode: "environment",
          zoom: {
            ideal: Object.keys(config).includes("zoom")
              ? config["zoom"]
                ? 0.5
                : 1
              : 0.5,
          },
        },
    audio: false,
  };
  //const constraints = { video: { width: { exact: 1920 }, height: { exact: 1080 }, facingMode: "environment" }, audio: false }

  const getTranslateText = (text) => {
    if (Object.keys(config["language"]).includes(text)) {
      return config["language"][text];
    } else {
      // const data = translateText(text, config["language"]["langCode"]);
      return text;
    }
  };

  const ua = navigator.userAgent.toLowerCase();
  const isIos = !!/iPhone|iPad|iPod/i.test(ua);
  const isSafari = /^((?!chrome|android).)*safari/i.test(ua);

  let webCamPromise = null;
  let model = null;
  let active = true;
  let images = [];
  let endTimer = 0;
  let modalFlag = false;
  let startTimer = null;
  let allRegionsCovered = false;
  let allGreen = false;

  let detections = [];

  const isLandscape = () => window.innerWidth > window.innerHeight * 1.2;
  if (logbugs) {
    patLogs("Entered to Video module", inspectionId);
    userLogs({ position: 8, last_page: "video module", inspectionId });
    // ReactGA.send({
    //   hitType: "pageview",
    //   page: "Video module",
    //   title: "Video module",
    // });
  }
  logbugs = false;

  const sendLogData = async (data) => {
    userLogs(data);
  };

  const drawProgress = () => {
    let bar = new progressBar.Circle("#progress_loader", {
      strokeWidth: 6,
      easing: "easeInOut",
      duration: 6000,
      color: "#000000",
      trailColor: "#eee",
      trailWidth: 4,
      svgStyle: null,
      step: function (state, circle) {
        let value = Math.round(circle.value() * 100);
        if (value === 0) {
          circle.setText("");
        } else {
          circle.setText(`${value}%`);
        }
      },
    });
    return bar;
  };

  const getAllFrameUpload = () => {
    return allFrameUpload;
  };

  const captureImg = (imgURL, detections) => {
    imgValue = imgValue + 1;
    let frameData = guidance.getFramesToPush(
      imgURL,
      detections,
      stopRecordingML
    );
    currentUploadedPartCount = frameData["currentUploadedPartCount"];
    allFrameUpload = frameData["allFrameUpload"];
    //console.log("allFrameUpload", allFrameUpload);
    let frames_to_push = [];
    if (frameData["framesToPush"].length > 1) {
      if (frameData["framesToPush"][1].length > 0) {
        afterFrameimgValue = afterFrameimgValue + 1;
      }
      frames_to_push = frameData["framesToPush"][1];
    }
    let region = Object.keys(config["video-page"]).includes("clientRegion")
      ? config["video-page"]["clientRegion"]
      : "eu";
    for (let i = 0; i < frames_to_push.length; i++) {
      if (
        frames_to_push[i] &&
        frames_to_push[i].hasOwnProperty("frameEncoded")
      ) {
        let frameEncoded = frames_to_push[i]["frameEncoded"];
        let angle = frames_to_push[i]["angle"];
        let web_region = frames_to_push[i]["region"];
        let videoData = frames_to_push[i];
        delete videoData.frameEncoded;
        addImageToList({
          name: `${captured}.jpg`,
          imgData: frameEncoded,
          tag: web_region,
          angle,
          web_region,
          region: region,
          videoData: JSON.stringify(frames_to_push[i]),
          dialStatus: finalRegionsMap,
        });
        captured++;
      }
    }
  };

  const detect360 = (data, angleStop, revolutionRegionCurrent) => {
    if (!showOnce) {
      return;
    }
    if (revolutionRegion === "" && revolutionRegionlist.length === 0) {
      revolutionRegion = revolutionRegionCurrent;
      return;
    }
    if (revolutionRegion !== revolutionRegionCurrent) {
      !revolutionRegionlist.includes(revolutionRegion) &&
        revolutionRegionlist.push(revolutionRegion);
      revolutionRegion = revolutionRegionCurrent;
    }
    if (
      revolutionRegionlist.length === 7 &&
      data.regionsCovered[revolutionRegion] === 2
    ) {
      !revolutionRegionlist.includes(revolutionRegionCurrent) &&
        revolutionRegionlist.push(revolutionRegionCurrent);
      revolutionRegion = revolutionRegionCurrent;
    }

    if (revolutionRegionlist.length === 8) {
      angleStop = false;
      const coveredSides = Object.values(data.regionsCovered).filter(
        (value) => value === 0
      ).length;
      if (coveredSides) setRecordStopBtnFlag(false);
      angleRef.current.style.display = "flex";
      if (showOnce) {
        lowLight();
        intervalId = setInterval(() => {
          lowLight();
        }, 2000);
        Object.keys(data.regionsCovered).map((e) => {
          if (
            data.regionsCovered[e] ===
              constants.REGION_COVERAGE_STATUS.NOT_COVERED ||
            data.regionsCovered[e] ===
              constants.REGION_COVERAGE_STATUS.PARTIALLY_COVERED
          ) {
            if (Object.keys(config["video-page"]).includes("region")) {
              notCoveredArea = ` ${config["video-page"]["region"][e]}, ${notCoveredArea}`;
            } else {
              notCoveredArea = ` ${e}, ${notCoveredArea}`;
            }
          }
        });

        showOnce = false;
        if (notCoveredArea.endsWith(", ")) {
          notCoveredArea = notCoveredArea.slice(0, -2);
        }
        if (
          ["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(clientId)
        )
          setUnCaptureList(notCoveredArea.split(", "));
        else angleDetailRef.current.innerHTML = notCoveredArea;
        drawArc(canvas4.current, data.angle, data.regionsCovered, clientId);
        patLogs("One revolution of car is completed", inspectionId);
      }
    }
  };

  const captureImgFrame = () => {
    const cnv = canvasRef.current;
    const vw = videoRef.current;
    cnv.width = vw.videoWidth;
    cnv.height = vw.videoHeight;
    const ctx = cnv.getContext("2d");
    ctx.drawImage(vw, 0, 0);
    const imageData = ctx.getImageData(0, 0, cnv.width, cnv.height);
    const { data, width, height } = imageData;
    let blackPixelCount = 0;
    for (let i = 0; i < data.length; i += 4) {
      const [r, g, b] = [data[i], data[i + 1], data[i + 2]];
      const brightness = (r + g + b) / 3;
      if (brightness < 5) {
        blackPixelCount++;
      }
    }
    const totalPixels = width * height;
    const blackPercentage = (blackPixelCount / totalPixels) * 100;
    return blackPercentage < 100 ? cnv.toDataURL("image/jpeg") : false;
  };

  const detectFrame = (video, model) => {
    if (!guidance.pausePartCoveringAndFrameUpload) {
      let detections = [];
      let newImgURL = captureImgFrame();
      if (newImgURL) {
        tf.engine().startScope();

        const processedFrame = partDetector.processInput(video);
        if (allRegionsCovered) {
          // guidance.pausePartCoveringAndFrameUpload = true;
        } else if (minutes === 7) {
          // guidance.pausePartCoveringAndFrameUpload = true;
        } else if (
          angleRef.current.style.display === "flex" ||
          stopRef.current.style.display === "flex"
        ) {
          // guidance.pausePartCoveringAndFrameUpload = true;
        } else if (
          angleRef.current.style.display === "none" ||
          stopRef.current.style.display === "none"
        ) {
          guidance.pausePartCoveringAndFrameUpload = false;
        }

        //add logs
        if (detectMlCheck) {
          detectMlCheck = false;
          //cardRef.current.style.display = "none";
          patLogs("part_detection_is_working", inspectionId);
        }

        model.executeAsync(processedFrame).then((predictions) => {
          detections = partDetector.getDetectionObjects(predictions);
          // console.log("*******detections*****", detections);
          let [
            currentAngle,
            currentRegion,
            regionsCoveredMap,
            feedbacks,
            videoFreezeStatus,
            currentNudge,
            imgURL,
            allowImgURL,
            frameCountPerRegion,
            // ZoomOutFlag,
            revolutionRegionCurrent,
          ] = guidance.performChecksAndGetWarningMessage(
            detections,
            processedFrame,
            newImgURL
          );

          allRegionsCovered = guidance.checkIfAllRegionsCovered();
          finalRegionsMap = regionsCoveredMap;
          allowImgURLmain = allowImgURL;
          regionWiseData = frameCountPerRegion;
          if (imgURL) {
            captureImg(imgURL, detections);
          } else if (stopRecordingML) {
            captureImg(null, null);
          }
          data = {
            text: feedbacks[feedbacks.length - 1],
            feedbacks: feedbacks,
            regionsCovered: regionsCoveredMap,
            angle: currentAngle !== -1 ? currentAngle : data.angle,
            region: currentRegion,
            nudges: currentNudge,
          };
          drawArc(canvas1.current, data.angle, data.regionsCovered, clientId);
          actualGuidanceRegion = guidance.actualPartsCoveredMap;
          detect360(data, angleStop, revolutionRegionCurrent);

          if (Object.keys(config["video-page"]).includes("videoNudges")) {
            if (currentNudge["Nudge"] === "UP") {
              upArrow.current.style.display = "flex";
              arrowUpText.current.innerHTML = Object.keys(
                config["video-page"]["videoNudges"]
              ).includes(currentNudge["partToPoint"].trim())
                ? config["video-page"]["videoNudges"][
                    currentNudge["partToPoint"].trim()
                  ]
                : getTranslateText(currentNudge["partToPoint"]);
              if (stopShowingNudgesUP) {
                setTimeout(() => {
                  upArrow.current.style.display = "none";
                  stopShowingNudgesUP = true;
                }, 2000);
              }
              stopShowingNudgesUP = false;
            } else if (currentNudge["Nudge"] === "DOWN") {
              downArrow.current.style.display = "flex";
              arrowDownText.current.innerHTML = Object.keys(
                config["video-page"]["videoNudges"]
              ).includes(currentNudge["partToPoint"].trim())
                ? config["video-page"]["videoNudges"][
                    currentNudge["partToPoint"].trim()
                  ]
                : getTranslateText(currentNudge["partToPoint"]);
              if (stopShowingNudgesDOWN) {
                setTimeout(() => {
                  downArrow.current.style.display = "none";
                  stopShowingNudgesDOWN = true;
                }, 2000);
              }
              stopShowingNudgesDOWN = false;
            }
          }

          Object.keys(actualGuidanceRegion).map((e) => {
            allActualRegionDataLog.key = e;
            let takeOutValue = [];
            actualGuidanceRegion[e].forEach((i) => {
              takeOutValue.push(i);
            });
            allActualRegionDataLog[e] = takeOutValue;
          });

          if (
            JSON.stringify(allActualRegionDataLog) !==
            JSON.stringify(allActualRegionDataLogsCheck)
          ) {
            allActualRegionDataLog["VideoTimer"] = `${minutes} : ${seconds}`;
            allActualRegionDataLog["angleDetected"] = angleDetectedGyro;
            allActualRegionDataLog["DialStatus"] = finalRegionsMap;
            sendCoveredArea(
              allGreen,
              allActualRegionDataLog,
              clientId,
              caseId,
              inspectionId
            );
            userLogs({
              position: 3,
              video_data: JSON.stringify(allActualRegionDataLog),
              inspectionId,
            });
            allActualRegionDataLogsCheck = allActualRegionDataLog;
          }

          if (allRegionsCovered) {
            angleStop = false;
            angleRef.current.style.display = "none";
            setTimeout(() => {
              if (flowStop) return;
              if (minStop) return;
              // document.getElementById("window-arc2").style.display = "none"
              if (greenStop) {
                lowLightImageData = [];
                stopRef.current.style.display = "flex";
                lowLight();
                drawArc(
                  canvas5.current,
                  data.angle,
                  data.regionsCovered,
                  clientId
                );
                intervalId2 = setInterval(() => {
                  lowLight();
                }, 2000);
              }
              greenStop = false;
              allGreen = true;
              allGreenGlobal = true;
              hiddenRestrictedCard = true;
              // guidance.pausePartCoveringAndFrameUpload = true;
            }, 3000);
          }
          if (active) requestAnimationFrame(() => detectFrame(video, model));
          tf.engine().endScope();
        });
      } else {
        if (active) requestAnimationFrame(() => detectFrame(video, model));
      }
    }
  };

  const lowLightCalculation = (data) => {
    Object.keys(data).forEach((key) => {
      if (data[key].length > lumaImageCountThreshold) {
        const countOccurrences = (arr, value) =>
          arr.filter((element) => element === value).length;
        const lowLightData = valuesToCountLowLightImage
          .map((value) => countOccurrences(data[key], value))
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        if (lowLightData / data[key].length > lowLightThreshold) {
          if (Object.keys(config["video-page"]).includes("region")) {
            if (Object.keys(config["video-page"]["region"]).includes(key)) {
              !lowLightImageData.includes(
                config["video-page"]["region"][key]
              ) &&
                lowLightImageData.push(
                  `${config["video-page"]["region"][key]}`
                );
            } else {
              !lowLightImageData.includes(key) &&
                lowLightImageData.push(`${key}`);
            }
          } else {
            !lowLightImageData.includes(key) &&
              lowLightImageData.push(`${key}`);
          }
        }
      }
    });
    return lowLightImageData;
  };

  const lowLight = async () => {
    // hRef.current.style.display = "none";
    // hRef2.current.style.display = "none";
    let region = "";
    let data = await lowLightImage();
    WholeLightLumaData = data;
    let value = lowLightCalculation(data);
    if (value.length > 0) {
      value.map((e) => {
        region = `${e}, ${region}`;
      });
      region.endsWith(", ") && (region = region.slice(0, -2));
      // console.log(region, typeof region)
      // region = region + JSON.stringify(data)
      // hRef.current.style.display = "block"
      // hRef2.current.style.display = "block"
      // lowLightRegion.current.innerHTML = region
      // lowLightRegion2.current.innerHTML = region
    } else {
      // region = region + JSON.stringify(data)
      // lowLightRegion.current.innerHTML = region
      // lowLightRegion2.current.innerHTML = region
      // hRef.current.style.display = "none";
      // hRef2.current.style.display = "none";
    }
  };

  const updateUI = () => {
    if (textRef.current) {
      textRef.current.style.display = "block";
      textRef.current.innerHTML =
        data.text && data.text !== config["video-page"]["dataText"]
          ? data.text
          : "<span>&nbsp;&nbsp;</span>";
    }
    if (!isLandscape()) {
      if (warnRef.current) warnRef.current.style.display = "flex";
    } else {
      if (warnRef.current) warnRef.current.style.display = "none";
    }
    if (seconds >= 60) {
      minutes++;
      seconds = 0;
    }
    if (seconds < 10) {
      if (timerRef.current)
        timerRef.current.innerText = "0" + minutes + ":" + "0" + seconds;
    } else {
      if (timerRef.current)
        timerRef.current.innerText = "0" + minutes + ":" + seconds;
    }
    if (minutes === 7 && !processCompleted) {
      if (
        Object.keys(config["video-page"]).includes("videoTimeoutRefreshLink")
      ) {
        stopBtn.current.style.display = "none";
      }
      angleStop = false;
      angleRef.current.style.display = "none";
      minStop = true;
      // guidance.pausePartCoveringAndFrameUpload = true;
      stopRef.current.style.display = "none";
      timeUpRef.current.style.display = "flex";
      document.getElementById("window-arc2").style.display = "none";
    }
    timerRef.current.style.fontSize = "larger";
    timerRef.current.style.color = "white";
    timerRef.current.style.marginTop = "40px";
    timerRef.current.style.backgroundColor = "rgba(0,0,0,.7)";
    if (data.videoFreezeStatus) {
      active = false;
      modalFlag = true;
      drawArc(canvas2.current, data.angle, data.regionsCovered, clientId);
    }
    drawArc(canvas7.current, data.angle, data.regionsCovered, clientId);
  };

  const reShoot = () => {
    startCamera();
  };

  const continueShoot = () => {
    modalFlag = false;
    active = true;
    detectFrame(videoRef.current, model);
  };

  const startCamera = () => {
    angleRef.current.style.display = "none";
    timeUpRef.current.style.display = "none";
    document.getElementById("window-arc2").style.visibility = "hidden";
    document.getElementById("btn-car-container").style.visibility = "hidden";
    cardRef.current.style.display = "none";
    if (!Object.keys(config).includes("directModule")) {
      backRef.current.style.display = "none";
    }
    startRef.current.style.display = "none";
    textRef.current.style.display = "none";
    reviewRef.current.style.display = "none";
    ackoReviewRef.current.style.display = "none";
    restrictedReviewRef.current.style.display = "none";
    warnRef.current.style.display = "none";
    imgRef.current.style.display = "block";
    stopRef.current.style.display = "none";
    modalGuidanceText.current.innerHTML = config["video-page"]["dataText"];
    loaderGuidanceText.current.innerHTML = config["video-page"]["loadingText"];

    drawArc(canvas1.current, data.angle, data.regionsCovered, clientId);
    // settingsRef.current.innerHTML = config["video-page"]["settingsText"];
    // settingsRef.current.style.color = "black";
    // settingsRef.current.style.marginTop = "60px";
    // settingsRef.current.style.padding = "25px";
    webCamPromise = navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        window.stream = stream;
        track = stream.getTracks()[0];
        videoRef.current.srcObject = stream;
        startBtn.current.style.display = "block";
        if (!Object.keys(config).includes("directModule")) {
          backRef.current.style.display = "block";
        }
        textRef.current.style.display = "block";
        document.getElementById("btn-car-container").style.visibility =
          "visible";
        document.getElementById("window-arc2").style.visibility = "visible";
        settingsRef.current.style.display = "none";
        startRef.current.style.display = "flex";
        // if(screenfull.isEnabled) screenfull.request(screen3.current, {navigationUI: 'hide'})
        return new Promise(
          (resolve, _) => (videoRef.current.onloadedmetadata = () => resolve())
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const startRecording = () => {
    patLogs("camera button clicked", inspectionId);
    cardRef.current.style.display = "flex";
    textRef.current.style.display = "none";
    sendLogData({ position: 9, record_button: "yes", inspectionId });
    if (isIos || isSafari) DeviceMotionEvent.requestPermission();
    startBtn.current.style.display = "none";
    startRef.current.style.display = "none";
    if (!Object.keys(config).includes("directModule")) {
      backRef.current.style.display = "none";
    }
    cardRef.current.style.display = "flex";
    stopRef.current.style.display = "none";
    if (
      Object.keys(config).includes("directModule") &&
      config["directModule"]
    ) {
      drawProgress().animate(0.9);
    }
    settingsRef.current.style.display = "none";
    timerRef.current.style.width = "10%";
    timerRef.current.style.marginLeft = "45%";
    Promise.all([getModel(), webCamPromise])
      .then((values) => {
        sendLogData({
          position: 6,
          model_initialized: "yes",
          inspectionId,
        });
        model = values[0];
        detectFrame(videoRef.current, values[0]);
        // stopBtn.current.style.display = "block";
        startTimer = setInterval(() => {
          try {
            cardRef.current.style.display = "none";
          } catch (err) {}
          setDisableStopRef(true);
          if (hiddenStopRef) {
            if (stopBtn.current) {
              stopBtn.current.style.display = "none";
            }
          } else {
            if (stopBtn.current) {
              stopBtn.current.style.display = "block";
            }
          }
          seconds++;
          endTimer++;
          requestAnimationFrame(() => updateUI());
        }, 1000);
        setCurrentPage("Model initialize");
      })
      .catch((error) => {
        console.log(error);
        sendLogData({
          position: 6,
          model_initialized: `no - ${error.message}`,
          inspectionId,
        });
        setCurrentPage("Model not initialize");
      });
  };

  const sendMessageAcko = () => {
    if (allGreen) {
      console.log("All Cover: 100%");
      window.top.postMessage("All Cover: 100%", "*");
    } else {
      const totalSides = Object.keys(finalRegionsMap).length;
      const coveredSides = Object.values(finalRegionsMap).filter(
        (value) => value === 1 || value === 2
      ).length;

      let percentage = (coveredSides / totalSides) * 100;
      percentage = Math.round(percentage);
      console.log("Partial Cover: ", percentage + "%");
      window.top.postMessage(`Partial Cover: ${percentage}%`, "*");
    }
  };

  const stopRecording = async (clicked, submitFlag) => {
    sendCoveredArea(allGreen, finalRegionsMap, clientId, caseId, inspectionId);
    // console.log(actualGuidanceRegion);
    if (!typeof actualGuidanceRegion === "undefined") {
      Object.keys(actualGuidanceRegion).map((e) => {
        allActualRegionData.key = e;
        let takeOutValue = [];
        actualGuidanceRegion[e].forEach((i) => {
          takeOutValue.push(i);
        });
        allActualRegionData[e] = takeOutValue;
      });
    }
    allActualRegionData["VideoTimer"] = `${minutes} : ${seconds}`;
    allActualRegionData["angleDetected"] = angleDetectedGyro;
    allActualRegionData["lowLightImage"] = WholeLightLumaData;
    allActualRegionData["DialStatus"] = finalRegionsMap;
    allActualRegionData["currentUploadedPartCount"] = currentUploadedPartCount;
    //console.log("allActualRegionData", allActualRegionData);
    actualRegion(allActualRegionData, inspectionId);
    setImgCOunt(inspectionId, {
      regionWiseData,
      afterFrameimgValue,
      imgValue,
      ...allowImgURLmain,
      allActualRegionData,
    });
    if (
      Object.keys(config["video-page"]).includes("restrictUserIfallNotGreen") &&
      config["video-page"]["restrictUserIfallNotGreen"]
    ) {
      processCompleted = true;

      if (clicked) {
        if (allRegionsCovered) {
          allGreenGlobal = true;
          hiddenRestrictedCard = true;
          document.getElementById("window-arc2").style.display = "none";
          timeUpRef.current.style.display = "none";
          cardRef.current.style.display = "none";
          warnRef.current.style.display = "none";
          stopBtn.current.style.display = "none";
          textRef.current.style.display = "none";
          settingsRef.current.style.display = "none";
          timerRef.current.style.display = "none";
          incompleteRef.current.style.display = "none";
          incompleteSecoundText.current.style.display = "none";
          completeRef.current.style.display = "flex";
          //completeImgRef.current.style.display = "flex";
          completeImgRestrictedRef.current.style.display = "none";
          allGreen = true;
        } else {
          //completeImgRef.current.style.display = "none";
          completeImgRestrictedRef.current.style.display = "none";
          completeRef.current.style.display = "none";
          document.getElementById("window-arc2").style.display = "flex";
          document.getElementById("window-arc2").style.top = "38%";
          document.getElementById("window-arc2").style.left = "40%";
          document.getElementById("window-arc2").style.zIndex = "4";
          document.getElementById("window-arc2").style.height = "10%";
          document.getElementById("window-arc2").style.width = "10%";
          allGreen = false;
          allGreenGlobal = false;
          stopRecordingML = true;
        }
      }
    } else {
      //stopRecordingML = true;
      completeRef.current.style.display = "none";
      // completeImgRef.current.style.display = "none";
      completeImgRestrictedRef.current.style.display = "none";
    }

    if (
      Object.keys(config["video-page"]).includes("restrictUserIfallNotGreen") &&
      config["video-page"]["restrictUserIfallNotGreen"]
    ) {
      if (!hiddenRestrictedCard) {
        restrictedReviewRef.current.style.display = "flex";
      } else {
        if (stopRef.current.style.display !== "flex") {
          if (["client_1381", "client_1381_prod"].includes(clientId)) {
            if (submitFlag) {
              setStopHiddenRef(true);
              stopRecording2();
            } else {
              setStopHiddenRef(true);
              ackoReviewRef.current.style.display = "flex";
            }
          } else {
            setStopHiddenRef(true);
            drawArc(canvas6.current, data.angle, data.regionsCovered, clientId);
            reviewRef.current.style.display = "flex";
          }
        } else {
          stopRecording2();
        }
      }
    } else {
      if (stopRef.current.style.display !== "flex") {
        if (["client_1381", "client_1381_prod"].includes(clientId)) {
          if (submitFlag) {
            setStopHiddenRef(true);
            stopRecording2();
          } else {
            setStopHiddenRef(true);
            ackoReviewRef.current.style.display = "flex";
          }
        } else {
          setStopHiddenRef(true);
          drawArc(canvas6.current, data.angle, data.regionsCovered, clientId);
          reviewRef.current.style.display = "flex";
        }
      } else {
        stopRecording2();
      }
    }

    if (
      Object.keys(config["video-page"]).includes("restrictUserIfallNotGreen") &&
      config["video-page"]["restrictUserIfallNotGreen"]
    ) {
      if (!allGreenGlobal) {
        return;
      }
    }
  };

  const stopRecording2 = async () => {
    sendMessageAcko();
    stopRecordingML = true;
    patLogs("stop recording clicked", inspectionId);
    //console.log("stop recording clicked", actualGuidanceRegion);
    active = false;
    clearInterval(startTimer);
    window.stream = null;
    track.stop();
    delete allActualRegionData.lowLightImage;
    userLogs({
      position: 3,
      video_data: JSON.stringify(allActualRegionData),
      inspectionId,
    });
    handleNext();
  };

  const showModal = (e) => {
    modalImg.current.src = images[e.target.dataset.index].imgData;
    modalRef.current.classList.add("active");
  };

  const hideModal = () => {
    modalRef.current.classList.remove("active");
  };

  const back = () => {
    logbugs = true;
    window.stream = null;
    track.stop();
    patLogs("clicked back button", inspectionId);
    setScreen("menu");
  };

  const handleNext = () => {
    logbugs = false;
    patLogs("video module is completed", inspectionId);
    setTimeout(() => {
      if (currentBlock["mandatory"] && captured > 0) {
        let temp1 = mandatory;
        temp1[currentBlock["id"]] = "completed";
        setMandatory(temp1);
      }
      if (captured > 0) {
        let temp = condition;
        temp[currentBlock["id"]] = "completed";
        setCondition(temp);
        setLog(`${currentBlock["tag"]} was captured`);
        resumeFlowFinalSubmitHandler(currentBlock["id"]);
      }
      fameInterval = setInterval(() => {
        // console.log(
        //   "getAllFrameUpload",
        //   getAllFrameUpload,
        //   "allFrameUpload",
        //   allFrameUpload
        // );
        if (getAllFrameUpload) {
          clearInterval(fameInterval);
          if (
            Object.keys(config).includes("directModule") &&
            config["directModule"]
          ) {
            setScreen("success");
          } else {
            setScreen("menu");
          }
        }
      }, 10);
    }, 1000);
  };

  useEffect(() => {
    setStopPing(null);
    startCamera();
  }, []);

  return (
    <div
      id="screen3"
      ref={screen3}
      style={{
        position: "relative",
        height: ["porto_seguros", "ss321gn"].includes(clientId)
          ? "90dvh"
          : "100dvh",
      }}
    >
      <canvas
        ref={canvasRef}
        id="ios-canvas"
        style={
          Object.keys(config).includes("aspectRatio")
            ? {
                width: "100vw",
                height: "100vh",
                objectFit: "contain",
                backgroundColor: "black",
              }
            : {}
        }
      ></canvas>
      <video
        ref={videoRef}
        id="videoWindow"
        style={
          Object.keys(config).includes("aspectRatio")
            ? {
                width: "100vw",
                height: "100vh",
                objectFit: "contain",
                backgroundColor: "black",
              }
            : {}
        }
        autoPlay
        playsInline
      ></video>
      <p ref={timerRef} id="video-instructions"></p>
      <p
        style={{ display: "none" }}
        ref={settingsRef}
        id="video-instructions"
      ></p>
      <div id="btn-car-container"></div>
      {Object.keys(config).includes("directModule") ? (
        ""
      ) : (
        <div ref={backRef} className="back-btn" style={{ color: "white" }}>
          <i class="fas fa-arrow-left fa-2x" onClick={back}></i>
        </div>
      )}
      {/* <div ref={backRef} className="back-btn" style={{ color: "white" }}>
        <i class="fas fa-arrow-left fa-2x" onClick={back}></i>
      </div> */}
      <div
        style={
          ["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(clientId)
            ? { top: "40%" }
            : {}
        }
        ref={startBtn}
        onClick={startRecording}
        id="btn-android-start"
      ></div>

      <div ref={upArrow} className="upArrow">
        <img src={arrowUp} />
        <p>
          {Object.keys(config["video-page"]).includes("videoNudges")
            ? config["video-page"]["videoNudges"]["Point your camera to"]
            : getTranslateText("Point your camera to")}{" "}
          <span ref={arrowUpText}></span>
        </p>
      </div>
      <div ref={downArrow} className="downArrow">
        <img src={arrowDown} />
        <p>
          {Object.keys(config["video-page"]).includes("videoNudges")
            ? config["video-page"]["videoNudges"]["Point your camera to"]
            : getTranslateText("Point your camera to")}{" "}
          <span ref={arrowDownText}></span>
        </p>
      </div>

      <div
        ref={stopBtn}
        onClick={() =>
          stopRef.current.style.display === "flex"
            ? ""
            : stopRecording(true, false)
        }
        id="btn-android-stop"
        style={{
          visibility: hiddenStopRef ? "hidden" : "",
          top: ["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(
            clientId
          )
            ? "40%"
            : "",
        }}
      ></div>
      <div id="window-arc2">
        <img src={carsmall} alt="" className="car" />
        <canvas ref={canvas1} width="100" height="100"></canvas>
      </div>

      <div
        className="allgreenstop1"
        ref={stopRef}
        style={
          ["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(clientId)
            ? { top: "5%", height: "70%" }
            : {}
        }
      >
        <div className="pre-start-card" style={{ width: "40%", height: "70%" }}>
          <strong
            style={{
              fontSize: "12px",
              transform: [
                "porto_seguros",
                "porto_seguros_uat",
                "ss321gn",
              ].includes(clientId)
                ? ""
                : "rotate(-90deg)",
              paddingRight: [
                "porto_seguros",
                "porto_seguros_uat",
                "ss321gn",
              ].includes(clientId)
                ? "2%"
                : "",
            }}
          >
            {config["video-page"]["left-text"]}
          </strong>
          <div className="car-flex">
            <strong style={{ textAlign: "center", fontSize: "12px" }}>
              {config["video-page"]["front-text"]}
            </strong>
            <canvas ref={canvas5} width="150" height="150"></canvas>
            <strong style={{ fontSize: "12px" }}>
              {config["video-page"]["rear-text"]}
            </strong>
          </div>
          <strong
            style={{
              fontSize: "12px",
              transform: [
                "porto_seguros",
                "porto_seguros_uat",
                "ss321gn",
              ].includes(clientId)
                ? ""
                : "rotate(90deg)",
              paddingLeft: [
                "porto_seguros",
                "porto_seguros_uat",
                "ss321gn",
              ].includes(clientId)
                ? "2%"
                : "",
            }}
          >
            {config["video-page"]["right-text"]}
          </strong>
          <div style={{ width: "20px" }}></div>
        </div>
        {["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(
          clientId
        ) && <div style={{ marginLeft: "4%" }} class="divider"></div>}
        <div
          className="allgreenstop_bodyDiv"
          style={
            ["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(clientId)
              ? { padding: "0% 3%" }
              : {}
          }
        >
          {["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(
            clientId
          ) ? (
            <h4>{config["video-page"]["stopText"]}</h4>
          ) : (
            <h3 style={{ width: "90%" }}>{config["video-page"]["stopText"]}</h3>
          )}
          <div className="modal-btn-container" style={{ display: "flex" }}>
            {["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(
              clientId
            ) ? (
              <button
                style={{
                  backgroundColor: config["colors"]["btn"],
                  color: "white",
                  width: "100%",
                  height: "200%",
                }}
                onClick={() => {
                  setRestrictUserIfallNotGreen(true);
                  clearInterval(intervalId2);
                  stopRecording(true, false);
                  clearInterval(startTimer);
                }}
              >
                {Object.keys(config["video-page"]).includes("stopRecordingText")
                  ? config["video-page"]["stopRecordingText"]
                  : getTranslateText("Stop Recording")}
              </button>
            ) : (
              <Button
                marginDefault="0px"
                text={config["video-page"]["stopRecordingText"]}
                onclick={() => {
                  setRestrictUserIfallNotGreen(true);
                  clearInterval(intervalId2);
                  stopRecording(true, false);
                  clearInterval(startTimer);
                }}
              />
            )}
          </div>
        </div>
      </div>

      <div className="allgreenstop3" ref={timeUpRef}>
        <div className="allgreenstop3_title">
          <h2>
            {Object.keys(config["video-page"]).includes("timeup-text")
              ? config["video-page"]["timeup-text"]
              : getTranslateText("Video can only be seven minutes long.")}
          </h2>
        </div>

        {Object.keys(config["video-page"]).includes(
          "videoTimeoutRefreshLink"
        ) && (
          <>
            <div className="allgreenstop3_body">
              <h4 style={{ fontWeight: "600", margin: "0px", padding: "0px" }}>
                {Object.keys(
                  config["video-page"]["videoTimeoutRefreshLink"]
                ).includes("time-limitup-text-1")
                  ? config["video-page"]["videoTimeoutRefreshLink"][
                      "time-limitup-text-1"
                    ]
                  : getTranslateText(
                      "Due to our strict 7-minute policy, all videos and photos captured so far will be deleted."
                    )}{" "}
                <span style={{ color: "red" }}>
                  {Object.keys(
                    config["video-page"]["videoTimeoutRefreshLink"]
                  ).includes("time-limitup-text-2")
                    ? config["video-page"]["videoTimeoutRefreshLink"][
                        "time-limitup-text-2"
                      ]
                    : getTranslateText(
                        "You will need to restart the inspection process."
                      )}
                </span>
              </h4>
            </div>
            <div className="allgreenstop3_btn">
              <Button
                text={
                  Object.keys(
                    config["video-page"]["videoTimeoutRefreshLink"]
                  ).includes("restartText_btn")
                    ? config["video-page"]["videoTimeoutRefreshLink"][
                        "restartText_btn"
                      ]
                    : getTranslateText("Restart inspection")
                }
                onclick={() => {
                  location.reload();
                }}
              />
            </div>
          </>
        )}
      </div>

      {/* <div className="allgreenstop2" ref={timeUpRef}>
        <h3>
          {Object.keys(config["video-page"]).includes("timeup-text")
            ? config["video-page"]["timeup-text"]
            : "Video can only be seven minutes long."}
        </h3>
      </div> */}

      <div
        className="allgreenstop2"
        ref={angleRef}
        style={
          ["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(clientId)
            ? { top: "5%", height: "72%" }
            : {}
        }
      >
        <div
          className="pre-start-card"
          style={
            ["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(clientId)
              ? { width: "auto" }
              : { width: "30%", height: "70%" }
          }
        >
          <strong
            style={{
              fontSize: "12px",
              marginRight: "2px",
              transform: [
                "porto_seguros",
                "porto_seguros_uat",
                "ss321gn",
              ].includes(clientId)
                ? ""
                : "rotate(-90deg)",
              paddingRight: [
                "porto_seguros",
                "porto_seguros_uat",
                "ss321gn",
              ].includes(clientId)
                ? "2%"
                : "",
            }}
          >
            {config["video-page"]["left-text"]}
          </strong>
          <div className="car-flex">
            <strong
              style={{
                marginBottom: "8px",
                textAlign: "center",
                fontSize: "12px",
              }}
            >
              {config["video-page"]["front-text"]}
            </strong>
            <canvas ref={canvas4} width="150" height="150"></canvas>
            <strong style={{ marginTop: "10px", fontSize: "12px" }}>
              {config["video-page"]["rear-text"]}
            </strong>
          </div>
          <strong
            style={{
              fontSize: "12px",
              transform: [
                "porto_seguros",
                "porto_seguros_uat",
                "ss321gn",
              ].includes(clientId)
                ? ""
                : "rotate(90deg)",
              paddingLeft: [
                "porto_seguros",
                "porto_seguros_uat",
                "ss321gn",
              ].includes(clientId)
                ? "2%"
                : "",
            }}
          >
            {config["video-page"]["right-text"]}
          </strong>
          <div style={{ width: "20px" }}></div>
        </div>
        {["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(
          clientId
        ) && <div class="divider"></div>}
        <div
          className="allgreenstop2_bodyDiv"
          style={
            ["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(clientId)
              ? { padding: "0% 3%" }
              : {}
          }
        >
          <div
            className="allgreenstop2_innerDiv"
            style={{ width: "100%", paddingBottom: "5%" }}
          >
            <h3 style={{ margin: "0px", padding: "0px", fontSize: "14px" }}>
              {Object.keys(config["video-page"]).includes("one-revolution")
                ? config["video-page"]["recordStopBtnEnable"]
                  ? recordStopBtnFlag
                    ? config["video-page"]["one-revolution"]
                    : config["video-page"]["one-revolution-partial"]
                  : config["video-page"]["one-revolution"]
                : getTranslateText(
                    "You have completed one revolution around the car."
                  )}
            </h3>
            <div
              className="notCoveredAreas"
              id={
                ["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(
                  clientId
                )
                  ? "scrollable-container"
                  : ""
              }
              style={{ fontSize: "12px" }}
            >
              <p style={{ fontWeight: "600", margin: "0px", padding: "0px" }}>
                {Object.keys(config["video-page"]).includes(
                  "missed-region-text"
                )
                  ? config["video-page"]["recordStopBtnEnable"]
                    ? recordStopBtnFlag
                      ? config["video-page"]["missed-region-text"]
                      : config["video-page"]["missed-region-text-partial"]
                    : config["video-page"]["missed-region-text"]
                  : getTranslateText(
                      "Continue to capture missed regions:"
                    )}{" "}
                {["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(
                  clientId
                ) ? (
                  unCaptureList.map((e) => (
                    <li style={{ margin: 0, fontWeight: 100 }}>{e.trim()}</li>
                  ))
                ) : (
                  <span
                    ref={angleDetailRef}
                    style={{ margin: 0, fontWeight: 100, color: "red" }}
                  ></span>
                )}
              </p>
              {/* <p style={{ fontWeight: '300' }} ref={hRef2}>
              {Object.keys(config["video-page"]).includes("lowLightRegion")
                ? config["video-page"]["lowLightRegion"]
                : "Regions in Low Light:"}{" "}
              <span
                ref={lowLightRegion2}
                style={{ margin: 0, fontWeight: 100 }}
              ></span>
            </p> */}
            </div>
          </div>

          <div
            className="modal-btn-container"
            style={{ justifyContent: "flex-end" }}
          >
            {Object.keys(config["video-page"]).includes(
              "restrictUserIfallNotGreen"
            ) &&
              config["video-page"]["restrictUserIfallNotGreen"] &&
              allGreenGlobal && (
                <Button
                  marginDefault="0px"
                  secoundaryBtn="true"
                  text={config["video-page"]["stopRecordingText"]}
                  onclick={() => {
                    angleStop = false;
                    angleRef.current.style.display = "none";
                    stopRecording(true, false);
                    clearInterval(intervalId);
                  }}
                />
              )}
            {recordStopBtnFlag &&
              config["video-page"]["recordStopBtnEnable"] && (
                <Button
                  marginDefault="0px"
                  secoundaryBtn="true"
                  text={
                    config["video-page"]["recordStopBtnText"]
                      ? config["video-page"]["recordStopBtnText"]
                      : getTranslateText("Submit")
                  }
                  onclick={() => {
                    angleStop = false;
                    angleRef.current.style.display = "none";
                    stopRecording(true, true);
                    clearInterval(intervalId);
                  }}
                />
              )}
            {["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(
              clientId
            ) ? (
              <button
                style={{
                  backgroundColor: config["colors"]["btn"],
                  color: "white",
                  width: "100%",
                  height: "200%",
                }}
                onClick={() => {
                  angleStop = false;
                  angleRef.current.style.display = "none";
                  guidance.pausePartCoveringAndFrameUpload = false;
                  guidance.stage = 2;
                  clearInterval(intervalId);
                }}
              >
                {Object.keys(config["video-page"]).includes("continueText")
                  ? config["video-page"]["continueText"]
                  : getTranslateText("Continue")}
              </button>
            ) : (
              <Button
                marginDefault="0px"
                text={config["video-page"]["continueText"]}
                onclick={() => {
                  angleStop = false;
                  angleRef.current.style.display = "none";
                  guidance.pausePartCoveringAndFrameUpload = false;
                  guidance.stage = 2;
                  clearInterval(intervalId);
                }}
              />
            )}
          </div>
        </div>
      </div>

      <div
        ref={cardRef}
        id="display-card"
        style={
          ["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(clientId)
            ? { height: "75%", top: "5%" }
            : {}
        }
      >
        <div className="pre-start-card">
          <strong
            style={{
              marginRight: "2px",
              fontSize: "12px",
              transform: [
                "porto_seguros",
                "porto_seguros_uat",
                "ss321gn",
              ].includes(clientId)
                ? ""
                : "rotate(-90deg)",
              paddingRight: [
                "porto_seguros",
                "porto_seguros_uat",
                "ss321gn",
              ].includes(clientId)
                ? "2%"
                : "",
            }}
          >
            {config["video-page"]["left-text"]}
          </strong>
          <div className="car-flex">
            <strong
              style={{
                marginBottom: "8px",
                textAlign: "center",
                fontSize: "12px",
              }}
            >
              {config["video-page"]["front-text"]}
            </strong>
            <img
              ref={imgRef}
              style={{ width: "12dvw" }}
              src={
                [
                  "porto_seguros",
                  "porto_seguros_uat",
                  "ss321gn",
                  "client_834",
                  "client_834_uat",
                ].includes(clientId)
                  ? ["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(
                      clientId
                    )
                    ? initBlueImgDot
                    : initBlueImg
                  : initRedImg
              }
            ></img>
            <strong style={{ marginTop: "10px", fontSize: "12px" }}>
              {config["video-page"]["rear-text"]}
            </strong>
          </div>
          <strong
            style={{
              fontSize: "12px",
              transform: [
                "porto_seguros",
                "porto_seguros_uat",
                "ss321gn",
              ].includes(clientId)
                ? ""
                : "rotate(90deg)",
              paddingLeft: [
                "porto_seguros",
                "porto_seguros_uat",
                "ss321gn",
              ].includes(clientId)
                ? "2%"
                : "",
            }}
          >
            {config["video-page"]["right-text"]}
          </strong>
          <div style={{ width: "20px" }}></div>
        </div>
        {["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(
          clientId
        ) && <div class="divider"></div>}
        <div className="card-info">
          <div>
            <div className="car-green"></div>
            <b
              style={{
                fontSize: "14px",
                fontWeight: [
                  "porto_seguros",
                  "porto_seguros_uat",
                  "ss321gn",
                ].includes(clientId)
                  ? "unset"
                  : "",
              }}
            >
              {config["video-page"]["covered-text"]}
            </b>
          </div>
          <div
            style={
              ["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(
                clientId
              )
                ? { padding: "3% 0%" }
                : {}
            }
          >
            <div
              className="car-red"
              style={{
                backgroundColor: [
                  "porto_seguros",
                  "porto_seguros_uat",
                  "ss321gn",
                  "client_834",
                  "client_834_uat",
                ].includes(clientId)
                  ? "#02CCFE"
                  : "#de524c",
              }}
            ></div>
            <b
              style={{
                fontSize: "14px",
                fontWeight: [
                  "porto_seguros",
                  "porto_seguros_uat",
                  "ss321gn",
                ].includes(clientId)
                  ? "unset"
                  : "",
              }}
            >
              {config["video-page"]["notcovered-text"]}
            </b>
          </div>
          {["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(
            clientId
          ) && (
            <div>
              <div className="red-circle"></div>
              <b style={{ fontWeight: "unset", fontSize: "14px" }}>
                {config["video-page"]["circle-red-text"]}
              </b>
            </div>
          )}
          <div className="card-note">
            {["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(
              clientId
            ) && <div className="car-white"></div>}
            <p
              ref={loaderGuidanceText}
              style={{ width: "100%", fontSize: "14px" }}
            ></p>
            {Object.keys(config).includes("directModule") &&
            config["directModule"] ? (
              <div className="progress" id="progress_loader"></div>
            ) : (
              <img
                id="model-loader"
                src="https://superapp-images-inspektlabs.s3.eu-central-1.amazonaws.com/app-icon/video_model_loader.gif"
                alt="abc"
              />
            )}
          </div>
        </div>
      </div>

      <div
        ref={startRef}
        id="stop-display-card"
        style={
          ["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(clientId)
            ? { height: "75%", top: "5%" }
            : {}
        }
      >
        <div className="pre-start-card">
          <strong
            style={{
              fontSize: "12px",
              marginRight: "2px",
              transform: [
                "porto_seguros",
                "porto_seguros_uat",
                "ss321gn",
              ].includes(clientId)
                ? ""
                : "rotate(-90deg)",
              paddingRight: [
                "porto_seguros",
                "porto_seguros_uat",
                "ss321gn",
              ].includes(clientId)
                ? "2%"
                : "",
            }}
          >
            {config["video-page"]["left-text"]}
          </strong>
          <div className="car-flex">
            <strong
              style={{
                marginBottom: "8px",
                textAlign: "center",
                fontSize: "12px",
              }}
            >
              {config["video-page"]["front-text"]}
            </strong>
            <img
              ref={imgRef}
              style={{ width: "12dvw" }}
              src={
                [
                  "porto_seguros",
                  "porto_seguros_uat",
                  "ss321gn",
                  "client_834",
                  "client_834_uat",
                ].includes(clientId)
                  ? ["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(
                      clientId
                    )
                    ? initBlueImgDot
                    : initBlueImg
                  : initRedImg
              }
            ></img>
            <strong style={{ marginTop: "10px", fontSize: "12px" }}>
              {config["video-page"]["rear-text"]}
            </strong>
          </div>
          <strong
            style={{
              fontSize: "12px",
              transform: [
                "porto_seguros",
                "porto_seguros_uat",
                "ss321gn",
              ].includes(clientId)
                ? ""
                : "rotate(90deg)",
              paddingLeft: [
                "porto_seguros",
                "porto_seguros_uat",
                "ss321gn",
              ].includes(clientId)
                ? "2%"
                : "",
            }}
          >
            {config["video-page"]["right-text"]}
          </strong>
          <div style={{ width: "20px" }}></div>
        </div>
        {["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(
          clientId
        ) && <div class="divider"></div>}
        <div className="card-info">
          <div>
            <div className="car-green"></div>
            <b
              style={{
                fontSize: "14px",
                width: "90%",
                fontWeight: [
                  "porto_seguros",
                  "porto_seguros_uat",
                  "ss321gn",
                ].includes(clientId)
                  ? "unset"
                  : "",
              }}
            >
              {config["video-page"]["covered-text"]}
            </b>
          </div>
          <div
            style={
              ["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(
                clientId
              )
                ? { padding: "3% 0%" }
                : {}
            }
          >
            <div
              className="car-red"
              style={{
                backgroundColor: [
                  "porto_seguros",
                  "porto_seguros_uat",
                  "ss321gn",
                  "client_834",
                  "client_834_uat",
                ].includes(clientId)
                  ? "#02CCFE"
                  : "#de524c",
              }}
            ></div>
            <b
              style={{
                width: "90%",
                fontSize: "14px",
                fontWeight: [
                  "porto_seguros",
                  "porto_seguros_uat",
                  "ss321gn",
                ].includes(clientId)
                  ? "unset"
                  : "",
              }}
            >
              {config["video-page"]["notcovered-text"]}
            </b>
          </div>
          {["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(
            clientId
          ) && (
            <div>
              <div className="red-circle"></div>
              <b
                style={{ width: "90%", fontWeight: "unset", fontSize: "14px" }}
              >
                {config["video-page"]["circle-red-text"]}
              </b>
            </div>
          )}
          <div className="card-note">
            {["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(
              clientId
            ) && <div className="car-white"></div>}
            <p ref={modalGuidanceText} style={{ fontSize: "14px" }}></p>
          </div>
        </div>
      </div>
      <div>
        <p ref={textRef} id="video-recording-text"></p>
      </div>

      {/* acko recent changes */}
      <div ref={ackoReviewRef} id="acko-review-card">
        <div
          className="pre-start-card"
          style={{ width: "15%", height: "70%", paddingLeft: "12%" }}
        >
          <strong style={{ transform: "rotate(-90deg)", fontSize: "12px" }}>
            {config["video-page"]["left-text"]}
          </strong>
          <div className="car-flex">
            <strong style={{ marginBottom: "8px", fontSize: "12px" }}>
              {config["video-page"]["front-text"]}
            </strong>
            <canvas ref={canvas7} width="150" height="150"></canvas>
            <strong style={{ marginTop: "10px", fontSize: "12px" }}>
              {config["video-page"]["rear-text"]}
            </strong>
          </div>
          <strong style={{ transform: "rotate(90deg)", fontSize: "12px" }}>
            {config["video-page"]["right-text"]}
          </strong>
        </div>
        <div className="allgreenstop2_bodyDiv">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              paddingRight: "2%",
              height: "80%",
            }}
          >
            <h3 style={{ margin: "0px", padding: "0px" }} ref={incompleteRef}>
              {Object.keys(config["video-page"]).includes("newIncompleteText")
                ? config["video-page"]["incompleteText"]
                : getTranslateText("Are you sure you want to stop recording?")}
            </h3>
            <p id="incomplete-secoundText" ref={incompleteSecoundText}>
              {Object.keys(config["video-page"]).includes(
                "incomplete-secound-text"
              )
                ? config["video-page"]["incomplete-secound-text"]
                : ""}
            </p>
          </div>
          <div className="modal-btn-container">
            {!allGreenGlobal && (
              <Button
                secoundaryBtn="true"
                id="ackoBtn"
                text={
                  config["video-page"]["ackoStopRecordingText"]
                    ? config["video-page"]["ackoStopRecordingText"]
                    : getTranslateText("Stop recording")
                }
                onclick={() => stopRecording2()}
              />
            )}
            {!allGreenGlobal && (
              <Button
                text={
                  config["video-page"]["continueRecordingText"]
                    ? config["video-page"]["restrictionBtn"]
                    : getTranslateText("Continue")
                }
                onclick={() => {
                  setStopHiddenRef(false);
                  ackoReviewRef.current.style.display = "none";
                  document.getElementById("window-arc2").style.top = "";
                  document.getElementById("window-arc2").style.left = "";
                  document.getElementById("window-arc2").style.right = "30px";
                  document.getElementById("window-arc2").style.bottom = "25px";
                  document.getElementById("window-arc2").style.zIndex = "";
                  document.getElementById("window-arc2").style.height = "79px";
                  document.getElementById("window-arc2").style.width = "79px";
                }}
              />
            )}
          </div>
        </div>
      </div>

      <div
        ref={reviewRef}
        id="review-card"
        style={
          ["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(clientId)
            ? { top: "8%", height: "70%" }
            : {}
        }
      >
        <div className="pre-start-card" style={{ width: "40%", height: "70%" }}>
          <strong
            style={{
              fontSize: "12px",
              transform: [
                "porto_seguros",
                "porto_seguros_uat",
                "ss321gn",
              ].includes(clientId)
                ? ""
                : "rotate(-90deg)",
              paddingRight: [
                "porto_seguros",
                "porto_seguros_uat",
                "ss321gn",
              ].includes(clientId)
                ? "2%"
                : "",
            }}
          >
            {config["video-page"]["left-text"]}
          </strong>
          <div className="car-flex">
            <strong style={{ textAlign: "center", fontSize: "12px" }}>
              {config["video-page"]["front-text"]}
            </strong>
            <canvas ref={canvas6} width="150" height="150"></canvas>
            <strong style={{ fontSize: "12px" }}>
              {config["video-page"]["rear-text"]}
            </strong>
          </div>
          <strong
            style={{
              fontSize: "12px",
              transform: [
                "porto_seguros",
                "porto_seguros_uat",
                "ss321gn",
              ].includes(clientId)
                ? ""
                : "rotate(90deg)",
              paddingLeft: [
                "porto_seguros",
                "porto_seguros_uat",
                "ss321gn",
              ].includes(clientId)
                ? "2%"
                : "",
            }}
          >
            {config["video-page"]["right-text"]}
          </strong>
          <div style={{ width: "20px" }}></div>
        </div>
        {["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(
          clientId
        ) && <div style={{ marginLeft: "4%" }} class="divider"></div>}
        <div
          className="allgreenstop_bodyDiv"
          style={
            ["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(clientId)
              ? { padding: "0% 3%" }
              : {}
          }
        >
          <h3 style={{ width: "90%" }} ref={completeRef}>
            {config["video-page"]["completeText"]}
          </h3>
          <h3 style={{ width: "90%" }} ref={incompleteRef}>
            {config["video-page"]["incompleteText"]}
          </h3>
          <div className="modal-btn-container" style={{ display: "flex" }}>
            {["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(
              clientId
            ) ? (
              <button
                style={{
                  backgroundColor: config["colors"]["btn"],
                  color: "white",
                  width: "100%",
                  height: "200%",
                }}
                onClick={() => {
                  if (
                    Object.keys(config["video-page"]).includes(
                      "restrictUserIfallNotGreen"
                    ) &&
                    config["video-page"]["restrictUserIfallNotGreen"]
                  ) {
                    if (allGreenGlobal) {
                      stopRecording2();
                    } else {
                      reviewRef.current.style.display = "none";
                      document.getElementById("window-arc2").style.top = "";
                      document.getElementById("window-arc2").style.left = "";
                      document.getElementById("window-arc2").style.right =
                        "30px";
                      document.getElementById("window-arc2").style.bottom =
                        "25px";
                      document.getElementById("window-arc2").style.zIndex = "";
                      document.getElementById("window-arc2").style.height =
                        "79px";
                      document.getElementById("window-arc2").style.width =
                        "79px";
                    }
                  } else {
                    stopRecording2();
                  }
                }}
              >
                {Object.keys(config["video-page"]).includes("btnText")
                  ? config["video-page"]["btnText"]
                  : getTranslateText("Stop Recording")}
              </button>
            ) : (
              <Button
                marginDefault="0px"
                text={config["video-page"]["btnText"]}
                onclick={() => {
                  if (
                    Object.keys(config["video-page"]).includes(
                      "restrictUserIfallNotGreen"
                    ) &&
                    config["video-page"]["restrictUserIfallNotGreen"]
                  ) {
                    if (allGreenGlobal) {
                      stopRecording2();
                    } else {
                      reviewRef.current.style.display = "none";
                      document.getElementById("window-arc2").style.top = "";
                      document.getElementById("window-arc2").style.left = "";
                      document.getElementById("window-arc2").style.right =
                        "30px";
                      document.getElementById("window-arc2").style.bottom =
                        "25px";
                      document.getElementById("window-arc2").style.zIndex = "";
                      document.getElementById("window-arc2").style.height =
                        "79px";
                      document.getElementById("window-arc2").style.width =
                        "79px";
                    }
                  } else {
                    stopRecording2();
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>

      <div ref={restrictedReviewRef} id="review-restricted-card">
        <h4>
          {config["video-page"]["restrictionText"]
            ? config["video-page"]["restrictionText"]
            : getTranslateText(
                "You can't move to next step until you complete all section to green"
              )}
        </h4>
        <img ref={completeImgRestrictedRef} src={indicator}></img>
        <div className="review-btns">
          <Button
            text={
              config["video-page"]["restrictionBtn"]
                ? config["video-page"]["restrictionBtn"]
                : getTranslateText("Continue Recording")
            }
            onclick={() => {
              restrictedReviewRef.current.style.display = "none";
              document.getElementById("window-arc2").style.top = "";
              document.getElementById("window-arc2").style.left = "";
              document.getElementById("window-arc2").style.right = "30px";
              document.getElementById("window-arc2").style.bottom = "25px";
              document.getElementById("window-arc2").style.zIndex = "";
              document.getElementById("window-arc2").style.height = "79px";
              document.getElementById("window-arc2").style.width = "79px";
            }}
          ></Button>
        </div>
      </div>

      <div ref={warnRef} id="warn-card">
        <Rotate />
        <p className="note">{config["video-page"]["rotateText"]}</p>
      </div>
      {modalFlag && (
        <div id="screen3-modal">
          <div className="modal-column">
            <div className="full-center">
              <div>
                <h2 className="header2">
                  {getTranslateText(
                    "You have not taken the Front View properly"
                  )}
                </h2>
                <strong>{getTranslateText("Problems identified:")}</strong>
                <ol>
                  {data.feedbacks.map((warning) => (
                    <li key={warning}>{warning}</li>
                  ))}
                </ol>
                <p>
                  {getTranslateText(
                    "Would you like to reshoot from front or continue as it is?"
                  )}
                </p>
                <div className="modal-btn-flex">
                  <Button text="Reshoot" onclick={reShoot} />
                  <Button
                    text={getTranslateText("Continue")}
                    translucent={true}
                    onclick={continueShoot}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-column">
            <div className="full-center">
              <div>
                <canvas ref={canvas2} width="200" height="200"></canvas>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Video;
