import React, { useContext, useEffect } from "react";
import {
  initializeToken,
  initializeInspectionId,
  initializeretake,
  makeRequest,
  userLogs,
  patLogs,
  addUserAgentData,
  setLogging,
  startModel,
  setInspectionID,
  setConditionResume,
  setMandatoryResume,
  setSubConditionResume,
  setSubMandatoryResume,
  setResumeFlowAllow,
  setDamageCount,
  setWindShieldDamageCount,
  setRedisFlowSetFirstTime,
  initializeEmail,
  setResumeFlowCount,
  zizoApiCalling,
  getInspectionID,
  setPathData,
  setUserLogs,
} from "../context/utils";

import { AppContext } from "../context";
import loading from "../img/loading.gif";
import sampleBase64Img from "../img/sampleBase64Img";
import MobileDetect from "mobile-detect";

let finalConditions = {};
let finalSubConditions = {};
let finalSubConditionsSub = {};
let mandatoryConditions = {};
let subMandatoryConditions = {};
let subMandatoryConditionsSub = {};
let startDateLog = new Date().getTime();
let endDateLog = 0;
let startdates = Date.now();
let userToken = undefined;
let userAgentData = "";
let windshiledModuleCount = false;
let damageModuleCount = false;
let fetchDataFromRedis = false;
let resumeFlowEmailAllow = true;

const Loading = () => {
  const {
    setCondition,
    setPhone,
    setScreen,
    setClientId,
    setInspectionId,
    inspectionId,
    setConfig,
    setMandatory,
    setSubMandatory,
    setSubMandatorySub,
    setSubCondition,
    setSubConditionSub,
    setToken,
    setErrorMessage,
    setRetake,
    setCaseId,
    setCount,
    setRestrictUserIfallNotGreen,
    config,
    setUserEmail,
    setCurrentDamage,
    setIsIOS,
  } = useContext(AppContext);
  let hashData = window.location.hash.split("#");
  if (hashData.length > 1) {
    userToken = hashData[1];
  }
  if (hashData.length > 2) {
    userAgentData = hashData[2];
    addUserAgentData({ token: userToken, userAgentData: userAgentData });
  }

  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  let isAndroid = /Android/.test(navigator.userAgent);
  let SetRetake_inspectionId;
  let reOpen;
  setIsIOS(isIOS);

  const loadCustomFont = (URL) => {
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `
      @font-face {
        font-family: 'D-DIN';
        src: url('${URL}') format('truetype');
      }
      body {
        font-family: 'D-DIN', sans-serif;
      }
    `;
    document.head.appendChild(style);
  };

  const checkToken = async () => {
    if (userToken === undefined) {
      setErrorMessage("Unique token is missing. Please use a valid URL");
      setScreen("error");
    } else {
      const res = await makeRequest("/checkToken", { token: userToken });
      const data = await res.json();
      if (data.status === "error") {
        if (data.errorType === "missing") {
          setErrorMessage("Unique token is invalid. Please use a valid URL");
        } else if (data.errorType === "used") {
          setErrorMessage(data.configData["errors"]["used"]);
        } else if (data.errorType === "wrongpath") {
          setErrorMessage(
            Object.keys("errors").includes("invalid_personaId")
              ? data.configData["errors"]["invalid_personaId"]
              : "Invalid Persona Id"
          );
        } else {
          setErrorMessage(data.configData["errors"]["invalid"]);
        }
        setScreen("error");
      } else {
        setLogging(data.setLogStatus);
        console.log("token verification time", startdates - Date.now());
        startdates = Date.now();
        const userAgent = window.navigator.userAgent;
        let browserName;
        let browserVersion;

        if (isIOS && navigator.userAgent.includes("CriOS")) {
          browserName = "Google Chrome";
          browserVersion = userAgent.match(/CriOS\/(\S+)/)
            ? userAgent.match(/CriOS\/(\S+)/)[1]
            : null;
        } else if (
          userAgent.includes("Safari") &&
          !userAgent.includes("Chrome")
        ) {
          browserName = "Apple Safari";
          browserVersion = userAgent.match(/Version\/(\S+)/)[1] || null;
        } else if (isAndroid && navigator.userAgent.includes("Samsung")) {
          browserName = "Samsung Browser";
          browserVersion =
            userAgent.match(/SamsungBrowser\/(\d+\.\d+)/)[1] || null;
        } else if (isAndroid && navigator.userAgent.includes("Edg")) {
          browserName = "Edge Browser";
          var version =
            userAgent.match(/Edg\/(\d+\.\d+\.\d+\.\d+)/) ||
            userAgent.match(/Edge\/(\d+\.\d+\.\d+\.\d+)/) ||
            userAgent.match(/EdgA\/(\d+\.\d+\.\d+\.\d+)/);
          browserVersion = version ? version[1] : null;
        } else if (isAndroid && !navigator.userAgent.includes("Chrome")) {
          browserName = "other";
          browserVersion = "N/A";
        } else if (
          isAndroid &&
          !navigator.userAgent.split(")")[2].split("/").length !== 3
        ) {
          browserName = "Google Chrome";
          browserVersion = userAgent.match(/Chrome\/(\S+)/)[1] || null;
        } else {
          browserName = "other";
          browserVersion = "N/A";
        }

        let moduleSelect = false;
        let allModule = [];
        if (
          Object.keys(data.configData["font"]).includes("url") &&
          data.configData["font"]["url"]
        ) {
          loadCustomFont(data.configData["font"]["url"]);
        } else {
          loadCustomFont(
            "https://superapp-images-inspektlabs.s3.eu-central-1.amazonaws.com/Font_style/default/d-din.regular+(1).ttf"
          );
        }
        if (
          Object.keys(data.configData["video-page"]).includes(
            "restrictUserIfallNotGreen"
          )
        ) {
          setRestrictUserIfallNotGreen(
            data.configData["video-page"]["restrictUserIfallNotGreen"]
          );
        }
        data.configData["blocks"].map((e) => {
          allModule.push(e.tag);
          if (
            e["name"] === "Video Capture" ||
            e["name"] === "New Video Capture"
          ) {
            startModel();
            moduleSelect = true;
          }
          if (e["name"] === "Windshield Damage") {
            windshiledModuleCount = true;
          }
          if (e["name"] === "damage") {
            damageModuleCount = true;
          }
          if (e["name"] === "New ZIZO Capture") {
            startModel();
            zizoApiCalling(sampleBase64Img);
          }
        });
        try {
          const res = await makeRequest("/setRetake", {
            token: userToken,
            resumeFlow: data.configData["resumeFlow"],
          });
          if (res.ok) {
            console.log("initialize inspection id", startdates - Date.now());
            startdates = Date.now();
            let retakeData = await res.json();
            SetRetake_inspectionId = retakeData.data;
            reOpen = retakeData.reOpen ? retakeData.reOpen : false;
            setRetake(retakeData.retake);
            initializeretake(retakeData.retake);
            if (retakeData.status === "error") {
              setErrorMessage(config["errors"]["services"]);
              setScreen("error");
            } else {
              const userAgent = navigator.userAgent;
              const md = new MobileDetect(userAgent);
              let logData = {
                position: 0,
                inspection_id: retakeData.data,
                client_id: data.clientId,
                token: userToken,
                browser_name: browserName,
                browser_version: browserVersion,
                os: isIOS ? "ios" : isAndroid && "android",
                app: moduleSelect ? "video" : "image",
                total_module: allModule.join(", "),
                user_agent: JSON.stringify(navigator.userAgent),
                mobile_detect: JSON.stringify(md),
                module_count: allModule.length.toString(),
                case_id: data.caseId,
              };

              // let logDataS3 = {
              //   date: Date.now(),
              //   inspection_id: retakeData.data,
              //   client_id: data.clientId,
              //   token: userToken,
              //   browser_name: browserName,
              //   browser_version: browserVersion,
              //   os: isIOS ? "ios" : isAndroid && "android",
              //   app: moduleSelect ? "video" : "image",
              //   total_module: allModule.join(", "),
              //   camera_permission: "",
              //   gyro_permission: "",
              //   desktop_view: "",
              //   model_initialized: "",
              //   video_data: "",
              //   user_step_covered: "",
              //   image_upload_status: "",
              //   final_image_Upload: "",
              //   last_page: "",
              //   mobile_detect: JSON.stringify(md),
              //   module_count: allModule.length.toString(),
              //   case_id: data.caseId,
              //   loading_timer: "",
              //   complete_module: "",
              //   location_permission: ""
              // };

              //checking if email is saved or not
              if (
                Object.keys(data.configData).includes("resumeFlow") &&
                data.configData["resumeFlow"] &&
                reOpen
              ) {
                try {
                  if (
                    data.configData["email"] &&
                    data.configData["intro-page"]["show-email-option"]
                  ) {
                    let makereq = await makeRequest(
                      `/setResumeFlowEmail/${SetRetake_inspectionId}/GET`
                    );
                    if (makereq.ok) {
                      const emailData = await makereq.json();
                      let fetchEmail = emailData.data ? emailData.data : "";
                      if (fetchEmail != "") {
                        resumeFlowEmailAllow = false;
                        initializeEmail(fetchEmail);
                        setUserEmail(fetchEmail);
                      }
                    }
                  }
                  let stepCountReq = await makeRequest(
                    `/setUserStepCount/${SetRetake_inspectionId}/GET`
                  );
                  if (stepCountReq.ok) {
                    const stepCount = await stepCountReq.json();
                    if (stepCount) {
                      let finalresult = stepCount.path.split(", ");
                      setPathData(finalresult);
                    }
                  }
                } catch (err) {
                  console.log("fetch email redis error     ", err);
                }
              }

              //check if some photo is uploaded or not
              if (
                Object.keys(data.configData).includes("resumeFlow") &&
                data.configData["resumeFlow"] &&
                reOpen
              ) {
                try {
                  let makereq = await makeRequest(
                    `/fetchResumeFlow/${SetRetake_inspectionId}/GET`
                  );
                  if (makereq.ok) {
                    const allowFetchData = await makereq.json();
                    fetchDataFromRedis = allowFetchData.flag
                      ? allowFetchData.flag
                      : false;
                    setRedisFlowSetFirstTime(fetchDataFromRedis);
                  }
                } catch (err) {
                  console.log("fetch redis flow error");
                  fetchDataFromRedis = false;
                  setRedisFlowSetFirstTime(fetchDataFromRedis);
                }
              }

              setConfig(data.configData);
              setInspectionID(retakeData.data);
              initializeInspectionId(retakeData.data);
              setInspectionId(retakeData.data);
              setCaseId(data.caseId);
              // sendLogData(logData, logDataS3, data, retakeData.data);
              sendLogData(logData, {}, data, retakeData.data);
            }
          } else {
            setErrorMessage(config["errors"]["services"]);
            setScreen("error");
            return;
          }
        } catch (err) {
          setErrorMessage(config["errors"]["services"]);
          setScreen("error");
          console.log(err);
          return;
        }
        setClientId(data.clientId);
        initializeToken(userToken);
        //In case of success
        setToken(userToken);
        setConfig(data.configData);

        //setting resume flow
        if (
          Object.keys(data.configData).includes("resumeFlow") &&
          data.configData["resumeFlow"]
        ) {
          setResumeFlowAllow(data.configData["resumeFlow"]);
        }

        if (
          Object.keys(data.configData).includes("resumeFlow") &&
          data.configData["resumeFlow"] &&
          reOpen &&
          fetchDataFromRedis
        ) {
          const retakeRedis = await makeRequest(
            `/redisConfigValue/${SetRetake_inspectionId}/GET`
          );
          const countRedis = await makeRequest(
            `/redisCountValue/${SetRetake_inspectionId}/GET`
          );
          if (windshiledModuleCount) {
            const windshiledCount = await makeRequest(
              `/windshieldRedisCount/${SetRetake_inspectionId}/GET`
            );
            if (windshiledCount.ok) {
              const countResult = await windshiledCount.json();
              setWindShieldDamageCount(countResult.count);
            }
          }
          if (damageModuleCount) {
            const damageCount = await makeRequest(
              `/damageRedisCount/${SetRetake_inspectionId}/GET`
            );
            if (damageCount.ok) {
              const countResult = await damageCount.json();
              setCount(countResult.count);
              setDamageCount(countResult.count);
              setCurrentDamage(countResult.count);
            }
          }
          if (countRedis.ok) {
            const countResult = await countRedis.json();
            setResumeFlowCount(countResult.count);
          }
          if (retakeRedis.ok) {
            let res1 = await retakeRedis.json();
            setCondition(res1.conditions);
            setMandatory(res1.mandatoryConditions);
            setSubMandatory(res1.subMandatoryConditions);
            setSubCondition(res1.subConditions);

            //resumeFlow
            setConditionResume(res1.conditions);
            setMandatoryResume(res1.mandatoryConditions);
            setSubMandatoryResume(res1.subMandatoryConditions);
            setSubConditionResume(res1.subConditions);
          }
        } else {
          data.configData["blocks"].map((item) => {
            finalConditions[item.id] = "pending";
            if (item.mandatory) {
              mandatoryConditions[item.id] = "pending";
            }
            if (item.subSections) {
              subMandatoryConditions[item.id] = {};
              finalSubConditions[item.id] = {};
              item.values.map((item2) => {
                if (item2.mandatory) {
                  subMandatoryConditions[item.id][item2.name] = "pending";
                }
                finalSubConditions[item.id][item2.name] = "pending";
                if (item2.subSections) {
                  finalSubConditionsSub[item2.name] = {};
                  subMandatoryConditionsSub[item2.name] = {};
                  item2.values.map((item3) => {
                    finalSubConditionsSub[item2.name][item3.name] = "pending";
                    if (item3.mandatory) {
                      subMandatoryConditionsSub[item2.name][item3.name] =
                        "pending";
                    }
                  });
                }
              });
            }
          });
          setCondition(finalConditions);
          setMandatory(mandatoryConditions);
          setSubMandatory(subMandatoryConditions);
          setSubMandatorySub(subMandatoryConditionsSub);
          setSubCondition(finalSubConditions);
          setSubConditionSub(finalSubConditionsSub);

          // console.log("loading 2 ::::   ", finalConditions, mandatoryConditions, subMandatoryConditions, finalSubConditions)

          //resumeFlow
          if (
            Object.keys(data.configData).includes("resumeFlow") &&
            data.configData["resumeFlow"]
          ) {
            setConditionResume(finalConditions);
            setMandatoryResume(mandatoryConditions);
            setSubMandatoryResume(subMandatoryConditions);
            setSubConditionResume(finalSubConditions);
          }
        }
      }
    }
  };
  const sendLogData = async (value, value1, value2, insp) => {
    if (value2 !== null) {
      if (!reOpen) await userLogs(value, value1, true);
      else {
        await setUserLogs(value1);
      }
      patLogs("Entered to loading page", insp, "loading");
      userLogs({
        position: 8,
        last_page: "loading page",
        inspectionId: insp,
        pos: "loading",
      });
      endDateLog = new Date().getTime();
      userLogs({
        position: 12,
        loading_timer: `${endDateLog - startDateLog} ms`,
        inspectionId: insp,
        pos: "loading",
      });
      if (window.ReactNativeWebView || navigator.userAgent.indexOf("wv") > -1) {
        if (
          Object.keys(value2.configData).includes("rejectionOption") &&
          value2.configData["rejectionOption"]
        ) {
          setScreen("rejection");
        } else if (
          value2.configData["email"] &&
          value2.configData["intro-page"]["show-email-option"]
        ) {
          if (resumeFlowEmailAllow) {
            setScreen("email");
          } else {
            if (fetchDataFromRedis) {
              setScreen("restart");
            } else {
              setScreen("instructions");
            }
          }
        } else {
          if (fetchDataFromRedis) {
            setScreen("restart");
          } else {
            if (
              value2.configData["email"] &&
              !value2.configData["intro-page"]["show-email-option"]
            ) {
              setScreen("email");
            } else {
              setScreen("instructions");
            }
          }
        }
      } else if (
        isAndroid &&
        !navigator.userAgent.includes("Chrome") &&
        !navigator.userAgent.includes("Samsung") &&
        !navigator.userAgent.includes("Edg")
      ) {
        setPhone("android");
        setScreen("error");
      } else if (
        isAndroid &&
        navigator.userAgent.split(")")[2].split("/").length !== 3 &&
        !navigator.userAgent.includes("Samsung") &&
        !navigator.userAgent.includes("Edg")
      ) {
        setPhone("android");
        setScreen("error");
      } else if (
        Object.keys(value2.configData).includes("rejectionOption") &&
        value2.configData["rejectionOption"]
      ) {
        setScreen("rejection");
      } else if (value2.configData["email"] && value2.configData["intro-page"]["show-email-option"] ) {
        if (resumeFlowEmailAllow) {
          setScreen("email");
        } else {
          if (fetchDataFromRedis) {
            setScreen("restart");
          } else {
            setScreen("instructions");
          }
        }
        sendCallback(value, value2.configData, insp);
      } else {
        if (fetchDataFromRedis) {
          setScreen("restart");
        } else {
          if (
            value2.configData["email"] &&
            !value2.configData["intro-page"]["show-email-option"]
          ) {
            setScreen("email");
          } else {
            setScreen("instructions");
          }
        }
        sendCallback(value, value2.configData, insp);
      }
    }
  };
  const sendCallback = (value, configData, insp) => {
    if (
      Object.keys(configData).includes("vmgFLowType") &&
      Object.keys(configData).includes("vmgUrl")
    ) {
      fetch(configData["vmgUrl"], {
        method: "POST",
        headers:
          configData["authorization"] === "" ||
          configData["authorization"] === null
            ? {
                "Content-Type": "application/json",
              }
            : {
                "Content-Type": "application/json",
                Authorization: configData["authorization"],
              },
        body: JSON.stringify({
          caseId: value.case_id,
          inspectionId: insp,
          webAppToken: value.token,
          status: "Url was opened",
          code: 1,
          // "imageUrl": urls
        }),
      })
        .then((response) => {
          // Handle response
          console.log(response);
        })
        .catch((error) => {
          // Handle error
          console.error("Error:", error);
        });
    }
  };
  useEffect(() => {
    window.top.postMessage("appLoaded", "*");
    checkToken();
  }, []);

  return (
    <div className="result-wait">
      <img src={loading}></img>
      <h3>
        {document.referrer && document.referrer.includes("portoseguro.com")
          ? "Verificando o token ..."
          : "Verifying Token ..."}
      </h3>
    </div>
  );
};

export default Loading;
