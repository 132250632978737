import React, {
  useEffect,
  useContext,
  useRef,
  Fragment,
  useState,
} from "react";
import { AppContext } from "../context";
import {
  addImageToList,
  setLog,
  userLogs,
  patLogs,
  translateText,
} from "../context/utils";
import cameraImg from "../img/camera.png";
import Button from "./Button";
import Rotate from "./Rotate";
import VINImg from "../img/VINPopup.png";
import cameraGuidanceImg from "../img/cameraGuidanceImg.jpg";
// import ReactGA from "react-ga4";

const screenfull = require("screenfull");
let imageCaptured = false;
let image = {};
let cameraStarted = false;
let track = null;
let imgUrl = null;
let logBugs = true;

const SingleImage = () => {
  const {
    inspectionId,
    clientId,
    stepCovered,
    setStepCovered,
    condition,
    config,
    currentBlock,
    setCurrentPage,
    setCondition,
    setScreen,
    mandatory,
    setMandatory,
    isLandscape,
    setLandscape,
  } = useContext(AppContext);
  let webCamPromise = null;

  const constraints = {
    video: Object.keys(config).includes("aspectRatio")
      ? {
          width: Object.keys(config).includes("resolution")
            ? config["resolution"]["width"]
            : 1920,
          height: Object.keys(config).includes("resolution")
            ? config["resolution"]["height"]
            : 1080,
          facingMode: "environment",
          aspectRatio: 4 / 3,
          zoom: {
            ideal: Object.keys(config).includes("zoom")
              ? config["zoom"]
                ? 0.5
                : 1
              : 0.5,
          },
        }
      : {
          width: Object.keys(config).includes("resolution")
            ? config["resolution"]["width"]
            : 1920,
          height: Object.keys(config).includes("resolution")
            ? config["resolution"]["height"]
            : 1080,
          facingMode: "environment",
          zoom: {
            ideal: Object.keys(config).includes("zoom")
              ? config["zoom"]
                ? 0.5
                : 1
              : 0.5,
          },
        },
    audio: false,
  };

  //Reference vaiables
  const singleScreen = useRef();
  const videoRef = useRef();
  const canvasRef = useRef();
  const startBtn = useRef();
  const imgRef = useRef();
  const backRef = useRef();
  const divImgRef = useRef();
  const guidanceTextRef = useRef();
  const redLineVINcontainer = useRef();
  const cardRef = useRef();

  const land = () => window.innerWidth > window.innerHeight * 1.2;

  window.onresize = () => {
    setLandscape(land());
    if (land()) {
      if (cameraStarted) {
        startCamera();
      }
    }
  };

  if (logBugs) {
    patLogs(`Entered to ${currentBlock.tag}`, inspectionId);
    // ReactGA.send({
    //   hitType: "pageview",
    //   page: `${currentBlock.tag}`,
    //   title: `${currentBlock.tag}`,
    // });
    userLogs({
      position: 8,
      last_page: `${currentBlock.tag} Module`,
      inspectionId,
    });
  }
  logBugs = false;
  const startCamera = () => {
    webCamPromise = navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        window.stream = stream;
        track = stream.getTracks()[0];
        videoRef.current.srcObject = stream;
        if (currentBlock["tag"] === "VIN") {
          if (
            !Object.keys(config["single-image"]).includes("vin_popup") &&
            !config["single-image"]["vin_popup"]
          ) {
            setTimeout(() => {
              startBtn.current.style.display = "block";
            }, 500);
          }
        } else {
          setTimeout(() => {
            startBtn.current.style.display = "block";
          }, 500);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    cameraStarted = true;
    if (imgUrl) {
      divImgRef.current.src = imgUrl;
      imgRef.current.style.display = "block";
    }
  };

  const getTranslateText = (text) => {
    if (Object.keys(config["language"]).includes(text)) {
      return config["language"][text];
    } else {
      // const data = translateText(text, config["language"]["langCode"]);
      return text;
    }
  };

  const captureImg = () => {
    //patLogs("Image capture button clicked", inspectionId);
    const cnv = canvasRef.current;
    const vw = videoRef.current;
    cnv.width = vw.videoWidth;
    cnv.height = vw.videoHeight;
    cnv.getContext("2d").drawImage(vw, 0, 0);
    const imgData = cnv.toDataURL("image/jpeg");
    imgUrl = imgData;
    divImgRef.current.src = imgData;
    let region = Object.keys(config["video-page"]).includes("clientRegion")
      ? config["video-page"]["clientRegion"]
      : "eu";
    image = {
      name: `${currentBlock["tag"]}.jpg`,
      imgData: cnv.toDataURL("image/jpeg"),
      tag: currentBlock["tag"],
      web_region: null,
      angle: null,
      region: region,
      videoData: null,
      dialStatus: null,
      currentBlockName : currentBlock["id"]
    };
    if (
      ["VIN", "Odometer", "Open Trunk", "Gear Shift"].includes(
        currentBlock["tag"]
      )
    )
      redLineVINcontainer.current.style.display = "none";
    imgRef.current.style.display = "block";
  };

  const imageRetake = () => {
    setStepCovered([...stepCovered]);
    // patLogs("retake button clicked for capture new image again", inspectionId);
    imgUrl = null;
    if (
      ["VIN", "Odometer", "Open Trunk", "Gear Shift"].includes(
        currentBlock["tag"]
      )
    )
      redLineVINcontainer.current.style.display = "flex";
    imgRef.current.style.display = "none";
  };

  const imageNext = () => {
    logBugs = true;
    if (imageCaptured) return;
    patLogs(
      `tick button clicked and image is sent for uploading , ${currentBlock["tag"]} module is completed`,
      inspectionId
    );
    cameraStarted = false;
    imageCaptured = true;
    if (currentBlock["mandatory"]) {
      let temp1 = mandatory;
      temp1[currentBlock["id"]] = "completed";
      setMandatory(temp1);
    }
    let temp = condition;
    temp[currentBlock["id"]] = "completed";
    setCondition(temp);
    setScreen("menu");
    setLog(`${currentBlock["tag"]} was captured`);
    // add image here for sending
    addImageToList(image);
    window.stream = null;
    track.stop();
    imgUrl = null;
  };

  const back = () => {
    logBugs = true;
    patLogs("back button clicked", inspectionId);
    window.stream = null;
    track.stop();
    setScreen("menu");
    cameraStarted = false;
  };

  useEffect(() => {
    startCamera();
    setLandscape(land());
    imageCaptured = false;
    setTimeout(() => {
      if (
        ["VIN", "Odometer", "Open Trunk", "Gear Shift"].includes(
          currentBlock["tag"]
        )
      ) {
        if (
          currentBlock["tag"] === "VIN" &&
          Object.keys(config["single-image"]).includes("vin_popup") &&
          config["single-image"]["vin_popup"]
        )
          cardRef.current.style.display = "flex";
        else redLineVINcontainer.current.style.display = "flex";
        switch (currentBlock["tag"]) {
          case "VIN":
            if (config["single-image"]["guidance-text"])
              guidanceTextRef.current.innerHTML =
                config["single-image"]["guidance-text"];
            else
              guidanceTextRef.current.innerHTML = getTranslateText(
                "Please position inside the white-bordered box"
              );
            break;
          case "Odometer":
            if (config["single-image"]["guidance-text-odo"])
              guidanceTextRef.current.innerHTML =
                config["single-image"]["guidance-text-odo"];
            else
              guidanceTextRef.current.innerHTML = getTranslateText(
                "Please position inside the white-bordered box"
              );
            break;
          case "Open Trunk":
            if (config["single-image"]["guidance-text-trunk"])
              guidanceTextRef.current.innerHTML =
                config["single-image"]["guidance-text-trunk"];
            else
              guidanceTextRef.current.innerHTML = getTranslateText(
                "Please position inside the white-bordered box"
              );
            break;
          case "Gear Shift":
            if (config["single-image"]["guidance-text-gear"])
              guidanceTextRef.current.innerHTML =
                config["single-image"]["guidance-text-gear"];
            else
              guidanceTextRef.current.innerHTML = getTranslateText(
                "Please position inside the white-bordered box"
              );
            break;
          default:
            guidanceTextRef.current.innerHTML = getTranslateText(
              "Please position inside the white-bordered box"
            );
        }
      }
    }, 1000);
  }, []);

  return (
    <Fragment>
      {!isLandscape ? (
        <Rotate />
      ) : (
        <div
          id="vin-screen"
          ref={singleScreen}
          style={{
            height: ["porto_seguros"].includes(clientId) ? "90dvh" : "100dvh",
          }}
        >
          <canvas
            ref={canvasRef}
            id="ios-canvas"
            style={
              Object.keys(config).includes("aspectRatio")
                ? {
                    width: "100vw",
                    height: "100vh",
                    objectFit: "contain",
                    backgroundColor: "black",
                  }
                : {}
            }
          ></canvas>
          <video
            ref={videoRef}
            id="videoWindow"
            style={
              Object.keys(config).includes("aspectRatio")
                ? {
                    width: "100vw",
                    height: "100vh",
                    objectFit: "contain",
                    backgroundColor: "black",
                  }
                : {}
            }
            autoPlay
            playsInline
          ></video>
          {["VIN"].includes(currentBlock["tag"]) && (
            <div ref={cardRef} id="display-card-vin">
              <div id="image-container">
                <img style={{ width: "70%" }} src={VINImg}></img>
              </div>
              <div id="btn-container">
                <button
                  style={{
                    backgroundColor: config["colors"]["btn"],
                    color: "white",
                    width: "20%",
                    height: "100%",
                    borderRadius: "10px",
                    border: "none",
                  }}
                  onClick={() => {
                    cardRef.current.style.display = "none";
                    redLineVINcontainer.current.style.display = "flex";
                    startBtn.current.style.display = "block";
                  }}
                >
                  {Object.keys(config["single-image"]).includes("btnVinText")
                    ? config["single-image"]["btnVinText"]
                    : getTranslateText("Ok got it")}
                </button>
              </div>
            </div>
          )}
          {["VIN", "Odometer", "Open Trunk", "Gear Shift"].includes(
            currentBlock["tag"]
          ) && (
            <div
              className="redLineVINcontainerView"
              style={{
                height: ["porto_seguros"].includes(clientId)
                  ? "90dvh"
                  : "100dvh",
              }}
              ref={redLineVINcontainer}
            >
              <div id="modalNameCont">
                <p id="modalNameText">{currentBlock["id"]}</p>
              </div>
              <div
                className="redLineVIN"
                style={{
                  width: "70vw",
                  height:
                    currentBlock.tag === "VIN"
                      ? "40vh"
                      : ["porto_seguros"].includes(clientId)
                      ? "47vh"
                      : "70vh",
                }}
              ></div>
              <div id="guidanceText" style={{ width: "auto" }}>
                <p
                  style={
                    ["porto_seguros", "porto_seguros_uat", "ss321gn"].includes(
                      clientId
                    )
                      ? { fontSize: "15px" }
                      : {}
                  }
                  ref={guidanceTextRef}
                  id="guidanceParaText"
                ></p>
              </div>
            </div>
          )}
          <div
            ref={startBtn}
            style={{
              backgroundImage: `url(${cameraImg})`,
              backgroundSize: "37px 37px",
              backgroundRepeat: "no-repeat",
              objectFit: "cover",
              backgroundPosition: "center",
            }}
            onClick={captureImg}
            id="btn-vin-start"
          ></div>
          <div ref={backRef} className="back-btn">
            <i className="fas fa-arrow-left fa-2x" onClick={back}></i>
          </div>
          {Object.keys(currentBlock).includes("cameraInstruction") && (
            <div className="camerInst">
              <p>{currentBlock["cameraInstruction"]}</p>
            </div>
          )}
          <div
            id="vin-image"
            style={
              Object.keys(config).includes("aspectRatio")
                ? { backgroundColor: "black", objectFit: "contain" }
                : {}
            }
            ref={imgRef}
          >
            <img
              ref={divImgRef}
              style={
                Object.keys(config).includes("aspectRatio")
                  ? { objectFit: "contain" }
                  : { width: "100vw", height: "100vh" }
              }
            />
            <i
              className="fas fa-times fa-2x vin-btn-retake"
              onClick={imageRetake}
            ></i>
            <i
              className="fas fa-check fa-2x vin-btn-next"
              onClick={imageNext}
            ></i>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default SingleImage;
