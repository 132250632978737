import React, { useContext, useEffect, useRef, useState } from "react";
import takeCamera from "../img/takeCamera.png";
import camera from "../img/camera.png";
import { AppContext } from "../context";
import Rotate from "./Rotate";
import Button from "./Button";
import {
  addImageToList,
  setLog,
  userLogs,
  patLogs,
  resumeFlowFinalSubmitHandler,
  translateText,
} from "../context/utils";
let logbugs = true;
// import ReactGA from "react-ga4";

const screenfull = require("screenfull");

let cameraStarted = false;
let captured = 0;
let images = [];
let track = null;
let imgData = null;

const Damage = () => {
  const {
    setDamageModuleCompleted,
    stepCovered,
    setStepCovered,
    clientId,
    setScreen,
    showAlert,
    setCurrentPage,
    currentTag,
    currentSubBlock,
    imageCaptured,
    screen,
    condition,
    setCondition,
    currentBlock,
    config,
    isLandscape,
    setLandscape,
    count,
    setCount,
    imgPosition,
    setImgPosition,
    currentDamage,
    setCurrentDamage,
    fImg,
    setFImg,
    sImg,
    setSImg,
    page,
    setPage,
    mandatory,
    setMandatory,
    currentSubObject,
    inspectionId,
  } = useContext(AppContext);

  let webCamPromise = null;
  let model = null;
  let active = true;
  const [endDialog, setEndDialog] = useState(false);
  const constraints = {
    video: Object.keys(config).includes("aspectRatio")
      ? {
          width: Object.keys(config).includes("resolution")
            ? config["resolution"]["width"]
            : 1920,
          height: Object.keys(config).includes("resolution")
            ? config["resolution"]["height"]
            : 1080,
          facingMode: "environment",
          aspectRatio: 4 / 3,
          zoom: {
            ideal: Object.keys(config).includes("zoom")
              ? config["zoom"]
                ? 0.5
                : 1
              : 0.5,
          },
        }
      : {
          width: Object.keys(config).includes("resolution")
            ? config["resolution"]["width"]
            : 1920,
          height: Object.keys(config).includes("resolution")
            ? config["resolution"]["height"]
            : 1080,
          facingMode: "environment",
          zoom: {
            ideal: Object.keys(config).includes("zoom")
              ? config["zoom"]
                ? 0.5
                : 1
              : 0.5,
          },
        },
    audio: false,
  };
  const videoRef = useRef();
  const startBtn = useRef();
  const canvasRef = useRef();
  const damageScreen = useRef();
  const modalRef = useRef();
  const modalImg = useRef();

  if (logbugs) {
    // patLogs("Damage Module -> At instruction page", inspectionId);
    userLogs({ position: 8, last_page: "damage module", inspectionId });
    // ReactGA.send({
    //   hitType: "pageview",
    //   page: "Damage module",
    //   title: "Damage module",
    // });
  }
  logbugs = false;

  const land = () => window.innerWidth > window.innerHeight * 1.2;

  const getTranslateText = (text) => {
    if (Object.keys(config["language"]).includes(text)) {
      return config["language"][text];
    } else {
      // const data = translateText(text, config["language"]["langCode"]);
      return text;
    }
  };

  window.onresize = () => {
    setLandscape(land());
    if (cameraStarted) startCamera();
  };

  const Text = (text) => {
    if (Object.keys(config["language"]).includes(text)) {
      return config["language"][text];
    } else {
      const data = translateText(text, config["language"]["langCode"]);
      return data;
    }
  };

  const ZoomStart = () => {
    // patLogs(
    //   "Damage Module -> At Capturing (Zoom out & zoon in) images",
    //   inspectionId
    // );
    setPage("damage");
  };

  const TakeMore = () => {
    cameraStarted = false;
    images = [];
    setSImg(null);
    setFImg(null);
    setCount(count + 1);
    // patLogs("Take more Damage images", inspectionId);
  };

  const End = () => {
    cameraStarted = false;
    if (currentBlock["mandatory"]) {
      let temp1 = mandatory;
      temp1[currentBlock["id"]] = "completed";
      setMandatory(temp1);
    }
    let temp = condition;
    temp[currentBlock["id"]] = "completed";
    setCondition(temp);
    patLogs(
      "Damages Module Submited (user clicked to end button)",
      inspectionId
    );
    setDamageModuleCompleted(true);
    resumeFlowFinalSubmitHandler(currentBlock["id"]);
    setScreen("menu");
    setPage("inst");
  };

  const ZoomIn = () => {
    //patLogs("Zoom in section clicked to capture image", inspectionId);
    setPage("camera");
    setImgPosition(0);
    startCamera();
  };

  const ZoomOut = () => {
    // patLogs("Zoom out section clicked to capture image", inspectionId);
    setPage("camera");
    setImgPosition(1);
    startCamera();
  };

  const startCamera = () => {
    if (imgData) {
      modalImg.current.src = imgData;
      modalRef.current.classList.add("active");
    }
    cameraStarted = true;
    webCamPromise = navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        window.stream = stream;
        track = stream.getTracks()[0];
        videoRef.current.srcObject = stream;
        setTimeout(() => {
          startBtn.current.style.display = "block";
        }, 500);
      });
  };

  const captureImg = () => {
    //patLogs("Image is captured", inspectionId);
    const cnv = canvasRef.current;
    const vw = videoRef.current;
    cnv.width = vw.videoWidth;
    cnv.height = vw.videoHeight;
    cnv.getContext("2d").drawImage(vw, 0, 0);
    imgData = cnv.toDataURL("image/jpeg");
    if (imgPosition === 0) {
      setFImg(cnv.toDataURL("image/jpeg"));
    } else if (imgPosition === 1) {
      setSImg(cnv.toDataURL("image/jpeg"));
    }
    active = false;
    window.stream = null;
    captured++;
    showModal();
  };

  const showModal = (e) => {
    modalImg.current.src = imgData;
    modalRef.current.classList.add("active");
  };

  const combineImage = async (fImg, sImg) => {
    const image1 = await loadImage(fImg);
    const image2 = await loadImage(sImg);

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    let width = Object.keys(config).includes("resolution")
      ? config["resolution"]["width"]
      : 1920;
    let height = Object.keys(config).includes("resolution")
      ? config["resolution"]["height"]
      : 1080;
    canvas.width = width;
    canvas.height = height;
    context.fillStyle = "white";
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.drawImage(image1, 0, height / 4, width / 2, height / 2);
    context.drawImage(image2, width / 2, height / 4, width / 2, height / 2);
    context.lineWidth = 15;
    context.strokeStyle = "yellow";
    context.strokeRect(0, 0, width, height);

    const dataURL = canvas.toDataURL("image/jpeg");
    return dataURL;
  };

  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
      img.src = src;
    });
  };
  const handleNext = async () => {
    // patLogs("tick button clicked image sent for uploading...", inspectionId);
    startBtn.current.style.display = "none";
    cameraStarted = false;
    track.stop();
    window.stream = null;
    if (fImg) {
      //patLogs("Zoom In image is clicked", inspectionId);
    }
    if (sImg) {
      //patLogs("Zoom Out image is clicked", inspectionId);
    }
    if (fImg && sImg) {
      // patLogs(
      //   `Round ${count + 1} Both images was captured and sent for uploading`,
      //   inspectionId
      // );
      setLog(`${currentBlock["tag"]} was completed`);
      setCurrentDamage(currentDamage + 1);
      let region = Object.keys(config["video-page"]).includes("clientRegion")
        ? config["video-page"]["clientRegion"]
        : "eu";
      let combinedata = await combineImage(fImg, sImg);
      images = [
        {
          name: `D_${currentDamage}_${captured}.jpg`,
          imgData: combinedata,
          tag: "Damage",
          coordinates: null,
        },
        {
          angle: null,
          region: region,
          web_region: null,
          videoData: null,
          dialStatus: null,
        },
      ];
      //send images
      addImageToList(images);
    }
    setPage("damage");
    imgData = null;
  };

  const handleRetake = () => {
    // patLogs("retake button clicked to capture image again", inspectionId);
    imgData = null;
    images = [];
    modalRef.current.classList.remove("active");
    captured = 0;
    if (imgPosition === 0) {
      setFImg(null);
    }
    if (imgPosition === 1) {
      setSImg(null);
    }
  };

  const back = () => {
    cameraStarted = false;
    patLogs("back button clicked", inspectionId);
    setScreen("menu");
    setPage("inst");
    setSImg(null);
    setFImg(null);
    logbugs = true;
  };

  const goback = () => {
    // patLogs("back button clicked and return to damage module", inspectionId);
    cameraStarted = false;
    track.stop();
    window.stream = null;
    setPage("damage");
  };
  useEffect(() => {
    setLandscape(land());
  }, []);

  return (
    <div className="damage-root">
      {!isLandscape ? (
        <Rotate />
      ) : page === "inst" ? (
        <div className="zoom-gif damage_size">
          <div className="dmgIns">
            <h1>{config["damage-page"]["menu-title"]}</h1>
            <div className="inst">
              <img src={config["damage-page"]["zoomIn-menu-image"]} />
              <div>
                <h3>{config["damage-page"]["zoomIn-menu-title"]}</h3>
                <p style={{ fontWeight: "600" }}>
                  {config["damage-page"]["zoomIn-menu-text"]}
                </p>
              </div>
            </div>
            <div className="inst">
              <img src={config["damage-page"]["zoomOut-menu-image"]} />
              <div>
                <h3>{config["damage-page"]["zoomOut-menu-title"]}</h3>
                <p style={{ fontWeight: "600" }}>
                  {config["damage-page"]["zoomOut-menu-text"]}
                </p>
              </div>
            </div>
          </div>
          <div className="InternetWarning">
            <p>{showAlert && showAlert}</p>
          </div>
          <div className="sep-btn">
            <div className="sep-btn-div">
              <Button
                secoundaryBtn="true"
                className="start"
                onclick={back}
                text={config["damage-page"]["back-btn-text"]}
                marginDefault="0px 0px 0px 0px"
              />
              <Button
                className="start"
                onclick={ZoomStart}
                text={config["damage-page"]["menu-btn-text"]}
                marginDefault="0px 25px 0px 25px"
              />
            </div>
          </div>
        </div>
      ) : page === "damage" ? (
        <div className="screen18 damage_size">
          {endDialog && (
            <div className="confirmEnd">
              <div className="confirmEndBox">
                <p>
                  {Object.keys(config["damage-page"]).includes("endPopupMsg")
                    ? config["damage-page"]["endPopupMsg"]
                    : getTranslateText("Are you sure want to end ?")}
                </p>
                <div className="confirmEndBoxBtn">
                  <Button
                    className="start"
                    style={{ marginTop: "0px", padding: "0px" }}
                    onclick={() => setEndDialog(false)}
                    text={
                      Object.keys(config["damage-page"]).includes(
                        "endPopupNoBtn"
                      )
                        ? config["damage-page"]["endPopupNoBtn"]
                        : getTranslateText("No")
                    }
                  />
                  <Button
                    className="start"
                    style={{ marginTop: "0px", padding: "0px" }}
                    onclick={End}
                    text={
                      Object.keys(config["damage-page"]).includes(
                        "endPopupYesBtn"
                      )
                        ? config["damage-page"]["endPopupYesBtn"]
                        : getTranslateText("Yes")
                    }
                  />
                </div>
              </div>
            </div>
          )}
          <h1>{config["damage-page"]["select-screen-title"]}</h1>
          <div className="post-sub">
            <div className="post-sub-div">
              <div className="zoom-container">
                <h3>{config["damage-page"]["zoomIn-select-text"]}</h3>
                <div
                  disabled={fImg ? true : false}
                  className={fImg ? "timg" : "rimg"}
                >
                  <img
                    src={fImg ? fImg : takeCamera}
                    onClick={!fImg && ZoomIn}
                  />
                </div>
                <div className="zoom-ALigment">
                  <div className={fImg ? "captured" : "pending"}>
                    {fImg ? "✓" : "!"}
                  </div>
                  {fImg
                    ? config["damage-page"]["captured"]
                    : config["damage-page"]["pending"]}
                </div>
              </div>
              <div className="zoom-container">
                <h3>{config["damage-page"]["zoomOut-select-text"]}</h3>
                <div disabled={true} className={sImg ? "timg" : "rimg"}>
                  <img
                    src={sImg ? sImg : takeCamera}
                    onClick={!sImg && ZoomOut}
                  />
                </div>
                <div className="zoom-ALigment">
                  <div className={sImg ? "captured" : "pending"}>
                    {sImg ? "✓" : "!"}
                  </div>
                  {sImg
                    ? config["damage-page"]["captured"]
                    : config["damage-page"]["pending"]}
                </div>
              </div>
            </div>
            <div className="InternetWarning">
              <p>{showAlert && showAlert}</p>
            </div>
            <div className="damage-btn">
              {/* /////////////////////////Adjust Image count here ////////////////////////////////// */}

              <div className="damage-btn-div">
                {fImg && sImg ? (
                  <div
                    className="btn process-btn"
                    style={{
                      margin: "0px",
                      backgroundColor: "white",
                      color: config["colors"]["secoundaryBtn"]
                        ? config["colors"]["secoundaryBtn"]
                        : "black",
                      border: config["colors"]["secoundaryBtn"]
                        ? `2px solid ${config["colors"]["secoundaryBtn"]}`
                        : "2px solid black",
                    }}
                    onClick={() => setEndDialog(true)}
                  >
                    {config["damage-page"]["end-btn"]}
                  </div>
                ) : null}
                {count !== currentBlock["count"] && fImg && sImg ? (
                  <div
                    className="btn process-btn"
                    style={{
                      margin: "0px 25px 0px 25px",
                      backgroundColor: config["colors"]["btn"],
                    }}
                    onClick={TakeMore}
                  >
                    {config["damage-page"]["more-btn"]}
                  </div>
                ) : null}
              </div>
              {count === currentBlock["count"] ? null : fImg && sImg ? null : (
                <div className="back-div">
                  <div className="back-btn-div">
                    <button
                      className="start-btn"
                      style={{
                        margin: "5px 0px",
                        backgroundColor: "white",
                        color: config["colors"]["secoundaryBtn"]
                          ? config["colors"]["secoundaryBtn"]
                          : "black",
                        border: config["colors"]["secoundaryBtn"]
                          ? `2px solid ${config["colors"]["secoundaryBtn"]}`
                          : "2px solid black",
                      }}
                      onClick={count >= 1 ? () => setEndDialog(true) : back}
                    >
                      {count >= 1
                        ? config["damage-page"]["end-btn"]
                        : config["damage-page"]["back-btn-text"]}
                    </button>
                    {/* <Button
                  text={config["damage-page"]["start-btn-text"] ? config["damage-page"]["start-btn-text"] : "Start"}
                /> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : page === "camera" ? (
        <div id="screen3" ref={damageScreen} className="damage_size">
          <canvas
            ref={canvasRef}
            id="ios-canvas"
            style={
              Object.keys(config).includes("aspectRatio")
                ? {
                    width: "100vw",
                    height: "100vh",
                    objectFit: "contain",
                    backgroundColor: "black",
                  }
                : {}
            }
          ></canvas>
          <video
            ref={videoRef}
            id="videoWindow"
            autoPlay
            playsInline
            style={
              Object.keys(config).includes("aspectRatio")
                ? {
                    width: "100vw",
                    height: "100vh",
                    objectFit: "contain",
                    backgroundColor: "black",
                  }
                : {}
            }
          ></video>
          <div style={{ zIndex: 2 }} className="back-btn">
            <i className="fas fa-arrow-left fa-2x" onClick={goback}></i>
          </div>
          {imgPosition === 0 &&
            Object.keys(currentBlock).includes("cameraInstructionZoomIn") && (
              <div className="camerInst">
                <p>{currentBlock["cameraInstructionZoomIn"]}</p>
              </div>
            )}
          {imgPosition === 1 &&
            Object.keys(currentBlock).includes("cameraInstructionZoomout") && (
              <div className="camerInst">
                <p>{currentBlock["cameraInstructionZoomout"]}</p>
              </div>
            )}
          <div id="btn-car-container" style={{ zIndex: 0 }}></div>
          <div className="clickBtn" ref={startBtn}>
            <img id="btn-android-start1" onClick={captureImg} src={camera} />
          </div>
          <div ref={modalRef} className="reels-modal">
            <img
              ref={modalImg}
              alt=""
              style={
                Object.keys(config).includes("aspectRatio")
                  ? { objectFit: "contain" }
                  : {}
              }
            />
            <div className="reel-container" style={{ bottom: "88px" }}>
              <i
                className="fas fa-times fa-2x vin-btn-retake"
                onClick={handleRetake}
              ></i>
              <i
                className="fas fa-check fa-2x vin-btn-next"
                onClick={handleNext}
              ></i>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Damage;
