import React, { useState, useContext, useEffect } from "react";
import Button from "./Button";
import Header from "./Header";
import { AppContext } from "../context";
import {
  setLog,
  userLogs,
  patLogs,
  makeRequest,
  translateText,
} from "../context/utils";
import Navbar from "./Navbar";

const Restart = () => {
  const { setScreen, token, inspectionId, config, showAlert } =
    useContext(AppContext);

  const land = () => window.innerWidth > window.innerHeight * 1.2;
  const [screenHeight, setScreenHeight] = useState(land() ? false : true);

  window.onresize = () => {
    setScreenHeight(land() ? false : true);
  };

  const getTranslateText = (text) => {
    if (Object.keys(config["language"]).includes(text)) {
      return config["language"][text];
    } else {
      // const data = translateText(text, config["language"]["langCode"]);
      return text;
    }
  };

  const onContinue = () => {
    patLogs(
      "Continue button is clicked on restart page",
      inspectionId,
      "restart"
    );
    setScreen("instructions");
  };

  const onRestart = async () => {
    try {
      patLogs("Restart button is clicked", inspectionId, "restart");
      const res = await makeRequest(`/restartInspection/${token}`);
      if (res.ok) {
        location.reload();
      } else {
        //show an alert of refreshing the page again as somethibng went wrong
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    patLogs("Skip Email page and Entered to restart page", inspectionId);
    userLogs({ position: 8, last_page: "restart page", inspectionId });
  }, []);

  return (
    <div
      className="screen_email"
      style={{
        height: screenHeight ? "100dvh" : "",
        position: screenHeight ? "relative" : "",
        padding: screenHeight ? "0%" : "",
      }}
    >
      <Navbar />
      <div
        id="screen1"
        className="email_screen"
        style={{
          height: screenHeight ? "70vh" : "",
          justifyContent: screenHeight ? "space-evenly" : "",
        }}
      >
        <Header />
        <img src={config["intro-page"]["image"]}></img>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <h2 className="header2" style={{ fontSize: "larger" }}>
            {config["errors"]["restart-page-text"]
              ? config["errors"]["restart-page-text"]
              : getTranslateText(
                  "Do you want to continue old inspection or start a new inspection?"
                )}
          </h2>
          <div
            id="restart-btn-div"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              width: "100%",
            }}
          >
            <Button
              wide={true}
              onclick={onContinue}
              text={
                config["errors"]["continue-btn"]
                  ? config["errors"]["continue-btn"]
                  : getTranslateText("Continue Old Inspection")
              }
            />
            <Button
              secoundaryBtn="true"
              wide={true}
              onclick={onRestart}
              text={
                config["errors"]["restart-btn"]
                  ? config["errors"]["restart-btn"]
                  : getTranslateText("Start New Inspection")
              }
            />
          </div>
        </div>
        <div className="InternetWarning">
          <p>{showAlert && showAlert}</p>
        </div>
      </div>
    </div>
  );
};

export default Restart;
