import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import { AppContext } from "../context";

const Button = ({
  secoundaryBtn,
  text,
  id,
  wide,
  disabled,
  translucent,
  onclick,
  marginDefault,
}) => {
  const { config, clientId } = useContext(AppContext);
  let colors, colors_disabled;
  if (secoundaryBtn === "true") {
    colors = {
      backgroundColor: "white",
      borderColor: config["colors"]["secoundaryBtn"]
        ? config["colors"]["secoundaryBtn"]
        : "black",
      color: config["colors"]["secoundaryBtn"]
        ? config["colors"]["secoundaryBtn"]
        : "black",
      margin: `${marginDefault}`,
    };
    colors_disabled = {
      backgroundColor: "white",
      borderColor: config["colors"]["secoundaryBtn"]
        ? config["colors"]["secoundaryBtn"]
        : "black",
      color: config["colors"]["secoundaryBtn"]
        ? config["colors"]["secoundaryBtn"]
        : "black",
      opacity: 0.3,
      margin: `${marginDefault}`,
    };
  }

  return (
    <Fragment>
      <div
        id={id}
        style={
          secoundaryBtn
            ? disabled
              ? colors_disabled
              : colors
            : disabled
            ? {
                backgroundColor: config["colors"]["btn"],
                opacity: 0.3,
                margin: `${marginDefault}`,
              }
            : {
                backgroundColor: config["colors"]["btn"],
                margin: `${marginDefault}`,
              }
        }
        className={`btn ${translucent ? "btn-blue-translucent" : "btn-blue"} ${
          wide ? "btn-wide" : ""
        } ${disabled ? (secoundaryBtn ? "" : "btn-disabled") : ""}`}
        onClick={onclick}
      >
        {text}
      </div>
    </Fragment>
  );
};

Button.defaultProps = {
  id: "btn-proceed",
  text: "Proceed",
  wide: false,
  translucent: false,
  disabled: false,
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string,
  wide: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  translucent: PropTypes.bool.isRequired,
  onclick: PropTypes.func,
};

export default Button;
