import React, { useState, useContext, useEffect } from "react";
import Button from "./Button";
import Header from "./Header";
import { AppContext } from "../context";
import {
  initializeEmail,
  setLog,
  userLogs,
  patLogs,
  setResumeFlowEmail,
  translateText,
} from "../context/utils";
import Navbar from "./Navbar";

// import * as Sentry from "@sentry/react";
// import ReactGA from "react-ga4";

let logsbugs = true;

const Email = () => {
  const {
    stepCovered,
    setStepCovered,
    setScreen,
    setUserEmail,
    clientId,
    token,
    inspectionId,
    config,
    showAlert,
    setCurrentPage,
    currentPage,
    setStartPing,
  } = useContext(AppContext);

  const land = () => window.innerWidth > window.innerHeight * 1.2;

  const mailFormat =
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const [email, setEmail] = useState("");
  const [checked, setChecked] = useState(false);
  const [screenHeight, setScreenHeight] = useState(land() ? false : true);
  logsbugs = false;
  const validateEmail = (e) => {
    setEmail(e.target.value);
  };
  const isValid = () => {
    if (
      (!config["intro-page"]["show-privacy-policy"] ||
        config["intro-page"]["privacy-policy-type"] === "text") &&
      !config["intro-page"]["show-email-option"]
    ) {
      return true;
    } else if (
      (!config["intro-page"]["show-privacy-policy"] ||
        config["intro-page"]["privacy-policy-type"] === "text") &&
      config["intro-page"]["show-email-option"]
    ) {
      return mailFormat.test(email);
    } else if (
      config["intro-page"]["privacy-policy-type"] === "checkbox" &&
      config["intro-page"]["show-email-option"]
    ) {
      return mailFormat.test(email) && checked;
    } else if (
      config["intro-page"]["privacy-policy-type"] === "checkbox" &&
      !config["intro-page"]["show-email-option"]
    ) {
      return checked;
    } else return true;
  };

  window.onresize = () => {
    setScreenHeight(land() ? false : true);
  };

  const getTranslateText = (text) => {
    if (Object.keys(config["language"]).includes(text)) {
      return config["language"][text];
    } else {
      // const data = translateText(text, config["language"]["langCode"]);
      return text;
    }
  };

  const onProceed = () => {
    //patLogs("Procced button is clicked", inspectionId);
    if (isValid()) {
      if (navigator.onLine) {
        patLogs(
          config["intro-page"]["show-email-option"]
            ? `Entered email -> ${email}`
            : "Email is not mandatory",
          inspectionId
        );
        // patLogs(
        //   config["intro-page"]["show-privacy-policy"]
        //     ? "checked Privacy Policy"
        //     : "Privacy Policy is not mandatory",
        //   inspectionId
        // );
        setUserEmail(email);
        initializeEmail(email);
        if (Object.keys(config).includes("resumeFlow")) {
          setResumeFlowEmail(inspectionId, email);
        }
        setScreen("instructions");
        setEmail("");
      }
    } else {
      patLogs(`this email ${email} is not valid`, inspectionId);
    }
  };

  useEffect(() => {
    patLogs("Entered to email page", inspectionId);
    userLogs({
      position: 8,
      last_page: "email page",
      inspectionId: inspectionId,
    });
  }, []);

  return (
    <div
      className="screen_email"
      style={{
        height: screenHeight ? "100dvh" : "",
        position: screenHeight ? "relative" : "",
        padding: screenHeight ? "0%" : "",
      }}
    >
      <Navbar />
      <div
        id="screen1"
        className="email_screen"
        style={{
          height: screenHeight ? "70vh" : "",
          justifyContent: screenHeight ? "space-evenly" : "",
        }}
      >
        <Header />
        <img src={config["intro-page"]["image"]}></img>
        {config["intro-page"]["show-email-option"] ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h2 className="header2">{config["intro-page"]["email-text"]}</h2>
            <div className="form">
              <input
                id="email"
                name="email"
                type="email"
                style={{ width: "80%" }}
                onInput={validateEmail}
                value={email}
                placeholder={config["intro-page"]["placeholder"]}
                required
              />
            </div>
          </div>
        ) : null}
        {config["intro-page"]["show-privacy-policy"] ? (
          config["intro-page"]["privacy-policy-type"] === "text" ? (
            <h2
              style={{ textDecoration: "underline", color: "#006cca" }}
              onClick={() => {
                window.location.href =
                  config["intro-page"]["privacy-policy-link"];
              }}
            >
              {config["intro-page"]["privacy-policy-text"]}
            </h2>
          ) : config["intro-page"]["privacy-policy-type"] === "checkbox" ? (
            <div className="email-checkbox">
              <input
                type="checkbox"
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
              {config["intro-page"]["privacy-policy-link"] === "" ? (
                <h4 style={{ color: "#006cca" }}>
                  {config["intro-page"]["privacy-policy-text"]}
                </h4>
              ) : (
                <h4
                  style={{ textDecoration: "underline", color: "#006cca" }}
                  onClick={() => {
                    window.location.href =
                      config["intro-page"]["privacy-policy-link"];
                  }}
                >
                  {config["intro-page"]["privacy-policy-text"]}
                </h4>
              )}
            </div>
          ) : null
        ) : null}
        <div className="InternetWarning">
          <p>{showAlert && showAlert}</p>
        </div>
      </div>
      <div
        className="email_btn"
        style={{
          height: screenHeight ? "auto" : "",
          position: screenHeight ? "absolute" : "",
          bottom: screenHeight ? "10px" : "",
          padding: screenHeight ? "0px 2px" : "",
        }}
      >
        <Button
          disabled={!isValid()}
          wide={true}
          onclick={onProceed}
          text={config["intro-page"]["btn-text"]}
        />
      </div>
    </div>
  );
};

export default Email;
