import React, { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../context";
import Button from "./Button";
import { userLogs, translateText } from "../context/utils";
import copy from "clipboard-copy";

const Error = () => {
  const { errorMessage, phone, config, inspectionId, clientId } =
    useContext(AppContext);
  const [showMessage, setShowMessage] = useState(false);
  let url = `${window.location.hostname}/${window.location.hash}`;

  const onProceed = async () => {
    if (phone === "ios") {
    } else if (phone === "android") {
      copy(url);
      window.location.href = `intent://${url}#Intent;package=com.android.chrome;scheme=http;end`;
    }
    setTimeout(() => {
      setShowMessage(true);
    }, 1500);
  };

  const getTranslateText = (text) => {
    if (Object.keys(config["language"]).includes(text)) {
      return config["language"][text];
    } else {
      // const data = translateText(text, config["language"]["langCode"]);
      return text;
    }
  };

  useEffect(() => {
    if (!showMessage && phone === "android") {
      userLogs({ position: 8, last_page: "other browser", inspectionId });
    }
  }, []);

  return (
    <Fragment>
      <div id="final-screen" className="screen error_size">
        {phone === null && (
          <h3 style={{ fontWeight: "bold" }}>{errorMessage}</h3>
        )}
        {/* {phone === "ios" && (
          <h3 style={{ fontWeight: "bold" }}>
            {Object.keys(config["errors"]).includes("ios_other_browser_message")
              ? config["errors"]["ios_other_browser_message"]
              : "Application is not supported on this browser on iPhone. Please open on Safari."}
          </h3>
        )} */}
        {phone === "android" && (
          <h3 style={{ fontWeight: "bold" }}>
            {Object.keys(config["errors"]).includes(
              "android_other_browser_message"
            )
              ? config["errors"]["android_other_browser_message"]
              : getTranslateText(
                  "Application is not supported on this browser on Android. Please open on Chrome."
                )}
          </h3>
        )}
        {/* {!showMessage && phone === "ios" && (
          <Button
            disabled={false}
            wide={true}
            onclick={onProceed}
            text={
              Object.keys(config["errors"]).includes(
                "ios_other_browser_btn_txt"
              )
                ? config["errors"]["ios_other_browser_btn_txt"]
                : "Open on Safari"
            }
          />
        )} */}
        {!showMessage && phone === "android" && (
          <Button
            disabled={false}
            wide={true}
            onclick={onProceed}
            text={
              Object.keys(config["errors"]).includes(
                "android_other_browser_btn_txt"
              )
                ? config["errors"]["android_other_browser_btn_txt"]
                : getTranslateText("Open on Chrome")
            }
          />
        )}
        {/* {showMessage && phone === "ios" && (
          <p>
            {Object.keys(config["errors"]).includes("install_safari_Message")
              ? config["errors"]["install_safari_Message"]
              : "Install Safari to use the link"}
          </p>
        )} */}
        {showMessage && phone === "android" && (
          <p>
            {Object.keys(config["errors"]).includes("install_chrome_Message")
              ? config["errors"]["install_chrome_Message"]
              : getTranslateText("Install Chrome to use the link")}
          </p>
        )}

        {clientId === "client_hiib" && <p>{navigator.userAgent}</p>}
      </div>
    </Fragment>
  );
};

export default Error;
