import React, { Fragment, useContext, useEffect } from "react";
import { AppContext } from "../context";
import { userLogs, translateText } from "../context/utils";
import rotate from "../img/rotate.json";
import Button from "./Button";

const Rotate = () => {
  const { config, autoRotate, isIOS, clientId, isSwitchToLandscape } =
    useContext(AppContext);
  let isAndroid = /Android/.test(navigator.userAgent);

  // userLogs({position: 8, last_page: "rotate page"})
  const sendMessageIFrame = () => {
    // added event
    window.top.postMessage("rotation", "*");
  };

  const getTranslateText = (text) => {
    if (Object.keys(config["language"]).includes(text)) {
      return config["language"][text];
    } else {
      // const data = translateText(text, config["language"]["langCode"]);
      return text;
    }
  };

  const switchLandscape = () => {
    sendMessageIFrame();
    const element = document.documentElement;
    if (element.requestFullscreen) {
      element
        .requestFullscreen()
        .then(() => {
          if (screen.orientation && screen.orientation.lock) {
            screen.orientation
              .lock("landscape")
              .then(function success() {})
              .catch(function error(e) {
                console.log(e.message);
              });
          } else if (
            window.screen &&
            window.screen.orientation &&
            window.screen.orientation.lock
          ) {
            window.screen.orientation
              .lock("landscape")
              .then(function success() {})
              .catch(function error(e) {
                console.log(e.message);
              });
          }
        })
        .catch((error) => {
          // console.log('Failed to enter fullscreen mode:', error);
        });
    }
  };

  const switchPortrait = () => {
    const elements = document.querySelectorAll(".pingsPort");
    elements.forEach((element) => {
      element.style.top = "60px";
    });
    const element = document.documentElement;
    if (element.requestFullscreen) {
      element
        .requestFullscreen()
        .then(() => {
          if (screen.orientation && screen.orientation.lock) {
            screen.orientation
              .lock("portrait")
              .then(function success() {})
              .catch(function error(e) {
                console.log(e.message);
              });
          } else if (
            window.screen &&
            window.screen.orientation &&
            window.screen.orientation.lock
          ) {
            window.screen.orientation
              .lock("portrait")
              .then(function success() {})
              .catch(function error(e) {
                console.log(e.message);
              });
          }
        })
        .catch((error) => {
          // console.log('Failed to enter fullscreen mode:', error);
        });
    }
  };

  useEffect(() => {
    sendMessageIFrame();
  }, []);

  return (
    <Fragment className="rotate_size">
      <lottie-player
        id={
          Object.keys(config).includes("allowPortraitApp") &&
          !isSwitchToLandscape
            ? "rotate-image-2"
            : "rotate-image-1"
        }
        src={rotate}
        background="transparent"
        speed="1"
        loop
        autoplay
      ></lottie-player>
      <h2 style={{ textAlign: "center", padding: "10px" }}>
        {["client_1381", "client_1381_prod"].includes(clientId)
          ? isIOS
            ? config["rotate-page"]["text-ios"]
            : config["rotate-page"]["text"]
          : Object.keys(config).includes("allowPortraitApp")
          ? isSwitchToLandscape
            ? config["rotate-page"]["text-landscape"]
            : config["rotate-page"]["text"]
          : config["rotate-page"]["text"]}
      </h2>
      {isAndroid && autoRotate && (
        <div id="rotateBtnContainer">
          <div id="rotateBtn">
            <Button
              wide={true}
              onclick={switchLandscape}
              text={
                Object.keys(config["rotate-page"]).includes("rotateBtnTxt")
                  ? config["rotate-page"]["rotateBtnTxt"]
                  : getTranslateText("Click to Rotate")
              }
            />
          </div>
        </div>
      )}
      {isAndroid &&
        Object.keys(config).includes("allowPortraitApp") &&
        isSwitchToLandscape && (
          <div id="rotateBtnContainer">
            <div id="rotateBtn">
              <Button
                wide={true}
                onclick={switchLandscape}
                text={
                  Object.keys(config["rotate-page"]).includes("rotateBtnTxt")
                    ? config["rotate-page"]["rotateBtnTxt"]
                    : getTranslateText("Click to Rotate")
                }
              />
            </div>
          </div>
        )}
      {isAndroid &&
        Object.keys(config).includes("allowPortraitApp") &&
        !isSwitchToLandscape && (
          <Button
            wide={false}
            onclick={switchPortrait}
            text={
              Object.keys(config["rotate-page"]).includes("rotateBtnTxt")
                ? config["rotate-page"]["rotateBtnTxt"]
                : getTranslateText("Click to Rotate")
            }
          />
        )}
    </Fragment>
  );
};

export default Rotate;
