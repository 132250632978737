import React, { Fragment, useContext, useState } from "react";
import { AppContext } from "../context";
import loadingGif from "../img/loading.gif";
import {
  rejectionBatch,
  patLogs,
  userLogs,
  translateText,
} from "../context/utils";
import Button from "./Button";

export default function Rejection() {
  const { config, token, clientId, inspectionId, setScreen, caseId } =
    useContext(AppContext);
  const [loading, setLoding] = useState(false);
  const [rejected, setRejected] = useState(false);
  patLogs("Entered to rejection page", inspectionId);
  userLogs({ position: 8, last_page: "rejection page", inspectionId });

  const onReject = async () => {
    patLogs("click to rejection", inspectionId);
    setLoding(true);
    const data = await rejectionBatch({
      token,
      client_id: clientId,
      inspection_id: inspectionId,
      case_id: caseId,
      customer_declined_ai_inspection: true,
    });
    if (data) {
      patLogs("inspection is rejected", inspectionId);
      setRejected(true);
      setLoding(false);
    } else {
      patLogs("unable to reject", inspectionId);
      setLoding(false);
    }
  };

  const getTranslateText = (text) => {
    if (Object.keys(config["language"]).includes(text)) {
      return config["language"][text];
    } else {
      // const data = translateText(text, config["language"]["langCode"]);
      return text;
    }
  };

  const onProceed = () => {
    patLogs("click to continue", inspectionId);
    setLoding(true);
    config["email"] ? setScreen("email") : setScreen("instructions");
  };

  return (
    <Fragment>
      {rejected ? (
        <div id="rejection-screen" className="screen">
          <h3 style={{ fontWeight: "bold" }}>
            {Object.keys(config).includes("rejectionCompleteText")
              ? config["rejectionCompleteText"]
              : getTranslateText("Now you can close your tab")}
          </h3>
        </div>
      ) : (
        <div id="final-screen" className="screen">
          <h4>
            {Object.keys(config).includes("rejectionText")
              ? config["rejectionText"]
              : getTranslateText("Want to reject the inspection")}
          </h4>
          {!loading ? (
            <div style={{ bottom: "50px", position: "absolute", width: "80%" }}>
              <Button
                disabled={loading}
                wide={true}
                onclick={onReject}
                text={
                  Object.keys(config).includes("rejectionRejectBtnTxt")
                    ? config["rejectionRejectBtnTxt"]
                    : getTranslateText("Decline")
                }
              />
              <Button
                disabled={loading}
                wide={true}
                onclick={onProceed}
                text={
                  Object.keys(config).includes("rejectionAgreeBtnTxt")
                    ? config["rejectionAgreeBtnTxt"]
                    : getTranslateText("Continue")
                }
              />
            </div>
          ) : (
            <div>
              <img
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "50px",
                  marginTop: "5px",
                }}
                src={loadingGif}
              ></img>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
}
