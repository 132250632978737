import * as constants from "../utils_new/Constants";

export class PartCoverageEstimator {
  constructor() {
    this.boxBorderThresholdForEachPart = {};
    this.partsCoveredMap = {};
    this.partsCoveredFramesCountMap = {};
    this.defaultBorderThreshold = {
      top: 0.01,
      bottom: 0.99,
      left: 0.01,
      right: 0.99,
    };
    for (let id in constants.CLASS_LABELS) {
      let label = constants.CLASS_LABELS[id];
      this.boxBorderThresholdForEachPart[label] = this.defaultBorderThreshold;
    }
  }
  getPartsCovered(boundingBoxDetections) {
    let partsCoveredInAFrame = [];

    // Iterate through each Detection
    boundingBoxDetections.forEach((detection) => {
      let label = detection["label"];
      let x = detection["bbox"][0];
      let y = detection["bbox"][1];
      let width = detection["bbox"][2];
      let height = detection["bbox"][3];

      // Check if the bboxes are not too close to the borders of the image, based on thresholds.
      let boxIsHorizontallyCovered =
        x >= this.boxBorderThresholdForEachPart[label]["left"] &&
        x + width <= this.boxBorderThresholdForEachPart[label]["right"];
      let boxIsVerticallyCovered =
        y >= this.boxBorderThresholdForEachPart[label]["top"] &&
        y + height <= this.boxBorderThresholdForEachPart[label]["bottom"];

      // Add the parts (and the orientation if applicable) to the list.
      if (boxIsHorizontallyCovered && boxIsVerticallyCovered) {
        partsCoveredInAFrame.push(label);
      }
    });
    return partsCoveredInAFrame;
  }

  // getPartsCovered(boundingBoxDetections) {
  //   return boundingBoxDetections
  //     .filter(({ label, bbox: [x, y, width, height] }) => {
  //       const threshold = this.boxBorderThresholdForEachPart[label];
  //       if (!threshold) return false;
  //       return (
  //         x >= threshold.left &&
  //         x + width <= threshold.right &&
  //         y >= threshold.top &&
  //         y + height <= threshold.bottom
  //       );
  //     })
  //     .map((detection) => detection.label);
  // }

  getPartsCoveredWithConfidence(boundingBoxDetections) {
    const partsCovered = this.getPartsCovered(boundingBoxDetections);
    return partsCovered;
  }

  setActualPartsCoveredMap(boundingBoxDetections) {
    const allParts = [...new Set(boundingBoxDetections.map((d) => d.label))];
    this.actualFrameCall = (this.actualFrameCall || 0) + 1;

    const partsCoveredWithConfidence = this.getPartsCoveredWithConfidence(
      boundingBoxDetections
    );

    const orientedPartsFullyCovered = new Set([
      "fender",
      "front_bumper",
      "window_glass",
      "hood",
      "left_front_bumper_isometric",
      "right_front_bumper_isometric",
      "front_glass",
      "qtr_panel",
      "qtr_panel",
      "back_bumper",
      "dicky",
      "left_back_bumper_isometric",
      "right_back_bumper_isometric",
      "back_glass",
      "front_door",
      "back_door",
      "running_board",
      "running_board",
    ]);

    const majorPartsSet = new Set(constants.ALL_MAJOR_PARTS);
    const fullyCoveredCount = partsCoveredWithConfidence.filter((part) =>
      orientedPartsFullyCovered.has(part)
    ).length;
    const majorPartsCount = allParts.filter((part) =>
      majorPartsSet.has(part)
    ).length;

    return majorPartsCount > 2 && fullyCoveredCount > 1;
  }
}
