import React, { useState, useContext, useEffect } from "react";
import NavbarP from "./NavBarP";
import { AppContext } from "../../context";
import Rotate from "../Rotate";
import {
  setLog,
  getCountData,
  formUpload,
  userLogs,
  patLogs,
  translateText,
} from "../../context/utils";
import leftArrow from "../../img/left.png";
import rightArrow from "../../img/right.png";

let upload;
let uploaded = true;
let runsOnce = true;
export default function FormModuleWondle() {
  const {
    stepCovered,
    setScreen,
    setLandscape,
    config,
    setCurrentPage,
    inspectionId,
    mandatory,
    setMandatory,
    isLandscape,
    setCondition,
    currentBlock,
    setCurrentBlock,
    setSubMandatory,
    subMandatory,
    subCondition,
    setSubCondition,
    condition,
    clientId,
    setStepCovered,
  } = useContext(AppContext);
  const [inputValidationError, setInputValidationError] = useState([]);
  const [formdata, setFormData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [wrongFile, setWrongFile] = useState(null);
  const [corrupted, setCorrupted] = useState(false);
  const [formDocuments, setFormDocuments] = useState({});
  const [refreshed, setRefreshed] = useState(true);
  const [totalQuestions, setTotalQuestions] = useState(null);
  const [currentQuestions, setCurrentQuestions] = useState(1);
  const [textData, setTextData] = useState({});

  const land = () => window.innerWidth > window.innerHeight * 1.2;
  window.onresize = () => {
    setLandscape(land());
  };

  const getTranslateText = (text) => {
    if (Object.keys(config["language"]).includes(text)) {
      return config["language"][text];
    } else {
      // const data = translateText(text, config["language"]["langCode"]);
      return text;
    }
  };

  const handleFileSelect = (e, f) => {
    const file = e.target.files[0];
    if (
      file &&
      (file.type === "image/jpeg" || file.type === "application/pdf")
    ) {
      setDisabled(false);
      setWrongFile("");
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setFormDocuments({ ...formDocuments, [f]: reader.result });
      };
      reader.onerror = () => {
        setDisabled(true);
        setCorrupted(true);
        setWrongFile(f);
      };
    } else {
      setDisabled(true);
      setWrongFile(f);
    }
  };

  const uploadForm = () => {
    let jsonFileWithDocuments = {};
    let jsonFile = "";
    let impData = [];
    jsonFileWithDocuments = {
      ...formdata,
      documents: formDocuments,
      ...textData,
    };
    jsonFile = formdata;
    if (config["form-page"]["impData"]) {
      impData = [
        config["form-page"]["impData"],
        formdata[config["form-page"]["impData"]]
          ? formdata[config["form-page"]["impData"]]
          : "",
      ];
    } else {
      impData = ["", ""];
    }
    // upload = setInterval(() => {
    // let data = getCountData();
    // if (data && uploaded) {
    // uploaded = false;
    formUpload(
      JSON.stringify(jsonFileWithDocuments),
      JSON.stringify(jsonFile),
      impData
    );

    let temp = subCondition;
    if (currentBlock.mandatory) {
      let temp1 = subMandatory;
      temp1["360 Image"][currentBlock.name] = "completed";
      setSubMandatory(temp1);
    }
    temp["360 Image"][currentBlock.name] = "completed";
    setSubCondition(temp);
    patLogs(`${currentBlock.name} is completed`, inspectionId);
    const elements = document.querySelectorAll(".pingsPort");
    elements.forEach((element) => {
      element.style.display = "flex";
    });
    setCurrentBlock(config["blocks"][0]);
  };

  const radioFunc = (a, e) => {
    setRefreshed(refreshed ? false : true);
    formdata[a]
      ? formdata[a] === e
        ? delete formdata[a]
        : setFormData({ ...formdata, [a]: e })
      : setFormData({ ...formdata, [a]: e });
  };

  const checkBoxFunc = (a, e) => {
    setRefreshed(refreshed ? false : true);
    formdata[a]
      ? formdata[a].includes(e)
        ? formdata[a].length === 1
          ? delete formdata[a]
          : setFormData({
              ...formdata,
              [a]: formdata[a].filter((q) => q !== e),
            })
        : setFormData({
            ...formdata,
            [a]: formdata[a] ? [...formdata[a], e] : [e],
          })
      : setFormData({
          ...formdata,
          [a]: formdata[a] ? [...formdata[a], e] : [e],
        });
  };

  const inputCheck = (f, e, validation) => {
    if (validation[0] === "") {
      setFormData({ ...formdata, [f]: e.target.value });
    } else {
      let regex = new RegExp(validation[0]);
      if (regex.test(e.target.value)) {
        setFormData({ ...formdata, [f]: e.target.value });
        setInputValidationError(inputValidationError.filter((e) => e !== f));
      } else {
        formdata[f] && setFormData({ ...delete formdata[f] });
        setInputValidationError([...inputValidationError, f]);
      }
    }
  };

  const setTextArea = (f, e) => {
    setTextData({ ...textData, [f]: e.target.value });
  };

  const deleteSelectedFile = (f) => {
    setRefreshed(refreshed ? false : true);
    document.getElementById(f).value = "";
    formDocuments[f] && delete formDocuments[f];
    setCorrupted(false);
    setWrongFile(null);
  };

  useEffect(() => {
    setLandscape(land());
    config["form-page"].tag_mandatory.filter(
      (e) => !formdata[e] && !formDocuments[e]
    ).length
      ? setDisabled(true)
      : setDisabled(false);
  }, [formdata, formDocuments, refreshed]);

  useEffect(() => {
    setTotalQuestions(
      Object.keys(config["form-page"].inputTag).length +
        Object.keys(config["form-page"].radio).length +
        Object.keys(config["form-page"].checkbox).length +
        Object.keys(config["form-page"].select).length +
        config["form-page"].documents.length +
        Object.keys(config["form-page"].textAreaTag).length
    );
  }, [currentQuestions, setCurrentQuestions, window.onresize]);

  const goBack = () => {
    setCurrentBlock(config["blocks"][0]);
  };

  if (runsOnce) {
    let radioData = {};
    let checkboxData = {};
    Object.keys(config["form-page"].radio).map((e) => {
      radioData[e] = config["form-page"].radio[e][0];
    });

    Object.keys(config["form-page"].checkbox).map((e) => {
      checkboxData[e] = config["form-page"].checkbox[e];
    });
    setFormData({
      ...formdata,
      ...radioData,
      ...checkboxData,
    });
    runsOnce = false;
  }

  useEffect(() => {
    const elements = document.querySelectorAll(".pingsPort");
    elements.forEach((element) => {
      element.style.display = "none";
    });
  }, []);

  return (
    <>
      {isLandscape ? (
        <Rotate />
      ) : (
        <div
          className="process"
          style={{ position: "relative", justifyContent: "space-between" }}
        >
          <NavbarP />
          <div
            className="form_size"
            id="container"
            style={{
              height: "100dvh",
              display: "flex",
              overflowY: "scroll",
              margin: "50px 0px 50px 0px",
            }}
          >
            <div id="form-module-inner-div">
              {Object.keys(config["form-page"]["inputTag"]).length ? (
                <h2 style={{ margin: "0px" }}>
                  {config["form-page"]["inputTagText"]}
                </h2>
              ) : (
                ""
              )}
              {Object.keys(config["form-page"].inputTag).map((f, i) => (
                <div key={f} id={`${i + 1}`} className="div-input">
                  <label className="label">
                    {f}{" "}
                    {config["form-page"].tag_mandatory?.includes(f) && (
                      <span>*</span>
                    )}
                    {inputValidationError.includes(f) && (
                      <span>
                        {"("} Enter Valid {f} {")"}
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    placeholder={config["form-page"].inputTag[f][1]}
                    value={formdata.f}
                    onChange={(e) =>
                      inputCheck(f, e, config["form-page"].inputTag[f])
                    }
                  />
                </div>
              ))}
              {Object.keys(config["form-page"].radio) ? (
                <h2 style={{ margin: "20px 0px 0px 0px" }}>
                  {config["form-page"]["radioTagText"]}
                </h2>
              ) : (
                ""
              )}
              {Object.keys(config["form-page"].radio).map((a, i) => (
                <div
                  id={`${
                    Object.keys(config["form-page"].inputTag).length + i + 1
                  }`}
                >
                  <label className="label">
                    {a}{" "}
                    {config["form-page"].tag_mandatory?.includes(a) && (
                      <span>*</span>
                    )}
                  </label>
                  <div className="div-radio">
                    {Object.values(config["form-page"].radio[a]).map((c) => (
                      <div className="label-div">
                        <input
                          type="radio"
                          value={c}
                          checked={formdata[a] === c}
                          onClick={() => radioFunc(a, c)}
                        />
                        <p>{c}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {Object.keys(config["form-page"].checkbox) ? (
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    width: "100%",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <h2 style={{ margin: "0px" }}>
                    {config["form-page"]["checkboxTagText"]}
                  </h2>
                  <div className="sampleCheckbox">
                    {"( "}
                    <div className="label-div">
                      <input
                        // disabled={true}
                        type="checkbox"
                        checked={true}
                      />
                      <p style={{ fontSize: "15px", margin: "0px -6px" }}>
                        Okay
                      </p>
                    </div>
                    <div className="label-div" style={{ margin: "0px 10px" }}>
                      <input
                        type="checkbox"
                        // disabled={true}
                        checked={false}
                      />
                      <p style={{ fontSize: "15px", margin: "5px -6px" }}>
                        Not Okay{" "}
                      </p>
                    </div>
                    {" )"}
                  </div>
                </div>
              ) : (
                ""
              )}
              {Object.keys(config["form-page"].checkbox).map((a, i) => (
                <div>
                  <div
                    className="checkboxLevel"
                    style={{
                      margin:
                        i == 0 ? "0px 10px 0px 10px" : "10px 10px 0px 10px",
                    }}
                  >
                    <label
                      className="label"
                      style={{ fontSize: "20px", margin: "10px 14px" }}
                    >
                      {a}{" "}
                      {config["form-page"].tag_mandatory?.includes(a) && (
                        <span>*</span>
                      )}
                    </label>
                  </div>
                  <div className="div-radio">
                    {Object.values(config["form-page"].checkbox[a]).map((c) => (
                      <div className="label-div">
                        <input
                          type="checkbox"
                          value={c}
                          checked={formdata[a]?.includes(c)}
                          onClick={() => checkBoxFunc(a, c)}
                        />
                        <p style={{ fontSize: "15px", margin: "5px 0px" }}>
                          {c}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {Object.keys(config["form-page"]["select"]).length ? (
                <h2 style={{ margin: "20px 0px 0px 0px" }}>
                  {config["form-page"]["selectTagText"]}
                </h2>
              ) : (
                ""
              )}
              {Object.keys(config["form-page"].select).map((a, i) => (
                <div
                  id={`${
                    Object.keys(config["form-page"].inputTag).length +
                    Object.keys(config["form-page"].radio).length +
                    Object.keys(config["form-page"].checkbox).length +
                    i +
                    1
                  }`}
                  className="div-input"
                >
                  <label className="label">
                    {a}{" "}
                    {config["form-page"].tag_mandatory?.includes(a) && (
                      <span>*</span>
                    )}
                  </label>
                  <select
                    className="form-select"
                    value={formdata.cartype}
                    onChange={(e) =>
                      setFormData({ ...formdata, [a]: e.target.value })
                    }
                  >
                    <option className="form-select-option" value=""></option>
                    {Object.values(config["form-page"].select[a]).map((g) => (
                      <option className="form-select-option" value={g}>
                        {g}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
              {config["form-page"]["documents"].length > 0 ? (
                <h2 style={{ margin: "20px 0px 0px 0px" }}>
                  {config["form-page"]["documentTagText"]}
                </h2>
              ) : (
                ""
              )}
              {config["form-page"].documents.map((f, i) => (
                <div
                  id={`${
                    Object.keys(config["form-page"].inputTag).length +
                    Object.keys(config["form-page"].radio).length +
                    Object.keys(config["form-page"].checkbox).length +
                    Object.keys(config["form-page"].select).length +
                    i +
                    1
                  }`}
                  className="documents"
                >
                  <label className="label">
                    {f}{" "}
                    {config["form-page"].tag_mandatory?.includes(f) && (
                      <span>*</span>
                    )}
                  </label>
                  <div className="inputFile">
                    <div className="radiodiv">
                      <input
                        type="file"
                        id={f}
                        accept="image/pdf, image/jpg"
                        onChange={(e) => handleFileSelect(e, f)}
                      />
                      {wrongFile === f && !corrupted && (
                        <p>{config["form-page"]["alertImageType"]}</p>
                      )}
                      {wrongFile === f && corrupted && (
                        <p>{config["form-page"]["alertImageCorrupted"]}</p>
                      )}
                    </div>
                    <div
                      className="imageRemovebutton"
                      onClick={() => deleteSelectedFile(f)}
                    >
                      X
                    </div>
                    <div></div>
                  </div>
                </div>
              ))}
              {Object.keys(config["form-page"]["textAreaTag"]).length ? (
                <h2 style={{ margin: "20px 0px 0px 0px" }}>
                  {config["form-page"]["textAreaTagText"]}
                </h2>
              ) : (
                ""
              )}
              {Object.keys(config["form-page"]).includes("textAreaTag") &&
                Object.keys(config["form-page"].textAreaTag).map((f, i) => (
                  <div
                    key={f}
                    className="div-input"
                    style={{
                      marginTop: "20px",
                      margin: "0px",
                      padding: "0px",
                      width: "79vw",
                    }}
                    id={`${
                      Object.keys(config["form-page"].inputTag).length +
                      Object.keys(config["form-page"].radio).length +
                      Object.keys(config["form-page"].checkbox).length +
                      Object.keys(config["form-page"].select).length +
                      Object.keys(config["form-page"].documents).length +
                      i +
                      1
                    }`}
                  >
                    <div className="checkboxLevel">
                      <label
                        className="label"
                        style={{ fontSize: "20px", margin: "10px 14px" }}
                      >
                        {f}{" "}
                        {config["form-page"].tag_mandatory?.includes(f) && (
                          <span>*</span>
                        )}
                      </label>
                    </div>
                    <textarea
                      type="text"
                      value={formdata.f}
                      onChange={(e) => setTextArea(f, e)}
                      rows="8"
                      style={{ width: "99%" }}
                    />
                  </div>
                ))}
              <div
                className="form-btn"
                style={{
                  width: "100%",
                }}
              >
                {/* <button
                  style={{
                    backgroundColor: "white",
                    borderColor: config["colors"]["secoundaryBtn"]? config["colors"]["secoundaryBtn"]: 'black',
                    color: config["colors"]["secoundaryBtn"]? config["colors"]["secoundaryBtn"] : 'black',
                    width: "60%",
                    height: "50px",
                    margin: "30px 0px 30px 0px",
                  }}
                  onClick={goBack}
                >
                  {config["multiple-image-page"]["back-btn-text"]}
                </button> */}
                <button
                  disabled={disabled}
                  style={{
                    backgroundColor: disabled
                      ? "#bcbcbc"
                      : config["form-page"]["btn"],
                    width: "60%",
                    height: "50px",
                    margin: "30px 0px 30px 0px",
                  }}
                  onClick={uploadForm}
                >
                  {config["form-page"]["btnText"]}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
