import React, { useEffect, useRef } from "react";
import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.entry";

const PdfThumbnail = ({ base64Pdf, scale, width, height }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const renderPdfThumbnail = async () => {
      try {
        // Load the PDF from the Base64 URL
        const pdf = await pdfjsLib.getDocument(base64Pdf).promise;

        // Get the first page of the PDF
        const page = await pdf.getPage(1);

        // Get the canvas element and its context
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");

        // Get the viewport with the specified scale
        const viewport = page.getViewport({ scale });

        // Set canvas dimensions based on viewport
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        // Render the page onto the canvas
        await page.render({
          canvasContext: context,
          viewport: viewport,
        });
      } catch (error) {
        console.log("Error rendering PDF thumbnail:", error);
      }
    };
    if (base64Pdf && scale && width && height) {
      renderPdfThumbnail();
    }
  }, [base64Pdf, scale]);

  return (
    <div
      style={{
        width: width,
        height: height,
        overflow: "hidden",
        border: "1px solid #ccc",
        borderRadius: "5px",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
      }}
    >
      <canvas ref={canvasRef} style={{ width: width, height: height }}></canvas>
    </div>
  );
};

export default PdfThumbnail;
