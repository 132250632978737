import React, { useEffect, useContext, useRef, useState, use } from "react";
import { AppContext } from "../context";
import * as tf from "@tensorflow/tfjs";
import { PartDetector } from "../partdetection_new/PartDetector";
import { Guidance } from "../guidance_new/GuidanceMain";
import getStartedGif from "../img/akgetStarted.gif";
import leftArrow from "../img/left.png";
import rightArrow from "../img/right.png";
import loading from "../img/loading.gif";
import white_tick from "../img/white_tick.png";
import redRecord from "../img/redRecord.gif";
import greenRecord from "../img/greenRecord.gif";
import ZizoClose from "./zizoSVG/ZizoClose";
import ZizoFar from "./zizoSVG/ZizoFar";
import Rotate from "./Rotate";
import plusSvg from "../img/plus.svg";
import CameraGreenBox from "./zizoSVG/CameraGreenBox";
import CameraRedBox from "./zizoSVG/CameraRedBox";
import { PartCoverageEstimator } from "../guidance_new/partCoverageZIZO";

import {
  addImageToList,
  userLogs,
  patLogs,
  getModel,
  resumeFlowFinalSubmitHandler,
  makeRequest,
} from "../context/utils";

let logbugs = true;
let alignment = false;
let takeMoreList = [false];
let recordGifFlag = false;
let interval;
let apiInterval;
let apiCallTag = "Zoom_Out";
let apiTag = "CAR_ZO";
let lambdaSendingFlag = true;
let captured = 0;
let detectionFlag = false;
let allowStopBtn = false;
let track = null;
let flag = true;
let landscapeFlag = false;
let cameraStarted = false;
let arrowInterval;
let modalStart = true;
let tempStream = null;
let cameraInterval;
let ZoomOutFlag = false;
let log = {
  ZoomOutFlag: false,
  CurrentAngle: 0,
  PartDectect: [],
};

const NewZIZO = () => {
  const {
    setScreen,
    condition,
    setCondition,
    config,
    isLandscape,
    clientId,
    currentBlock,
    showAlert,
    setLandscape,
    page,
    setPage,
    scrollX,
    setscrollX,
    setscrolEnd,
    scrolEnd,
    mandatory,
    setMandatory,
    inspectionId,
  } = useContext(AppContext);
  let partCoverageEstimator = new PartCoverageEstimator();

  const [apiFlag, setApiFlag] = useState(false);
  const [currentGif, setCurrentGif] = useState(0);
  const [alignmentFlag, setAlignmentFlag] = useState(true);
  const [selectGradText, setSelectGradText] = useState(0);
  const [count, setCount] = useState(1);
  const listCount = currentBlock["count"];
  const [niranjanLambda, setNiranjanLambda] = useState("");
  const gradiantText = [
    "Is the Damage visible & fitting within the box",
    "Video captured successfully, Please stop the recording",
    "Please fit the damage inside the green box and confirm us once done",
  ];

  let webCamPromise = null;

  const videoRef = useRef();
  const canvasRef = useRef();
  const cardRef = useRef();
  const lambdaGuidRef = useRef();
  const zizoBtns = useRef();
  const modalGuidanceText = useRef();
  let scrl = useRef();
  const recordingRef = useRef();
  const niranjanRef = useRef();
  const recordGifRef = useRef();
  const lambdaFeedbackRef = useRef();
  const pathGuidRefLeft1 = useRef();
  const pathGuidRefRight1 = useRef();
  const pathGuidRefLeft2 = useRef();
  const pathGuidRefRight2 = useRef();
  const recordGuidTxtRef = useRef();
  const redSVGRef = useRef();
  const greenSVGRef = useRef();
  const ptag = useRef();

  if (logbugs) {
    patLogs("New ZIZO Module -> At instruction page", inspectionId);
    userLogs({ position: 8, last_page: "zizo module", inspectionId });
    logbugs = false;
  }

  const partDetector = new PartDetector();
  const guidance = new Guidance(
    config?.["video-page"]?.guidanceMessages,
    clientId
  );

  const constraints = {
    video: {
      width: config?.resolution?.width ?? 1920,
      height: config?.resolution?.height ?? 1080,
      facingMode: "environment",
      aspectRatio: config?.aspectRatio ? 4 / 3 : undefined,
      zoom: { ideal: config?.zoom ? 0.5 : 1 },
    },
    audio: false,
  };

  const land = () => window.innerWidth > window.innerHeight * 1.2;

  window.onresize = () => {
    if (!land() && cameraStarted) {
      detectionFlag = false;
      modalStart = false;
      landscapeFlag = true;
      setApiFlag(false);
      clearInterval(arrowInterval);
    }
    else if (land() && cameraStarted && landscapeFlag) {
      landscapeFlag = false;
      startCamera();
    }
    setLandscape(land());
  };

  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const takeMore = async () => {
    ZoomOutFlag = false;
    setPage("camera");
    detectionFlag = false;
    lambdaSendingFlag = true;
    recordGifFlag = false;
    allowStopBtn = false;
    modalStart = true;
    startCamera();
    setCount((prevCount) => ++prevCount);
  };

  const detectFrame = async (video, model) => {
    let detections = [];
    let newImgURL = "";
    tf.engine().startScope();

    const processedFrame = partDetector.processInput(video);
    model.executeAsync(processedFrame).then((predictions) => {
      detections = partDetector.getDetectionObjects(predictions);
      ZoomOutFlag = partCoverageEstimator.setActualPartsCoveredMap(detections);
      //console.log("ZoomOutFlag", ZoomOutFlag);
      if (detectionFlag && ZoomOutFlag) {
        detectionFlag = false;
        clearInterval(arrowInterval);
        lambdaSendingFlag = false;
        modalStart = false;
        redSVGRef.current.style.display = "none";
        greenSVGRef.current.style.display = "block";
        recordGifFlag = true;
        setSelectGradText(1);
        allowStopBtn = true;
        if (recordGuidTxtRef.current.style)
          recordGuidTxtRef.current.style.display = "none";
        recordGifRef.current.style.paddingTop = "5%";
        recordGifRef.current.style.width = "15%";
        // setTimeout(() => { setApiFlag(false) }, 1000);
      }
      if (modalStart) {
        requestAnimationFrame(() => detectFrame(video, model));
      }
      tf.engine().endScope();
    });
    //}
  };

  const startRecording = async () => {
    modalGuidanceText.current.innerHTML = config["video-page"]["loadingText"];
    cardRef.current.style.display = "flex";
    const ua = navigator.userAgent.toLowerCase();
    const isIos = !!/iPhone|iPad|iPod/i.test(ua);
    const isSafari = /^((?!chrome|android).)*safari/i.test(ua);
    if (isIos || isSafari) DeviceMotionEvent.requestPermission();
    try {
      const [model] = await Promise.all([getModel(), webCamPromise]);
      detectFrame(videoRef.current, model);
      cardRef.current.style.display = "none";
      setTimeout(() => {
        lambdaGuidRef.current.style.display = "none";
        redSVGRef.current.style.display = "none";
        greenSVGRef.current.style.display = "block";
        zizoBtns.current.style.display = "flex";
      }, 500);
      setApiFlag(true);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const startCamera = async () => {
    try {
      if (!cameraStarted && !track) {
        let stream = await navigator.mediaDevices.getUserMedia(constraints);
        //console.log(stream);
        window.stream = stream;
        track = stream.getTracks()[0];
        videoRef.current.srcObject = stream;
        tempStream = stream;
      }
      else {
       // console.log(tempStream)
        cameraInterval = setInterval(async () => {
          if (videoRef && videoRef.current) {
            videoRef.current.srcObject = tempStream;
            clearInterval(cameraInterval);
            await new Promise((resolve) => (videoRef.current.onloadedmetadata = resolve));
            setTimeout(() => { startRecording() }, 50);
          }
        }, 5);
      }
      modalStart = true;
      if (!cameraStarted) await new Promise((resolve) => (videoRef.current.onloadedmetadata = resolve));
      if (!cameraStarted) setTimeout(() => { startRecording() }, 100);
      cameraStarted = true;
    } catch (err) {
      console.error("Camera access error:", err);
    }
  };

  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
      img.src = src;
    });
  };

  const reSizeImage = async (img) => {
    const img1 = await loadImage(img);
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.width = 320;
    canvas.height = 320;
    context.drawImage(img1, 0, 0, 320, 320);
    let base64Data = canvas.toDataURL("image/jpeg");
    return base64Data;
  };

  const captureImg = (imgURL) => {
    let region = Object.keys(config["video-page"]).includes("clientRegion")
      ? config["video-page"]["clientRegion"]
      : "eu";
    addImageToList({
      name: `${captured}.jpg`,
      imgData: imgURL,
      tag: `zizo_${count}`,
      region: region,
    });
    captured++;
  };

  const sendImageToLamda = async (image, tag) => {
    try {
      let result = await makeRequest(
        "https://72mxy4glexfyf56zyu6mocbnne0xwmco.lambda-url.eu-central-1.on.aws/",
        {
          inspection_id: inspectionId,
          client_id: clientId,
          region: "eu-central-1",
          env: 0,
          image: image,
          tag: apiTag,
        }
      );
      if (result.statusText === "OK") {
        let lambdaObj = await result.json();
        console.log("lambda feedback ::  ", lambdaObj);
        if (lambdaObj.feedback.length === 0) setNiranjanLambda("");
        else setNiranjanLambda(lambdaObj.feedback[0]);
      }
    } catch (err) {
      console.log("Zizo lambda calling error  ", err);
    }
  };

  const apiImageCalculation = async (tag) => {
    const cnv = canvasRef.current;
    const vw = videoRef.current;
    cnv.width = vw.videoWidth;
    cnv.height = vw.videoHeight;
    cnv.getContext("2d").drawImage(vw, 0, 0);
    //let imageBase64 = await reSizeImage(cnv.toDataURL("image/jpeg"));
    if (lambdaSendingFlag) {
      //sendImageToLamda(imageBase64.replace(/^data:image\/jpeg;base64,/, ""), tag);
    }
    if (tag === "Zoom_Out") {
      captureImg(cnv.toDataURL("image/jpeg"));
    }
  };

  const yesBtnTimerFn = async () => {
    arrowInterval = setInterval(() => {
      if (flag) {
        pathGuidRefLeft1.current.style.display = "block";
        pathGuidRefRight1.current.style.display = "block";
        pathGuidRefLeft2.current.style.display = "none";
        pathGuidRefRight2.current.style.display = "none";
        flag = false;
      } else {
        pathGuidRefLeft1.current.style.display = "none";
        pathGuidRefRight1.current.style.display = "none";
        pathGuidRefLeft2.current.style.display = "block";
        pathGuidRefRight2.current.style.display = "block";
        flag = true;
      }
    }, 1000);
    detectionFlag = true;
    setTimeout(() => {
      if (recordGuidTxtRef.current)
        recordGuidTxtRef.current.style.display = "none";
      clearInterval(arrowInterval);
      pathGuidRefLeft1.current.style.display = "none";
      pathGuidRefRight1.current.style.display = "none";
      pathGuidRefLeft2.current.style.display = "none";
      pathGuidRefRight2.current.style.display = "none";
    }, 10000);
  };

  const ZizoYesBtnFn = () => {
    zizoBtns.current.style.display = "none";
    redSVGRef.current.style.display = "block";
    greenSVGRef.current.style.display = "none";
    recordGifRef.current.style.display = "block";
    recordGuidTxtRef.current.style.display = "block";
    recordingRef.current.style.display = "flex";
    yesBtnTimerFn();
  };

  const ZizoNoBtnFn = () => {
    zizoBtns.current.style.display = "none";
    lambdaGuidRef.current.style.display = "flex";
    redSVGRef.current.style.display = "block";
    greenSVGRef.current.style.display = "none";
    setTimeout(() => {
      lambdaGuidRef.current.style.display = "none";
      zizoBtns.current.style.display = "flex";
      redSVGRef.current.style.display = "none";
      greenSVGRef.current.style.display = "block";
    }, 1000);
  };

  const End = () => {
    if (currentBlock["mandatory"]) {
      let temp1 = mandatory;

      temp1[currentBlock["id"]] = "completed";
      setMandatory(temp1);
    }
    let temp = condition;

    temp[currentBlock["id"]] = "completed";
    setCondition(temp);
    patLogs("ZIZO Module Submited (user clicked to end button)", inspectionId);
    //setDamageModuleCompleted(true);
    if (track)track.stop();
    cameraStarted = false;
    resumeFlowFinalSubmitHandler(currentBlock["id"]);
    setScreen("menu");
    setPage("inst");
  };

  useEffect(() => {
    setLandscape(land());
    interval = setInterval(() => {
      setCurrentGif((prevImage) => (prevImage === 0 ? 1 : 0));
    }, 1200);
    return () => {
      clearInterval(interval);
      clearInterval(arrowInterval);
    };
  }, []);

  useEffect(() => {
    if (apiFlag) {
      apiInterval = setInterval(() => {
        apiImageCalculation(apiCallTag);
      }, 2000);
    } else {
      clearInterval(apiInterval);
    }
  }, [apiFlag]);

  useEffect(() => {
    if (takeMoreList.length <= 3) alignment = true;
    else alignment = false;
  }, [alignmentFlag]);

  return (
    <div className="zizo-root" style={{ overflow: "hidden" }}>
      {!isLandscape ? (
        <Rotate />
      ) : page === "inst" ? (
        <div className="zizo-inst">
          <div className="current-gif" style={{ height: "70%" }}>
            {currentGif === 0 ? (
              <ZizoClose className="svgFar" />
            ) : (
              <ZizoFar className="svgFar" />
            )}
          </div>
          <div id="zizo-btn">
            <img
              id="zizo-Btn-Gif"
              src={getStartedGif}
              alt="123"
              onClick={() => {
                setPage("camera");
                clearInterval(interval);
                startCamera();
              }}
            />
          </div>
        </div>
      ) : page === "zizo" ? (
        <div className="screen18 damage_size">
          <h1 style={{ paddingTop: "3%" }}>
            {config["damage-page"]["select-screen-title"]}
          </h1>
          <div className="post-sub">
            <div className="process-main" style={{ paddingBottom: "3%" }}>
              <div
                className="left-btn"
                disabled={scrollX === 0}
                onClick={() => slide(-200)}
                style={alignment ? { display: "none" } : { display: "flex" }}
              >
                <img
                  src={leftArrow}
                  style={scrollX === 0 ? { opacity: "0.3" } : {}}
                />
              </div>
              <div
                className="process-steps"
                ref={scrl}
                onScroll={scrollCheck}
                style={
                  alignment
                    ? {
                      display: "flex",
                      justifyContent: "center",
                      width: "100vw",
                    }
                    : {
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "80vw",
                      overflowX: "scroll",
                      scrollBehavior: "smooth",
                    }
                }
              >
                {takeMoreList.map((item, index) => {
                  if (item) {
                    return (
                      <div key={index}>
                        <div className="menu-options">
                          <div
                            className="process-vin"
                            style={{
                              border: `2px solid ${config["colors"]["complete-block"]}`,
                            }}
                          >
                            <div className="greenCircleTick">
                              <img src={white_tick} alt="1232"></img>
                            </div>
                            <img src={currentBlock["link"]} alt="123"></img>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return (
                    <div key={index}>
                      <div className="menu-options">
                        <div
                          className="process-vin"
                          style={{
                            border: `2px solid gray`,
                          }}
                        >
                          <img
                            src={item ? currentBlock["link"] : plusSvg}
                            alt="123"
                            style={{
                              width: "40%",
                              height: "50%",
                              opacity: 0.5,
                            }}
                            onClick={!item && takeMore}
                          ></img>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                className="right-btn"
                disabled={scrolEnd}
                onClick={() => slide(+200)}
                style={alignment ? { display: "none" } : { display: "flex" }}
              >
                <img
                  src={rightArrow}
                  style={scrolEnd ? { opacity: "0.3" } : {}}
                />
              </div>
            </div>
            <div className="InternetWarning">
              <p>{showAlert && showAlert}</p>
            </div>
            <div className="damage-btn">
              <div className="damage-btn-div">
                <div
                  className="btn process-btn"
                  style={{
                    backgroundColor: config["colors"]["btn"],
                    color: "white",
                  }}
                  onClick={() => End()}
                >
                  {config["damage-page"]["end-btn"]}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : page === "camera" ? (
        <div id="vin-screen-zizo" style={{ position: "relative" }}>
          <canvas
            ref={canvasRef}
            id="ios-canvas-zizo"
            style={
              Object.keys(config).includes("aspectRatio")
                ? {
                  width: "100vw",
                  height: "100vh",
                  objectFit: "contain",
                  backgroundColor: "black",
                }
                : {}
            }
          ></canvas>
          <video
            ref={videoRef}
            id="videoWindow-zizo"
            style={
              Object.keys(config).includes("aspectRatio")
                ? {
                  width: "100vw",
                  height: "100vh",
                  objectFit: "contain",
                  backgroundColor: "black",
                }
                : {}
            }
            autoPlay
            playsInline
          ></video>
          {/* <p ref={ptag} style={{ zIndex: 7, backgroundColor: "white", padding: "2%", color: "black", position: "fixed", top: "30%", left: "10%" }}></p> */}
          <div ref={redSVGRef} id="redCameraBox">
            <CameraRedBox style={{ width: "101dvw", height: "101dvh", overflow: "hidden", objectFit: "cover" }} arrowLeft1={pathGuidRefLeft1} arrowLeft2={pathGuidRefLeft2} arrowRight1={pathGuidRefRight1} arrowRight2={pathGuidRefRight2} />
          </div>
          <div ref={greenSVGRef} id="greenCameraBox">
            <CameraGreenBox style={{ width: "101dvw", height: "101dvh", overflow: "hidden", objectFit: "cover" }} gradiantText={gradiantText[selectGradText]} />
          </div>
          <div ref={cardRef} id="display-card-zizo">
            <div id="zizo-loader-text" style={{ textAlign: "center" }}>
              <h3 ref={modalGuidanceText}></h3>
            </div>
            <div
              id="zizo-loader"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img style={{ width: "40%" }} src={loading}></img>
            </div>
          </div>
          <div id="zizo-camera-container" style={{ position: "fixed" }}>
            <div id="zizo-camera-first-container">
              <div id="zizo-recording-btn">
                <div
                  ref={recordingRef}
                  id="btn-android-stop"
                  onClick={() => {
                    if (allowStopBtn) {
                      setAlignmentFlag(!alignmentFlag);
                      // track.stop();
                      redSVGRef.current.style.display = "block";
                      greenSVGRef.current.style.display = "none";
                      setSelectGradText(0);
                      takeMoreList[takeMoreList.length - 1] = true;
                      if (takeMoreList.length < listCount) {
                        takeMoreList.push(false);
                      }
                      clearInterval(arrowInterval);
                      if (track) {
                        cameraStarted = false;
                        track.stop();
                        track = null;
                      }
                      setApiFlag(false);
                      setPage("zizo");
                    }
                  }}
                ></div>
              </div>
            </div>
            <div id="zizo-camera-secound-container">
              <div ref={lambdaGuidRef} className="camera-box-inst-flex">
                <p className="inst-inside-title-flex">
                  Please focus on the damage by standing close to it
                </p>
              </div>
              <div ref={lambdaFeedbackRef} id="lambda-feebdack-cont">
                <p
                  style={{ color: "green" }}
                  ref={niranjanRef}
                  id="lambda-feedback-text"
                ></p>
              </div>
              <div id="zizo-recording-container">
                <img
                  ref={recordGifRef}
                  id="record_gif-flex"
                  src={recordGifFlag ? greenRecord : redRecord}
                  alt="123"
                />
                <p ref={recordGuidTxtRef} id="recordGuidText-flex">
                  Slowly move away from the car
                </p>
              </div>
              <div ref={zizoBtns} id="zizo-btns">
                <div id="zizo_btn_no-flex" onClick={ZizoNoBtnFn}>
                  <p className="zizo_btn_text-flex">No</p>
                </div>
                <div id="zizo_btn_yes-flex" onClick={ZizoYesBtnFn}>
                  <p className="zizo_btn_text-flex">Yes</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default NewZIZO;
