import React, { useEffect, useContext, useRef, useState } from "react";
import Button from "./Button";
import { AppContext } from "../context";
import * as tf from "@tensorflow/tfjs";
import { PartDetector } from "../partdetection_new/PartDetector";
import { Guidance } from "../guidance_new/GuidanceMain";
import drawArc from "../utils_new/DrawArc";
import drawArcVin from "../utils_new/DrawArcVIN";
import carsmall from "../img/carsmall.png";
import initRedImg from "../img/coverage_red.png";
import initBlueImg from "../img/coverage_blue.png";
import indicator from "../img/indicator.svg";
// vin image
import vinLocationCar from "../img/vinLocationCar.png";
import engineVin from "../img/engineVin.svg";
import windshieldVin from "../img/windshieldVin.svg";
import rightFrontDoorVin from "../img/rightFrontDoorVin.svg";
import underbodyRightFrontWheelVin from "../img/underbodyRightFrontWheelVin.svg";
import leftVinArrow from "../img/leftVinArrow.gif";
import RightVInArrow from "../img/RightVInArrow.gif";
import vinPointingArrow from "../img/vinPointingArrow.svg";
import vinCross from "../img/vin-cross.png";
import vinWindshield from "../img/vin-windshield.png";
import vinDoor from "../img/vin-door.png";
import vinWheel from "../img/vin-wheel.png";
import vinArrow from "../img/vin-arrow.png";

// vin images end
import Rotate from "./Rotate";
import {
  addImageToList,
  sendCoveredArea,
  setLog,
  lowLightImage,
  actualRegion,
  userLogs,
  patLogs,
  getModel,
  resumeFlowFinalSubmitHandler,
  setImgCOunt,
  vin_detection,
  translateText,
} from "../context/utils";

import * as constants from "../utils_new/Constants";
import arrowDown from "../img/down_arrow.gif";
import arrowUp from "../img/up_arrow.gif";
// import ReactGA from "react-ga4";
let fameInterval;
let logbugs = true;
const screenfull = require("screenfull");
var progressBar = require("progressbar.js");
let flowStop = false;
let processCompleted = false;
let minStop = false;
let notCoveredArea = "";
let showOnce = true;
let regionsAndAnglesMap = {};
for (let val of Object.values(constants.REGIONS))
  regionsAndAnglesMap[val] = new Set();
let allActualRegionDataLog = {};
let allActualRegionDataLogsCheck = {};
let finalRegionsMap = {};
let angleStop = true;
let regionsAngleCountThresh = 1;
let lowLightThreshold = 0.75;
let valuesToCountLowLightImage = [1, 2];
let greenStop = true;
let intervalId;
let intervalId2;
let lumaImageCountThreshold = 5;
let lowLightImageData = [];
let captured = 0;
let allActualRegionData = {};
let actualGuidanceRegion;
let seconds = 0;
let minutes = 0;
let WholeLightLumaData = "";
let data = {};
let track = null;
let angleDetectedGyro = [];
let currentUploadedPartCount = null;
let detectMlCheck = true;
let stopShowingNudgesDOWN = true;
let stopShowingNudgesUP = true;
let allGreenGlobal = false;
let hiddenRestrictedCard = false;
let imgValue = 0;
let afterFrameimgValue = 0;
let stopRecordingML = false;
let allowImgURLmain = {};
let regionWiseData = {};
let allFrameUpload = false;
let revolutionRegion = "";
let revolutionRegionlist = [];
let readGuide = false;
let stopTimeout = false;
let stopGuidingUser = "showGuiding";
let intervalVin;
let hideRecordbutton = true;
let tempVIN = "";
let availableVin = [
  "engineVin",
  "windshieldVin",
  "rightFrontDoorVin",
  "underbodyRightFrontWheelVin",
];
let onceStop = true;
let froudInterval;
let timeoutVin;

const Video = () => {
  const {
    stepCovered,
    setStepCovered,
    setScreen,
    currentBlock,
    condition,
    setCondition,
    config,
    token,
    mandatory,
    setMandatory,
    inspectionId,
    setStopPing,
    setCurrentPage,
    stopPing,
    restrictUserIfallNotGreen,
    setRestrictUserIfallNotGreen,
    clientId,
    caseId,
  } = useContext(AppContext);

  const arrowUpText = useRef();
  const arrowDownText = useRef();
  const upArrow = useRef();
  const downArrow = useRef();
  const timerRef = useRef();
  const textRef = useRef();
  const videoRef = useRef();
  const startBtn = useRef();
  const stopBtn = useRef();
  const canvasRef = useRef();
  const canvas1 = useRef();
  const canvas4 = useRef();
  const canvas6 = useRef();
  const canvas7 = useRef();
  const canvas2 = useRef();
  const screen3 = useRef();
  const canvas5 = useRef();
  const reelsRef = useRef();
  const modalRef = useRef();
  const modalImg = useRef();
  const settingsRef = useRef();
  const cardRef = useRef();
  const reviewRef = useRef();
  const warnRef = useRef();
  const backRef = useRef();
  const imgRef = useRef();
  const startRef = useRef();
  const completeRef = useRef();
  const incompleteRef = useRef();
  const completeImgRef = useRef();
  const completeImgRestrictedRef = useRef();
  const stopRef = useRef();
  const timeUpRef = useRef();
  const angleRef = useRef();
  const angleDetailRef = useRef();
  const lowLightRegion = useRef();
  const lowLightRegion2 = useRef();
  const restrictedReviewRef = useRef();
  const incompleteSecoundText = useRef();
  const ackoReviewRef = useRef();
  const modalGuidanceText = useRef();
  const loaderGuidanceText = useRef();
  const vinGuidePopup = useRef();
  const leftArrowVin = useRef();
  const rightArrowVin = useRef();
  const canvasVIN = useRef();
  const lowerMessagePopup = useRef();
  const lowerMessagePopupP1 = useRef();
  const lowerMessagePopupP2 = useRef();
  const textRefDiv = useRef();
  const dotCod = useRef();
  const askUserPopup = useRef();
  const vinDetecting = useRef();
  const successMssg = useRef();
  const retryPopupVin = useRef();
  const vinModalContainer = useRef();
  const storedValue = useRef(null);
  // const hRef = useRef();
  // const hRef2 = useRef();
  const partDetector = new PartDetector();
  const guidance = new Guidance(
    config["video-page"]["guidanceMessages"],
    clientId
  );
  const [disableStopRef, setDisableStopRef] = useState(false);
  const [hiddenStopRef, setStopHiddenRef] = useState(false);
  const [vinPosition, setVinPosition] = useState("windshieldVin");
  const [enableVInCanvas, setEnableVInCanvas] = useState(false);
  const [enableVInCanvas2, setEnableVInCanvas2] = useState(false);
  // engineVin , windshieldVin , rightFrontDoorVin , underbodyRightFrontWheelVin
  const [vinPositionAngle, setVinPositionAngle] = useState(0);
  // const [stopGuidingUser, setStopGuidingUser] = useState("showGuiding");
  const [vinDetected, setVinDetected] = useState(false);
  const [showVinModal, setShowVinModal] = useState(false);
  data = {
    text: config["video-page"]["dataText"],
    feedbacks: [],
    regionsCovered: guidance.regionsCoveredMap,
    angle: 0,
    region: "",
    nudges: { partToPoint: "", Nudge: "" },
  };

  const getTranslateText = (text) => {
    if (Object.keys(config["language"]).includes(text)) {
      return config["language"][text];
    } else {
      // const data1 = translateText(text, config["language"]["langCode"]);
      return text;
    }
  };

  const constraints = {
    video: Object.keys(config).includes("aspectRatio")
      ? {
          width: Object.keys(config).includes("resolution")
            ? config["resolution"]["width"]
            : 1920,
          height: Object.keys(config).includes("resolution")
            ? config["resolution"]["height"]
            : 1080,
          facingMode: "environment",
          aspectRatio: 4 / 3,
          zoom: {
            ideal: Object.keys(config).includes("zoom")
              ? config["zoom"]
                ? 0.5
                : 1
              : 0.5,
          },
        }
      : {
          width: Object.keys(config).includes("resolution")
            ? config["resolution"]["width"]
            : 1920,
          height: Object.keys(config).includes("resolution")
            ? config["resolution"]["height"]
            : 1080,
          facingMode: "environment",
          zoom: {
            ideal: Object.keys(config).includes("zoom")
              ? config["zoom"]
                ? 0.5
                : 1
              : 0.5,
          },
        },
    audio: false,
  };
  //const constraints = { video: { width: { exact: 1920 }, height: { exact: 1080 }, facingMode: "environment" }, audio: false }

  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf("android") > -1;
  const isIos = !!/iPhone|iPad|iPod/i.test(ua);
  const isChrome = !!window.chrome && ua.indexOf("chrome") > -1;
  const isFirefox = ua.indexOf("firefox") > -1;
  const isSafari = /^((?!chrome|android).)*safari/i.test(ua);

  let webCamPromise = null;
  let model = null;
  let active = true;
  let images = [];
  let endTimer = 0;
  let modalFlag = false;
  let startTimer = null;
  let allRegionsCovered = false;
  let allGreen = false;

  let detections = [];

  const isLandscape = () => window.innerWidth > window.innerHeight * 1.2;
  if (logbugs) {
    patLogs("Entered to Video module", inspectionId);
    userLogs({ position: 8, last_page: "video module", inspectionId });
    // ReactGA.send({
    //   hitType: "pageview",
    //   page: "Video module",
    //   title: "Video module",
    // });
  }
  logbugs = false;

  const sendLogData = async (data) => {
    userLogs(data);
  };

  const drawProgress = () => {
    let bar = new progressBar.Circle("#progress_loader", {
      strokeWidth: 6,
      easing: "easeInOut",
      duration: 6000,
      color: "#000000",
      trailColor: "#eee",
      trailWidth: 4,
      svgStyle: null,
      step: function (state, circle) {
        let value = Math.round(circle.value() * 100);
        if (value === 0) {
          circle.setText("");
        } else {
          circle.setText(`${value}%`);
        }
      },
    });
    return bar;
  };

  const getAllFrameUpload = () => {
    return allFrameUpload;
  };

  const captureImg = (imgURL, detections) => {
    imgValue = imgValue + 1;
    let frameData = guidance.getFramesToPush(
      imgURL,
      detections,
      stopRecordingML
    );
    currentUploadedPartCount = frameData["currentUploadedPartCount"];
    allFrameUpload = frameData["allFrameUpload"];
    console.log("allFrameUpload", allFrameUpload);
    let frames_to_push = [];
    if (frameData["framesToPush"].length > 1) {
      if (frameData["framesToPush"][1].length > 0) {
        afterFrameimgValue = afterFrameimgValue + 1;
      }
      frames_to_push = frameData["framesToPush"][1];
    }
    let region = Object.keys(config["video-page"]).includes("clientRegion")
      ? config["video-page"]["clientRegion"]
      : "eu";
    for (let i = 0; i < frames_to_push.length; i++) {
      if (
        frames_to_push[i] &&
        frames_to_push[i].hasOwnProperty("frameEncoded")
      ) {
        let frameEncoded = frames_to_push[i]["frameEncoded"];
        let angle = frames_to_push[i]["angle"];
        let web_region = frames_to_push[i]["region"];
        let videoData = frames_to_push[i];
        delete videoData.frameEncoded;
        addImageToList({
          name: `${captured}.jpg`,
          imgData: frameEncoded,
          tag: web_region,
          angle,
          web_region,
          region: region,
          videoData: JSON.stringify(frames_to_push[i]),
          dialStatus: finalRegionsMap,
        });
        captured++;
      }
    }
  };

  const detect360 = (data, angleStop, revolutionRegionCurrent) => {
    if (!showOnce) {
      return;
    }
    if (revolutionRegion === "" && revolutionRegionlist.length === 0) {
      revolutionRegion = revolutionRegionCurrent;
      return;
    }
    if (revolutionRegion !== revolutionRegionCurrent) {
      !revolutionRegionlist.includes(revolutionRegion) &&
        revolutionRegionlist.push(revolutionRegion);
      revolutionRegion = revolutionRegionCurrent;
    }
    if (
      revolutionRegionlist.length === 7 &&
      data.regionsCovered[revolutionRegion] === 2
    ) {
      !revolutionRegionlist.includes(revolutionRegionCurrent) &&
        revolutionRegionlist.push(revolutionRegionCurrent);
      revolutionRegion = revolutionRegionCurrent;
    }

    if (revolutionRegionlist.length === 8) {
      angleStop = false;
      const coveredSides = Object.values(data.regionsCovered).filter(
        (value) => value === 0
      ).length;
      if (coveredSides) setRecordStopBtnFlag(false);
      angleRef.current.style.display = "flex";
      if (showOnce) {
        lowLight();
        intervalId = setInterval(() => {
          lowLight();
        }, 2000);
        Object.keys(data.regionsCovered).map((e) => {
          if (
            data.regionsCovered[e] ===
              constants.REGION_COVERAGE_STATUS.NOT_COVERED ||
            data.regionsCovered[e] ===
              constants.REGION_COVERAGE_STATUS.PARTIALLY_COVERED
          ) {
            if (Object.keys(config["video-page"]).includes("region")) {
              notCoveredArea = ` ${config["video-page"]["region"][e]}, ${notCoveredArea}`;
            } else {
              notCoveredArea = ` ${e}, ${notCoveredArea}`;
            }
          }
        });

        showOnce = false;
        if (notCoveredArea.endsWith(", ")) {
          notCoveredArea = notCoveredArea.slice(0, -2);
        }
        if (["porto_seguros", "porto_seguros_uat"].includes(clientId))
          setUnCaptureList(notCoveredArea.split(", "));
        else angleDetailRef.current.innerHTML = notCoveredArea;
        drawArc(canvas4.current, data.angle, data.regionsCovered, clientId);
        patLogs("One revolution of car is completed", inspectionId);
      }
    }
  };

  const captureImgFrame = (byPassBlack) => {
    const cnv = canvasRef.current;
    const vw = videoRef.current;
    cnv.width = vw.videoWidth;
    cnv.height = vw.videoHeight;
    const ctx = cnv.getContext("2d");
    ctx.drawImage(vw, 0, 0);
    const imageData = ctx.getImageData(0, 0, cnv.width, cnv.height);
    const { data, width, height } = imageData;
    let blackPixelCount = 0;
    for (let i = 0; i < data.length; i += 4) {
      const [r, g, b] = [data[i], data[i + 1], data[i + 2]];
      const brightness = (r + g + b) / 3;
      if (brightness < 5) {
        blackPixelCount++;
      }
    }
    const totalPixels = width * height;
    const blackPercentage = (blackPixelCount / totalPixels) * 100;
    return byPassBlack
      ? cnv.toDataURL("image/jpeg")
      : blackPercentage < 100
      ? cnv.toDataURL("image/jpeg")
      : false;
  };

  // vin code
  const FraudImageUpload = () => {
    let img = captureImgFrame(true);
    let region = "eu";
    region = region === "eu" ? "eu-central-1" : "ap-south-1";
    addImageToList({
      name: `FroudDetection.jpg`,
      imgData: img,
      tag: "FroudDetection",
      web_region: null,
      angle: null,
      region: region,
      videoData: null,
      dialStatus: null,
    });
  };

  const calculateUserPositions = (variable, constant) => {
    const vinRegion = [
      "Front",
      "Front-Isometric-Left",
      "Side-Left",
      "Rear-Isometric-Left",
      "Rear",
      "Rear-Isometric-Right",
      "Side-Right",
      "Front-Isometric-Right",
      "Front",
    ];
    const variableIndex = vinRegion.indexOf(variable);
    const constantIndex = vinRegion.indexOf(constant);
    // Validate if both values exist in the array
    if (variableIndex === -1 || constantIndex === -1) {
      console.log("Invalid variable or constant value", variable, constant);
      return "undefined";
    }
    const totalRegions = vinRegion.length;
    // Calculate clockwise and counterclockwise distances considering circular nature
    const clockwiseDistance =
      (constantIndex - variableIndex + totalRegions) % totalRegions;
    const counterClockwiseDistance =
      (variableIndex - constantIndex + totalRegions) % totalRegions;
    // for same position
    if (clockwiseDistance === 0 || counterClockwiseDistance === 0) {
      return "same";
    }
    // move from right
    if (clockwiseDistance < counterClockwiseDistance) {
      return "left";
      // move from left
    } else if (counterClockwiseDistance < clockwiseDistance) {
      return "right";
      // can move from left or right, but choose to return "right"
    } else {
      return "left";
    }
  };
  const calculateCord = (detections, vinPosition) => {
    console.log("etections, vinPosition", detections, vinPosition);
    let scaleX = window.innerWidth / 128;
    let scaleY = window.innerHeight / 128;
    let part =
      vinPosition === "engineVin"
        ? "hood"
        : vinPosition === "windshieldVin"
        ? "front_glass"
        : vinPosition === "rightFrontDoorVin"
        ? "front_door"
        : vinPosition === "underbodyRightFrontWheelVin"
        ? "fender"
        : "";
    dotCod.current.style.display = "none";
    if (Array.isArray(detections) && detections.length > 0) {
      detections.forEach((detection) => {
        if (detection.label === part) {
          let [x, y, width, height] = detection.bbox;
          let centerX_128 = 0;
          let centerY_128 = 0;
          switch (vinPosition) {
            case "engineVin":
              centerX_128 = x + width / 2;
              centerY_128 = y + height;
              break;
            case "windshieldVin":
              centerX_128 = x + width * 0.9;
              centerY_128 = y + height * 0.9;
              break;
            case "rightFrontDoorVin":
              centerX_128 = x;
              centerY_128 = y;
              break;
            case "underbodyRightFrontWheelVin":
              centerX_128 = x + width / 2;
              centerY_128 = y + height / 3;
              // centerY_128 = y + height / 3;
              break;
            default:
              centerX_128 = 0;
              centerY_128 = 0;
              dotCod.current.style.display = "none";
              break;
          }
          let Xcod = centerX_128 * scaleX * 128;
          let Ycod = centerY_128 * scaleY * 128;
          console.log(centerX_128, centerY_128);
          dotCod.current.style.display = "flex";
          dotCod.current.style.top = `${Ycod.toFixed(2) - 50}px`;
          dotCod.current.style.left = `${Xcod.toFixed(2) - 160}px`;
        }
      });
    }
  };
  const updateVinDataUI = (
    variableRegion,
    angle,
    regionsCovered,
    canvasVIN,
    detections
  ) => {
    console.log(variableRegion, angle, regionsCovered, canvasVIN, detections);
    hideRecordbutton = false;
    let regionAccordingVin =
      tempVIN === "engineVin"
        ? "Front"
        : tempVIN === "windshieldVin"
        ? "Front"
        : tempVIN === "rightFrontDoorVin"
        ? "Side-Right"
        : tempVIN === "underbodyRightFrontWheelVin"
        ? "Front-Isometric-Right"
        : false;
    if (stopGuidingUser === "showGuiding") {
      const val = calculateUserPositions(variableRegion, regionAccordingVin);
      if (leftArrowVin.current) leftArrowVin.current.style.display = "none";
      if (rightArrowVin.current) rightArrowVin.current.style.display = "none";
      switch (val) {
        case "right":
          if (leftArrowVin.current) leftArrowVin.current.style.display = "flex";
          if (dotCod.current) dotCod.current.style.display = "none";
          lowerMessagePopup.current.style.display = "flex";
          lowerMessagePopupP1.current.innerHTML = getTranslateText(
            "Follow the direction and reached to VIN loaction"
          );
          lowerMessagePopupP2.current.innerHTML = getTranslateText("Move Left");
          break;
        case "left":
          if (rightArrowVin.current)
            rightArrowVin.current.style.display = "flex";
          if (dotCod.current) dotCod.current.style.display = "none";
          lowerMessagePopup.current.style.display = "flex";
          lowerMessagePopupP1.current.innerHTML = getTranslateText(
            "Follow the direction and reached to VIN loaction"
          );
          lowerMessagePopupP2.current.innerHTML =
            getTranslateText("Move Right");
          break;
        case "same":
          !stopTimeout &&
            setTimeout(() => {
              stopGuidingUser = "askUserVin";
            }, 5000);
          stopTimeout = true;
          calculateCord(detections, tempVIN);
          if (leftArrowVin.current) leftArrowVin.current.style.display = "none";
          if (rightArrowVin.current)
            rightArrowVin.current.style.display = "none";
          lowerMessagePopup.current.style.display = "flex";
          lowerMessagePopupP2.current.innerHTML = getTranslateText(
            "Please capture the Vin Code"
          );
          if (tempVIN === "engineVin") {
            lowerMessagePopupP1.current.innerHTML = getTranslateText(
              "Hold Position and open the front hood"
            );
          } else if (tempVIN === "windshieldVin") {
            lowerMessagePopupP1.current.innerHTML = getTranslateText(
              "Find the VIN on the Right side of the car windshield"
            );
          } else if (tempVIN === "rightFrontDoorVin") {
            lowerMessagePopupP1.current.innerHTML = getTranslateText(
              "VIN is located underside of the hood"
            );
          } else if (tempVIN === "underbodyRightFrontWheelVin") {
            lowerMessagePopupP1.current.innerHTML = getTranslateText(
              "Find the VIN below the front wheel"
            );
          }
          break;
        default:
          if (dotCod.current) dotCod.current.style.display = "none";
          if (lowerMessagePopup.current)
            lowerMessagePopup.current.style.display = "none";
          break;
      }
    } else if (stopGuidingUser === "askUserVin") {
      calculateCord(detections, tempVIN);
      if (leftArrowVin.current) leftArrowVin.current.style.display = "none";
      if (rightArrowVin.current) rightArrowVin.current.style.display = "none";
      if (lowerMessagePopup.current)
        lowerMessagePopup.current.style.display = "none";
      askUserPopup.current.style.display = "flex";
    } else if (stopGuidingUser === "detectVin") {
      if (dotCod.current) dotCod.current.style.display = "none";
      if (askUserPopup.current) askUserPopup.current.style.display = "none";
      if (leftArrowVin.current) leftArrowVin.current.style.display = "none";
      if (rightArrowVin.current) rightArrowVin.current.style.display = "none";
      if (retryPopupVin.current) retryPopupVin.current.style.display = "none";
      if (vinDetecting.current) vinDetecting.current.style.display = "flex";
      if (lowerMessagePopup.current)
        lowerMessagePopup.current.style.display = "flex";
      lowerMessagePopupP1.current.innerHTML = getTranslateText(
        "Place the VIN inside the Box for 10 second"
      );
      lowerMessagePopupP2.current.innerHTML = getTranslateText(
        "Please wait to detect the VIN"
      );
    } else if (stopGuidingUser === "retryPopup") {
      if (vinDetecting.current) vinDetecting.current.style.display = "none";
      if (lowerMessagePopup.current)
        lowerMessagePopup.current.style.display = "none";
    } else if (stopGuidingUser === "detectedVin") {
      if (retryPopupVin.current) retryPopupVin.current.style.display = "none";
      if (vinDetecting.current) vinDetecting.current.style.display = "none";
      if (lowerMessagePopup.current)
        lowerMessagePopup.current.style.display = "none";
      if (retryPopupVin.current) retryPopupVin.current.style.display = "none";
    } else if (stopGuidingUser === "differentLocation") {
      if (dotCod.current) dotCod.current.style.display = "none";
      if (askUserPopup.current) askUserPopup.current.style.display = "none";
    }

    drawArcVin(
      canvasVIN,
      angle,
      regionsCovered,
      regionAccordingVin,
      variableRegion
    );
  };

  const startCapturingVin = async () => {
    let img = captureImgFrame(false);
    let region = "eu";
    if (img) {
      let value = await vin_detection({
        image: img.replace(/^data:image\/jpeg;base64,/, ""),
        region: region === "eu" ? "eu-central-1" : "ap-south-1",
      });
      if (value) {
        clearTimeout(startCapturingVin);
        addImageToList({
          name: `VIN.jpg`,
          imgData: img,
          tag: "VIN",
          web_region: null,
          angle: null,
          region: region,
          videoData: null,
          dialStatus: null,
        });
        clearInterval(intervalVin);
        stopGuidingUser = "detectedVin";
        setVinDetected(true);
        vinDetecting.current.style.display = "none";
        setTimeout(() => {
          handleNext();
        }, 3000);
      }
    }
  };

  // vin code end

  const detectFrame = (video, model) => {
    console.log(active);
    // if (!guidance.pausePartCoveringAndFrameUpload) {
    let detections = [];
    let newImgURL = captureImgFrame(false);
    if (newImgURL) {
      tf.engine().startScope();

      const processedFrame = partDetector.processInput(video);
      // console.log("processedFrame", processedFrame);
      if (allRegionsCovered) {
        // guidance.pausePartCoveringAndFrameUpload = true;
      } else if (minutes === 7) {
        // guidance.pausePartCoveringAndFrameUpload = true;
      } else if (
        angleRef.current.style.display === "flex" ||
        stopRef.current.style.display === "flex"
      ) {
        // guidance.pausePartCoveringAndFrameUpload = true;
      } else if (
        angleRef.current.style.display === "none" ||
        stopRef.current.style.display === "none"
      ) {
        guidance.pausePartCoveringAndFrameUpload = false;
      }

      //add logs
      if (detectMlCheck) {
        detectMlCheck = false;
        //cardRef.current.style.display = "none";
        patLogs("part_detection_is_working", inspectionId);
      }

      model.executeAsync(processedFrame).then((predictions) => {
        // console.log("predictions", predictions);
        detections = partDetector.getDetectionObjects(predictions);
        // console.log("*******detections*****", detections);
        let [
          currentAngle,
          currentRegion,
          regionsCoveredMap,
          feedbacks,
          videoFreezeStatus,
          currentNudge,
          imgURL,
          allowImgURL,
          frameCountPerRegion,
          // ZoomOutFlag,
          revolutionRegionCurrent,
        ] = guidance.performChecksAndGetWarningMessage(
          detections,
          processedFrame,
          newImgURL
        );
        // console.log("cor", cor);
        allRegionsCovered = guidance.checkIfAllRegionsCovered();
        finalRegionsMap = regionsCoveredMap;
        allowImgURLmain = allowImgURL;
        regionWiseData = frameCountPerRegion;
        if (stopRecordingML && readGuide) {
          updateVinDataUI(
            currentRegion,
            currentAngle,
            storedValue.current,
            canvasVIN.current,
            detections
          );
        } else {
          if (canvas1.current) {
            drawArc(canvas1.current, currentAngle, regionsCoveredMap, clientId);
          }
          if (Object.keys(config["video-page"]).includes("videoNudges")) {
            if (currentNudge["Nudge"] === "UP") {
              upArrow.current.style.display = "flex";
              arrowUpText.current.innerHTML = Object.keys(
                config["video-page"]["videoNudges"]
              ).includes(currentNudge["partToPoint"].trim())
                ? config["video-page"]["videoNudges"][
                    currentNudge["partToPoint"].trim()
                  ]
                : getTranslateText(currentNudge["partToPoint"]);
              if (stopShowingNudgesUP) {
                setTimeout(() => {
                  upArrow.current.style.display = "none";
                  stopShowingNudgesUP = true;
                }, 2000);
              }
              stopShowingNudgesUP = false;
            } else if (currentNudge["Nudge"] === "DOWN") {
              downArrow.current.style.display = "flex";
              arrowDownText.current.innerHTML = Object.keys(
                config["video-page"]["videoNudges"]
              ).includes(currentNudge["partToPoint"].trim())
                ? config["video-page"]["videoNudges"][
                    currentNudge["partToPoint"].trim()
                  ]
                : getTranslateText(currentNudge["partToPoint"]);
              if (stopShowingNudgesDOWN) {
                setTimeout(() => {
                  downArrow.current.style.display = "none";
                  stopShowingNudgesDOWN = true;
                }, 2000);
              }
              stopShowingNudgesDOWN = false;
            }
          }
          if (allRegionsCovered) {
            angleStop = false;
            angleRef.current.style.display = "none";
            setTimeout(() => {
              if (flowStop) return;
              if (minStop) return;
              // document.getElementById("window-arc2").style.display = "none"
              if (greenStop) {
                lowLightImageData = [];
                stopRef.current.style.display = "flex";
                lowLight();
                drawArc(
                  canvas5.current,
                  data.angle,
                  data.regionsCovered,
                  clientId
                );
                intervalId2 = setInterval(() => {
                  lowLight();
                }, 2000);
              }
              greenStop = false;
              allGreen = true;
              allGreenGlobal = true;
              hiddenRestrictedCard = true;
              // guidance.pausePartCoveringAndFrameUpload = true;
            }, 3000);
          }
          detect360(data, angleStop, revolutionRegionCurrent);
        }
        if (imgURL) {
          captureImg(imgURL, detections);
        } else if (stopRecordingML) {
          captureImg(null, null);
        }
        data = {
          text: feedbacks[feedbacks.length - 1],
          feedbacks: feedbacks,
          regionsCovered: regionsCoveredMap,
          angle: currentAngle !== -1 ? currentAngle : data.angle,
          region: currentRegion,
          nudges: currentNudge,
        };
        actualGuidanceRegion = guidance.actualPartsCoveredMap;
        Object.keys(actualGuidanceRegion).map((e) => {
          allActualRegionDataLog.key = e;
          let takeOutValue = [];
          actualGuidanceRegion[e].forEach((i) => {
            takeOutValue.push(i);
          });
          allActualRegionDataLog[e] = takeOutValue;
        });

        if (
          JSON.stringify(allActualRegionDataLog) !==
          JSON.stringify(allActualRegionDataLogsCheck)
        ) {
          allActualRegionDataLog["VideoTimer"] = `${minutes} : ${seconds}`;
          allActualRegionDataLog["angleDetected"] = angleDetectedGyro;
          allActualRegionDataLog["DialStatus"] = finalRegionsMap;
          userLogs({
            position: 3,
            video_data: JSON.stringify(allActualRegionDataLog),
            inspectionId,
          });
          allActualRegionDataLogsCheck = allActualRegionDataLog;
        }
        if (active) requestAnimationFrame(() => detectFrame(video, model));

        tf.engine().endScope();
      });
    } else {
      if (active) requestAnimationFrame(() => detectFrame(video, model));
    }
    // } else {
    //   if (active) requestAnimationFrame(() => detectFrame(video, model));
    // }
  };

  const lowLightCalculation = (data) => {
    Object.keys(data).forEach((key) => {
      if (data[key].length > lumaImageCountThreshold) {
        const countOccurrences = (arr, value) =>
          arr.filter((element) => element === value).length;
        const lowLightData = valuesToCountLowLightImage
          .map((value) => countOccurrences(data[key], value))
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        if (lowLightData / data[key].length > lowLightThreshold) {
          if (Object.keys(config["video-page"]).includes("region")) {
            if (Object.keys(config["video-page"]["region"]).includes(key)) {
              !lowLightImageData.includes(
                config["video-page"]["region"][key]
              ) &&
                lowLightImageData.push(
                  `${config["video-page"]["region"][key]}`
                );
            } else {
              !lowLightImageData.includes(key) &&
                lowLightImageData.push(`${key}`);
            }
          } else {
            !lowLightImageData.includes(key) &&
              lowLightImageData.push(`${key}`);
          }
        }
      }
    });
    return lowLightImageData;
  };

  const lowLight = async () => {
    // hRef.current.style.display = "none";
    // hRef2.current.style.display = "none";
    let region = "";
    let data = await lowLightImage();
    WholeLightLumaData = data;
    let value = lowLightCalculation(data);
    if (value.length > 0) {
      value.map((e) => {
        region = `${e}, ${region}`;
      });
      region.endsWith(", ") && (region = region.slice(0, -2));
      // console.log(region, typeof region)
      // region = region + JSON.stringify(data)
      // hRef.current.style.display = "block"
      // hRef2.current.style.display = "block"
      // lowLightRegion.current.innerHTML = region
      // lowLightRegion2.current.innerHTML = region
    } else {
      // region = region + JSON.stringify(data)
      // lowLightRegion.current.innerHTML = region
      // lowLightRegion2.current.innerHTML = region
      // hRef.current.style.display = "none";
      // hRef2.current.style.display = "none";
    }
  };

  const updateUI = () => {
    if (textRef.current) {
      textRef.current.style.display = "block";
      textRef.current.innerHTML =
        data.text && data.text !== config["video-page"]["dataText"]
          ? data.text
          : "<span>&nbsp;&nbsp;</span>";
    }

    if (!isLandscape()) {
      warnRef.current.style.display = "flex";
    } else {
      warnRef.current.style.display = "none";
    }
    if (seconds >= 60) {
      minutes++;
      seconds = 0;
    }
    if (seconds < 10) {
      timerRef.current.innerText = "0" + minutes + ":" + "0" + seconds;
    } else {
      timerRef.current.innerText = "0" + minutes + ":" + seconds;
    }
    // if (minutes === 7 && !processCompleted) {
    //   if (
    //     Object.keys(config["video-page"]).includes("videoTimeoutRefreshLink")
    //   ) {
    //     stopBtn.current.style.display = "none";
    //   }
    //   angleStop = false;
    //   angleRef.current.style.display = "none";
    //   minStop = true;
    //   // guidance.pausePartCoveringAndFrameUpload = true;
    //   stopRef.current.style.display = "none";
    //   timeUpRef.current.style.display = "flex";
    //   document.getElementById("window-arc2").style.display = "none";
    // }
    timerRef.current.style.fontSize = "larger";
    timerRef.current.style.color = "white";
    timerRef.current.style.marginTop = "40px";
    timerRef.current.style.backgroundColor = "rgba(0,0,0,.7)";
    if (data.videoFreezeStatus) {
      // active = false;
      modalFlag = true;
      drawArc(canvas2.current, data.angle, data.regionsCovered, clientId);
    }
    drawArc(canvas6.current, data.angle, data.regionsCovered, clientId);
    drawArc(canvas7.current, data.angle, data.regionsCovered, clientId);
  };

  const reShoot = () => {
    startCamera();
  };

  const continueShoot = () => {
    modalFlag = false;
    active = true;
    detectFrame(videoRef.current, model);
  };

  const startCamera = () => {
    angleRef.current.style.display = "none";
    timeUpRef.current.style.display = "none";
    document.getElementById("window-arc2").style.visibility = "hidden";
    document.getElementById("btn-car-container").style.visibility = "hidden";
    cardRef.current.style.display = "none";
    if (!Object.keys(config).includes("directModule")) {
      backRef.current.style.display = "none";
    }
    startRef.current.style.display = "none";
    if (textRef.current) {
      textRef.current.style.display = "none";
    }
    reviewRef.current.style.display = "none";
    ackoReviewRef.current.style.display = "none";
    restrictedReviewRef.current.style.display = "none";
    warnRef.current.style.display = "none";
    imgRef.current.style.display = "block";
    stopRef.current.style.display = "none";
    modalGuidanceText.current.innerHTML = config["video-page"]["dataText"];
    loaderGuidanceText.current.innerHTML = config["video-page"]["loadingText"];
    if (!stopRecordingML) {
      drawArc(canvas1.current, data.angle, data.regionsCovered, clientId);
    }
    // settingsRef.current.innerHTML = config["video-page"]["settingsText"];
    // settingsRef.current.style.color = "black";
    // settingsRef.current.style.marginTop = "60px";
    // settingsRef.current.style.padding = "25px";
    webCamPromise = navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        window.stream = stream;
        track = stream.getTracks()[0];
        videoRef.current.srcObject = stream;
        startBtn.current.style.display = "block";
        if (!Object.keys(config).includes("directModule")) {
          backRef.current.style.display = "block";
        }
        if (textRef.current) {
          textRef.current.style.display = "block";
        }
        document.getElementById("btn-car-container").style.visibility =
          "visible";
        document.getElementById("window-arc2").style.visibility = "visible";
        settingsRef.current.style.display = "none";
        startRef.current.style.display = "flex";
        // if(screenfull.isEnabled) screenfull.request(screen3.current, {navigationUI: 'hide'})
        return new Promise(
          (resolve, _) => (videoRef.current.onloadedmetadata = () => resolve())
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const startRecording = () => {
    patLogs("camera button clicked", inspectionId);
    cardRef.current.style.display = "flex";
    if (textRef.current) {
      textRef.current.style.display = "none";
    }
    sendLogData({ position: 9, record_button: "yes", inspectionId });
    if (isIos || isSafari) DeviceMotionEvent.requestPermission();
    startBtn.current.style.display = "none";
    startRef.current.style.display = "none";
    if (!Object.keys(config).includes("directModule")) {
      backRef.current.style.display = "none";
    }
    cardRef.current.style.display = "flex";
    stopRef.current.style.display = "none";
    if (
      Object.keys(config).includes("directModule") &&
      config["directModule"]
    ) {
      drawProgress().animate(0.9);
    }
    settingsRef.current.style.display = "none";
    timerRef.current.style.width = "10%";
    timerRef.current.style.marginLeft = "45%";
    Promise.all([getModel(), webCamPromise])
      .then((values) => {
        sendLogData({
          position: 6,
          model_initialized: "yes",
          inspectionId,
        });
        model = values[0];
        detectFrame(videoRef.current, values[0]);
        // stopBtn.current.style.display = "block";
        startTimer = setInterval(() => {
          try {
            cardRef.current.style.display = "none";
          } catch (err) {}
          setDisableStopRef(true);
          if (hiddenStopRef) {
            if (stopBtn.current) {
              stopBtn.current.style.display = "none";
            }
          } else {
            if (stopBtn.current && onceStop) {
              stopBtn.current.style.display = "block";
            }
          }
          seconds++;
          endTimer++;
          requestAnimationFrame(() => updateUI());
        }, 1000);
        setCurrentPage("Model initialize");
      })
      .catch((error) => {
        console.log(error);
        sendLogData({
          position: 6,
          model_initialized: `no - ${error.message}`,
          inspectionId,
        });
        setCurrentPage("Model not initialize");
      });
  };

  const stopRecording = (clicked) => {
    if (storedValue.current === null) {
      storedValue.current = { ...finalRegionsMap }; // Store only once
      console.log("Stored value:2", storedValue.current);
    }
    stopRecordingML = true;
    onceStop = false;
    stopBtn.current.style.display = "none";
    stopRef.current.style.display = "none";
    vinGuidePopup.current.style.display = "flex";
    froudInterval = setInterval(() => {
      FraudImageUpload();
    }, 2000);
    sendCoveredArea(allGreen, finalRegionsMap, clientId, caseId, inspectionId);
    console.log(actualGuidanceRegion);
    if (!typeof actualGuidanceRegion === "undefined") {
      Object.keys(actualGuidanceRegion).map((e) => {
        allActualRegionData.key = e;
        let takeOutValue = [];
        actualGuidanceRegion[e].forEach((i) => {
          takeOutValue.push(i);
        });
        allActualRegionData[e] = takeOutValue;
      });
    }
    allActualRegionData["VideoTimer"] = `${minutes} : ${seconds}`;
    allActualRegionData["angleDetected"] = angleDetectedGyro;
    allActualRegionData["lowLightImage"] = WholeLightLumaData;
    allActualRegionData["DialStatus"] = finalRegionsMap;
    allActualRegionData["currentUploadedPartCount"] = currentUploadedPartCount;
    console.log("allActualRegionData", allActualRegionData);
    actualRegion(allActualRegionData, inspectionId);
    setImgCOunt(inspectionId, {
      regionWiseData,
      afterFrameimgValue,
      imgValue,
      ...allowImgURLmain,
      allActualRegionData,
    });
    textRefDiv.current.style.display = "none";
    patLogs("stop recording clicked", inspectionId);
    clearInterval(startTimer);
    delete allActualRegionData.lowLightImage;
    userLogs({
      position: 3,
      video_data: JSON.stringify(allActualRegionData),
      inspectionId,
    });
  };

  const showModal = (e) => {
    modalImg.current.src = images[e.target.dataset.index].imgData;
    modalRef.current.classList.add("active");
  };

  const hideModal = () => {
    modalRef.current.classList.remove("active");
  };

  const back = () => {
    logbugs = true;
    window.stream = null;
    track.stop();
    patLogs("clicked back button", inspectionId);
    setScreen("menu");
  };

  const handleNext = () => {
    clearInterval(froudInterval);
    active = false;
    logbugs = false;
    patLogs("video module is completed", inspectionId);
    setTimeout(() => {
      if (currentBlock["mandatory"] && captured > 0) {
        let temp1 = mandatory;
        temp1[currentBlock["id"]] = "completed";
        setMandatory(temp1);
      }
      if (captured > 0) {
        let temp = condition;
        temp[currentBlock["id"]] = "completed";
        setCondition(temp);
        setLog(`${currentBlock["tag"]} was captured`);
        resumeFlowFinalSubmitHandler(currentBlock["id"]);
      }
      fameInterval = setInterval(() => {
        console.log(
          "getAllFrameUpload",
          getAllFrameUpload,
          "allFrameUpload",
          allFrameUpload
        );
        if (getAllFrameUpload) {
          clearInterval(fameInterval);
          if (
            Object.keys(config).includes("directModule") &&
            config["directModule"]
          ) {
            setScreen("success");
          } else {
            setScreen("menu");
          }
        }
      }, 10);
    }, 1000);
  };

  useEffect(() => {
    setStopPing(null);
    startCamera();
  }, []);

  useEffect(() => {
    tempVIN = vinPosition;
    setVinPositionAngle(
      vinPosition === "engineVin"
        ? 0
        : vinPosition === "windshieldVin"
        ? 0
        : vinPosition === "rightFrontDoorVin"
        ? 90
        : vinPosition === "underbodyRightFrontWheelVin"
        ? 45
        : 0
    );
  }, [vinPosition]);

  return (
    <div id="screen3" ref={screen3} style={{ position: "relative" }}>
      <canvas
        ref={canvasRef}
        id="ios-canvas"
        style={
          Object.keys(config).includes("aspectRatio")
            ? {
                width: "100vw",
                height: "100vh",
                objectFit: "contain",
                backgroundColor: "black",
                position: "absolute",
                top: 0,
                left: 0,
              }
            : { position: "absolute", top: 0, left: 0 }
        }
      ></canvas>
      <video
        ref={videoRef}
        id="videoWindow"
        style={
          Object.keys(config).includes("aspectRatio")
            ? {
                width: "100vw",
                height: "100vh",
                objectFit: "contain",
                backgroundColor: "black",
                position: "absolute",
                top: 0,
                left: 0,
              }
            : { position: "absolute", top: 0, left: 0 }
        }
        autoPlay
        playsInline
      ></video>
      <p ref={timerRef} id="video-instructions"></p>
      <p
        style={{ display: "none" }}
        ref={settingsRef}
        id="video-instructions"
      ></p>
      <div id="btn-car-container"></div>
      {Object.keys(config).includes("directModule") &&
      config["directModule"] ? (
        ""
      ) : (
        <div ref={backRef} className="back-btn" style={{ color: "white" }}>
          <i class="fas fa-arrow-left fa-2x" onClick={back}></i>
        </div>
      )}
      {/* <div ref={backRef} className="back-btn" style={{ color: "white" }}>
        <i class="fas fa-arrow-left fa-2x" onClick={back}></i>
      </div> */}
      <div ref={startBtn} onClick={startRecording} id="btn-android-start"></div>
      <div ref={upArrow} className="upArrow">
        <img src={arrowUp} />
        <p>
          {Object.keys(config["video-page"]).includes("videoNudges")
            ? config["video-page"]["videoNudges"]["Point your camera to"]
            : getTranslateText("Point your camera to")}{" "}
          <span ref={arrowUpText}></span>
        </p>
      </div>
      <div ref={downArrow} className="downArrow">
        <img src={arrowDown} />
        <p>
          {Object.keys(config["video-page"]).includes("videoNudges")
            ? config["video-page"]["videoNudges"]["Point your camera to"]
            : getTranslateText("Point your camera to")}{" "}
          <span ref={arrowDownText}></span>
        </p>
      </div>
      <div
        ref={stopBtn}
        onClick={() =>
          hideRecordbutton && stopRef.current.style.display === "flex"
            ? ""
            : stopRecording(true)
        }
        id="btn-android-stop"
        style={{ visibility: hiddenStopRef ? "hidden" : "" }}
      ></div>
      <div id="window-arc2">
        <img src={carsmall} alt="" className="car" />
        {enableVInCanvas2 ? (
          <div className="canvas-wrapper">
            <canvas ref={canvasVIN} width="100" height="100"></canvas>
            <div
              className="rotating-arrow"
              style={{
                transform: `rotate(${
                  vinPositionAngle + 270
                }deg) translate(50px) rotate(-${vinPositionAngle + 270}deg)`,
              }}
            >
              <div
                style={{
                  transform: `rotate(${vinPositionAngle}deg)`,
                }}
              >
                <img src={vinPointingArrow} alt="Arrow" />
              </div>
            </div>
          </div>
        ) : (
          <canvas ref={canvas1} width="100" height="100"></canvas>
        )}
      </div>
      <div className="allgreenstop1" ref={stopRef}>
        <div className="pre-start-card" style={{ width: "40%", height: "70%" }}>
          <strong style={{ transform: "rotate(-90deg)" }}>
            {config["video-page"]["left-text"]}
          </strong>
          <div className="car-flex">
            <strong>{config["video-page"]["front-text"]}</strong>
            <canvas ref={canvas5} width="150" height="150"></canvas>
            <strong>{config["video-page"]["rear-text"]}</strong>
          </div>
          <strong style={{ transform: "rotate(90deg)" }}>
            {config["video-page"]["right-text"]}
          </strong>
          <div style={{ width: "20px" }}></div>
        </div>
        <div className="allgreenstop_bodyDiv">
          <h3 style={{ width: "90%" }}>{config["video-page"]["stopText"]}</h3>
          {/* <h4 style={{ margin: 0 }} ref={hRef}>
            {Object.keys(config["video-page"]).includes("lowLightRegion")
              ? config["video-page"]["lowLightRegion"]
              : "Regions in Low Light:"}{" "}
            <span
              ref={lowLightRegion}
              style={{ margin: 0, fontWeight: 100 }}
            ></span>
          </h4> */}
          <div
            className="modal-btn-container"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              marginDefault="0px"
              text={config["video-page"]["stopRecordingText"]}
              onclick={() => {
                setRestrictUserIfallNotGreen(true);
                clearInterval(intervalId2);
                stopRecording(true);
                clearInterval(startTimer);
              }}
            />
          </div>
        </div>
      </div>
      <div className="allgreenstop3" ref={timeUpRef}>
        <div className="allgreenstop3_title">
          <h2>
            {Object.keys(config["video-page"]).includes("timeup-text")
              ? config["video-page"]["timeup-text"]
              : getTranslateText("Video can only be seven minutes long.")}
          </h2>
        </div>

        {Object.keys(config["video-page"]).includes(
          "videoTimeoutRefreshLink"
        ) && (
          <>
            <div className="allgreenstop3_body">
              <h4 style={{ fontWeight: "600", margin: "0px", padding: "0px" }}>
                {Object.keys(
                  config["video-page"]["videoTimeoutRefreshLink"]
                ).includes("time-limitup-text-1")
                  ? config["video-page"]["videoTimeoutRefreshLink"][
                      "time-limitup-text-1"
                    ]
                  : getTranslateText(
                      "Due to our strict 7-minute policy, all videos and photos captured so far will be deleted."
                    )}{" "}
                <span style={{ color: "red" }}>
                  {Object.keys(
                    config["video-page"]["videoTimeoutRefreshLink"]
                  ).includes("time-limitup-text-2")
                    ? config["video-page"]["videoTimeoutRefreshLink"][
                        "time-limitup-text-2"
                      ]
                    : getTranslateText(
                        "You will need to restart the inspection process."
                      )}
                </span>
              </h4>
            </div>
            <div className="allgreenstop3_btn">
              <Button
                text={
                  Object.keys(
                    config["video-page"]["videoTimeoutRefreshLink"]
                  ).includes("restartText_btn")
                    ? config["video-page"]["videoTimeoutRefreshLink"][
                        "restartText_btn"
                      ]
                    : getTranslateText("Restart inspection")
                }
                onclick={() => {
                  location.reload();
                }}
              />
            </div>
          </>
        )}
      </div>
      {/* <div className="allgreenstop2" ref={timeUpRef}>
        <h3>
          {Object.keys(config["video-page"]).includes("timeup-text")
            ? config["video-page"]["timeup-text"]
            : "Video can only be seven minutes long."}
        </h3>
      </div> */}
      <div className="allgreenstop2" ref={angleRef}>
        <div className="pre-start-card" style={{ width: "40%", height: "70%" }}>
          <strong style={{ marginRight: "2px", transform: "rotate(-90deg)" }}>
            {config["video-page"]["left-text"]}
          </strong>
          <div className="car-flex">
            <strong style={{ marginBottom: "8px" }}>
              {config["video-page"]["front-text"]}
            </strong>
            <canvas ref={canvas4} width="150" height="150"></canvas>
            <strong style={{ marginTop: "10px" }}>
              {config["video-page"]["rear-text"]}
            </strong>
          </div>
          <strong style={{ transform: "rotate(90deg)" }}>
            {config["video-page"]["right-text"]}
          </strong>
          <div style={{ width: "20px" }}></div>
        </div>
        <div className="allgreenstop2_bodyDiv">
          <div className="allgreenstop2_innerDiv" style={{ width: "90%" }}>
            <h3 style={{ margin: "0px", padding: "0px" }}>
              {Object.keys(config["video-page"]).includes("one-revolution")
                ? config["video-page"]["one-revolution"]
                : getTranslateText(
                    "You have completed one revolution around the car."
                  )}
            </h3>
            <div className="notCoveredAreas">
              <p style={{ fontWeight: "600", margin: "0px", padding: "0px" }}>
                {Object.keys(config["video-page"]).includes(
                  "missed-region-text"
                )
                  ? config["video-page"]["missed-region-text"]
                  : getTranslateText(
                      "Continue to capture missed regions:"
                    )}{" "}
                <span
                  ref={angleDetailRef}
                  style={{ margin: 0, fontWeight: 100, color: "red" }}
                ></span>
              </p>
              {/* <p style={{ fontWeight: '300' }} ref={hRef2}>
              {Object.keys(config["video-page"]).includes("lowLightRegion")
                ? config["video-page"]["lowLightRegion"]
                : "Regions in Low Light:"}{" "}
              <span
                ref={lowLightRegion2}
                style={{ margin: 0, fontWeight: 100 }}
              ></span>
            </p> */}
            </div>
          </div>
          <div
            className="modal-btn-container"
            style={{ justifyContent: "flex-end" }}
          >
            {Object.keys(config["video-page"]).includes(
              "restrictUserIfallNotGreen"
            ) &&
              config["video-page"]["restrictUserIfallNotGreen"] &&
              allGreenGlobal && (
                <Button
                  marginDefault="0px"
                  secoundaryBtn="true"
                  text={config["video-page"]["stopRecordingText"]}
                  onclick={() => {
                    angleStop = false;
                    angleRef.current.style.display = "none";
                    stopRecording(true);
                    clearInterval(intervalId);
                  }}
                />
              )}
            <Button
              marginDefault="0px"
              text={config["video-page"]["continueText"]}
              onclick={() => {
                angleStop = false;
                angleRef.current.style.display = "none";
                guidance.pausePartCoveringAndFrameUpload = false;
                guidance.stage = 2;
                clearInterval(intervalId);
              }}
            />
          </div>
        </div>
      </div>
      <div ref={cardRef} id="display-card">
        <div className="pre-start-card">
          <strong style={{ marginRight: "2px", transform: "rotate(-90deg)" }}>
            {config["video-page"]["left-text"]}
          </strong>
          <div className="car-flex">
            <strong style={{ marginBottom: "8px" }}>
              {config["video-page"]["front-text"]}
            </strong>
            <img
              ref={imgRef}
              src={
                [
                  "porto_seguros",
                  "porto_seguros_uat",
                  "client_834",
                  "client_834_uat",
                ].includes(clientId)
                  ? initBlueImg
                  : initRedImg
              }
            ></img>
            <strong style={{ marginTop: "10px" }}>
              {config["video-page"]["rear-text"]}
            </strong>
          </div>
          <strong style={{ transform: "rotate(90deg)" }}>
            {config["video-page"]["right-text"]}
          </strong>
          <div style={{ width: "20px" }}></div>
        </div>
        <div className="card-info">
          <div>
            <div className="car-green"></div>
            <b>{config["video-page"]["covered-text"]}</b>
          </div>
          <div>
            <div
              className="car-red"
              style={{
                backgroundColor: [
                  "porto_seguros",
                  "porto_seguros_uat",
                  "client_834",
                  "client_834_uat",
                ].includes(clientId)
                  ? "#02CCFE"
                  : "#de524c",
              }}
            ></div>
            <b>{config["video-page"]["notcovered-text"]}</b>
          </div>
          <div className="card-note">
            <p ref={loaderGuidanceText} style={{ width: "100%" }}></p>
            {Object.keys(config).includes("directModule") &&
            config["directModule"] ? (
              <div className="progress" id="progress_loader"></div>
            ) : (
              <img
                id="model-loader"
                src="https://superapp-images-inspektlabs.s3.eu-central-1.amazonaws.com/app-icon/video_model_loader.gif"
                alt="abc"
              />
            )}
          </div>
        </div>
      </div>
      <div ref={startRef} id="stop-display-card">
        <div className="pre-start-card">
          <strong style={{ marginRight: "2px", transform: "rotate(-90deg)" }}>
            {config["video-page"]["left-text"]}
          </strong>
          <div className="car-flex">
            <strong style={{ marginBottom: "8px" }}>
              {config["video-page"]["front-text"]}
            </strong>
            <img
              ref={imgRef}
              src={
                [
                  "porto_seguros",
                  "porto_seguros_uat",
                  "client_834",
                  "client_834_uat",
                ].includes(clientId)
                  ? initBlueImg
                  : initRedImg
              }
            ></img>
            <strong style={{ marginTop: "10px" }}>
              {config["video-page"]["rear-text"]}
            </strong>
          </div>
          <strong style={{ transform: "rotate(90deg)" }}>
            {config["video-page"]["right-text"]}
          </strong>
          <div style={{ width: "20px" }}></div>
        </div>
        <div className="card-info">
          <div>
            <div className="car-green"></div>
            <b style={{ width: "90%" }}>
              {config["video-page"]["covered-text"]}
            </b>
          </div>
          <div>
            <div
              className="car-red"
              style={{
                backgroundColor: [
                  "porto_seguros",
                  "porto_seguros_uat",
                  "client_834",
                  "client_834_uat",
                ].includes(clientId)
                  ? "#02CCFE"
                  : "#de524c",
              }}
            ></div>
            <b style={{ width: "90%" }}>
              {config["video-page"]["notcovered-text"]}
            </b>
          </div>
          <div className="card-note">
            <p ref={modalGuidanceText}></p>
          </div>
        </div>
      </div>
      <div ref={textRefDiv}>
        <p ref={textRef} id="video-recording-text"></p>
      </div>
      {/* acko recent changes */}
      <div ref={ackoReviewRef} id="acko-review-card">
        <div className="pre-start-card" style={{ width: "40%", height: "70%" }}>
          <strong style={{ marginRight: "2px", transform: "rotate(-90deg)" }}>
            {config["video-page"]["left-text"]}
          </strong>
          <div className="car-flex">
            <strong style={{ marginBottom: "8px" }}>
              {config["video-page"]["front-text"]}
            </strong>
            <canvas ref={canvas7} width="150" height="150"></canvas>
            <strong style={{ marginTop: "10px" }}>
              {config["video-page"]["rear-text"]}
            </strong>
          </div>
          <strong style={{ transform: "rotate(90deg)" }}>
            {config["video-page"]["right-text"]}
          </strong>
          <div style={{ width: "20px" }}></div>
        </div>
        <div className="allgreenstop2_bodyDiv">
          <div
            style={{
              width: "85%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <h3 style={{ margin: "0px", padding: "0px" }} ref={incompleteRef}>
              {Object.keys(config["video-page"]).includes("newIncompleteText")
                ? config["video-page"]["incompleteText"]
                : getTranslateText("Are you sure you want to stop recording?")}
            </h3>
            <p id="incomplete-secoundText" ref={incompleteSecoundText}>
              {Object.keys(config["video-page"]).includes(
                "incomplete-secound-text"
              )
                ? config["video-page"]["incomplete-secound-text"]
                : ""}
            </p>
          </div>
          <div className="modal-btn-container">
            {!allGreenGlobal && (
              <Button
                secoundaryBtn="true"
                text={
                  config["video-page"]["ackoStopRecordingText"]
                    ? config["video-page"]["ackoStopRecordingText"]
                    : getTranslateText("Stop recording")
                }
                onclick={() => stopRecording2()}
              />
            )}
            {!allGreenGlobal && (
              <Button
                text={
                  config["video-page"]["continueRecordingText"]
                    ? config["video-page"]["restrictionBtn"]
                    : getTranslateText("Continue")
                }
                onclick={() => {
                  setStopHiddenRef(false);
                  ackoReviewRef.current.style.display = "none";
                  document.getElementById("window-arc2").style.top = "";
                  document.getElementById("window-arc2").style.left = "";
                  document.getElementById("window-arc2").style.right = "30px";
                  document.getElementById("window-arc2").style.bottom = "25px";
                  document.getElementById("window-arc2").style.zIndex = "";
                  document.getElementById("window-arc2").style.height = "79px";
                  document.getElementById("window-arc2").style.width = "79px";
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div ref={reviewRef} id="review-card">
        <h4 ref={completeRef}>{config["video-page"]["completeText"]}</h4>
        <h4 ref={incompleteRef}>{config["video-page"]["incompleteText"]}</h4>
        <canvas ref={canvas6} width="150" height="150"></canvas>
        <div className="review-btns">
          <Button
            text={config["video-page"]["btnText"]}
            onclick={() => {
              if (
                Object.keys(config["video-page"]).includes(
                  "restrictUserIfallNotGreen"
                ) &&
                config["video-page"]["restrictUserIfallNotGreen"]
              ) {
                if (allGreenGlobal) {
                  stopRecording2();
                } else {
                  reviewRef.current.style.display = "none";
                  document.getElementById("window-arc2").style.top = "";
                  document.getElementById("window-arc2").style.left = "";
                  document.getElementById("window-arc2").style.right = "30px";
                  document.getElementById("window-arc2").style.bottom = "25px";
                  document.getElementById("window-arc2").style.zIndex = "";
                  document.getElementById("window-arc2").style.height = "79px";
                  document.getElementById("window-arc2").style.width = "79px";
                }
              } else {
                stopRecording2();
              }
            }}
          ></Button>
        </div>
      </div>
      <div ref={restrictedReviewRef} id="review-restricted-card">
        <h4>
          {config["video-page"]["restrictionText"]
            ? config["video-page"]["restrictionText"]
            : getTranslateText(
                "You can't move to next step until you complete all section to green"
              )}
        </h4>
        <img ref={completeImgRestrictedRef} src={indicator}></img>
        <div className="review-btns">
          <Button
            text={
              config["video-page"]["restrictionBtn"]
                ? config["video-page"]["restrictionBtn"]
                : getTranslateText("Continue Recording")
            }
            onclick={() => {
              restrictedReviewRef.current.style.display = "none";
              document.getElementById("window-arc2").style.top = "";
              document.getElementById("window-arc2").style.left = "";
              document.getElementById("window-arc2").style.right = "30px";
              document.getElementById("window-arc2").style.bottom = "25px";
              document.getElementById("window-arc2").style.zIndex = "";
              document.getElementById("window-arc2").style.height = "79px";
              document.getElementById("window-arc2").style.width = "79px";
            }}
          ></Button>
        </div>
      </div>
      <div ref={warnRef} id="warn-card">
        <Rotate />
        <p className="note">{config["video-page"]["rotateText"]}</p>
      </div>
      {modalFlag && (
        <div id="screen3-modal">
          <div className="modal-column">
            <div className="full-center">
              <div>
                <h2 className="header2">
                  {getTranslateText(
                    "You have not taken the Front View properly"
                  )}
                </h2>
                <strong>{getTranslateText("Problems identified:")}</strong>
                <ol>
                  {data.feedbacks.map((warning) => (
                    <li key={warning}>{warning}</li>
                  ))}
                </ol>
                <p>
                  {getTranslateText(
                    "Would you like to reshoot from front or continue as it is?"
                  )}
                </p>
                <div className="modal-btn-flex">
                  <Button text="Reshoot" onclick={reShoot} />
                  <Button
                    text={getTranslateText("Continue")}
                    translucent={true}
                    onclick={continueShoot}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-column">
            <div className="full-center">
              <div>
                <canvas ref={canvas2} width="200" height="200"></canvas>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* VIN START */}

      <div className="vinDiv" ref={vinGuidePopup}>
        <div className="vinInnerDiv">
          <div className="divHead">
            <h3>Find the VIN in your car</h3>
          </div>
          <div className="divBody">
            <div className="indicateImage">
              <div
                className="indicateImagediv"
                style={
                  !vinPosition === "engineVin"
                    ? { border: "0px solid #006cc9" }
                    : {}
                }
              >
                <img
                  src={
                    vinPosition === "engineVin"
                      ? engineVin
                      : vinPosition === "windshieldVin"
                      ? windshieldVin
                      : vinPosition === "rightFrontDoorVin"
                      ? rightFrontDoorVin
                      : vinPosition === "underbodyRightFrontWheelVin" &&
                        underbodyRightFrontWheelVin
                  }
                />
              </div>
              {vinPosition === "engineVin" ? (
                <div>
                  <h4>
                    {getTranslateText("Engine Compartment under the hood")}
                  </h4>
                  <p>
                    {getTranslateText(
                      "VIN plate is located inside the wall of the hood, visible on opening the hood."
                    )}
                  </p>
                </div>
              ) : vinPosition === "windshieldVin" ? (
                <div>
                  <h4>
                    {getTranslateText("Bottom Right or Left of Windshield")}
                  </h4>
                  <p>
                    {getTranslateText(
                      "Most modern vehicles will have VIN at this location."
                    )}
                  </p>
                </div>
              ) : vinPosition === "rightFrontDoorVin" ? (
                <div>
                  <h4>{getTranslateText("Right Side Front Door Frame")}</h4>
                  <p>
                    {getTranslateText("Open the door to see the VIN sticker.")}
                  </p>
                </div>
              ) : (
                vinPosition === "underbodyRightFrontWheelVin" && (
                  <div>
                    <h4>
                      {getTranslateText("Underbody behind Right Front Wheel")}
                    </h4>
                    <p>
                      {getTranslateText(
                        "Turn the wheel to the right to check this location. This is for older vehicles."
                      )}
                    </p>
                  </div>
                )
              )}
            </div>
            <div
              className="markLine"
              style={
                vinPosition === "engineVin"
                  ? {
                      zIndex: 1,
                      width: "42%",
                      height: "3px",
                      top: "54px",
                      left: "123px",
                    }
                  : vinPosition === "windshieldVin"
                  ? {
                      zIndex: 1,
                      width: "58%",
                      height: "3px",
                      top: "54px",
                      left: "123px",
                    }
                  : vinPosition === "rightFrontDoorVin"
                  ? {
                      zIndex: 1,
                      width: "69%",
                      height: "3px",
                      top: "54px",
                      left: "123px",
                    }
                  : vinPosition === "underbodyRightFrontWheelVin" && {
                      zIndex: 1,
                      width: "58%",
                      height: "3px",
                      top: "54px",
                      left: "123px",
                    }
              }
            ></div>
            <div
              className="markLine"
              style={
                vinPosition === "engineVin"
                  ? {
                      zIndex: 1,
                      width: "3px",
                      height: "17%",
                      top: "54px",
                      left: "407px",
                    }
                  : vinPosition === "windshieldVin"
                  ? {
                      zIndex: 1,
                      width: "3px",
                      height: "6%",
                      top: "54px",
                      left: "516px",
                    }
                  : vinPosition === "rightFrontDoorVin"
                  ? {
                      zIndex: 1,
                      width: "3px",
                      height: "13%",
                      top: "56px",
                      left: "591px",
                    }
                  : vinPosition === "underbodyRightFrontWheelVin" && {
                      zIndex: 1,
                      width: "3px",
                      height: "16%",
                      top: "56px",
                      left: "516px",
                    }
              }
            ></div>
            <div
              className="markLine"
              style={
                vinPosition === "engineVin"
                  ? {
                      zIndex: 1,
                      width: "15px",
                      height: "15px",
                      top: "92px",
                      left: "401px",
                      borderRadius: "50%",
                      backgroundColor: "#FFB800",
                    }
                  : vinPosition === "windshieldVin"
                  ? {
                      zIndex: 1,
                      width: "15px",
                      height: "15px",
                      top: "66px",
                      left: "510px",
                      borderRadius: "50%",
                      backgroundColor: "#FFB800",
                    }
                  : vinPosition === "rightFrontDoorVin"
                  ? {
                      zIndex: 1,
                      width: "15px",
                      height: "15px",
                      top: "94px",
                      left: "585px",
                      borderRadius: "50%",
                      backgroundColor: "#FFB800",
                    }
                  : vinPosition === "underbodyRightFrontWheelVin" && {
                      zIndex: 1,
                      width: "15px",
                      height: "15px",
                      top: "102px",
                      left: "510px",
                      borderRadius: "50%",
                      backgroundColor: "#FFB800",
                    }
              }
            ></div>
            <img className="vinCarImage" src={vinLocationCar} />
            <button
              className="vinPopupButton"
              onClick={() => {
                guidance.pausePartCoveringAndFrameUpload = false;
                stopGuidingUser = "showGuiding";
                setEnableVInCanvas(true);
                setEnableVInCanvas2(true);
                readGuide = true;
                vinGuidePopup.current.style.display = "none";
                startCapturingVin();
                stopTimeout = false;
              }}
            >
              {getTranslateText("Ok got it")}
            </button>
          </div>
        </div>
      </div>

      <img ref={leftArrowVin} className="ArrowVin" src={leftVinArrow} />
      <img ref={rightArrowVin} className="ArrowVin" src={RightVInArrow} />

      <div ref={lowerMessagePopup} className="lowerMessagePopup">
        <div className="lowerMessagePopupdiv1">
          <p ref={lowerMessagePopupP1}></p>
        </div>
        <div className="lowerMessagePopupdiv2">
          <p ref={lowerMessagePopupP2}></p>
        </div>
      </div>

      <div ref={dotCod} className="cord">
        <div className="cordDivImage">
          <img
            src={
              vinPosition === "engineVin"
                ? engineVin
                : vinPosition === "windshieldVin"
                ? windshieldVin
                : vinPosition === "rightFrontDoorVin"
                ? rightFrontDoorVin
                : vinPosition === "underbodyRightFrontWheelVin" &&
                  underbodyRightFrontWheelVin
            }
          />
        </div>
        <div className="cordLine"></div>
        <div className="cordCircle"></div>
      </div>

      <div ref={askUserPopup} className="askUserPopup">
        <div>
          <p>{getTranslateText("If you find the VIN, then capture it.")}</p>
          <button
            onClick={() => {
              stopGuidingUser = "detectVin";
              intervalVin = setInterval(() => {
                startCapturingVin();
              }, 1000);
              timeoutVin = setTimeout(() => {
                clearInterval(intervalVin);
                stopGuidingUser = "retryPopup";
                if (retryPopupVin.current)
                  retryPopupVin.current.style.display = "flex";
              }, 10000);
            }}
          >
            {getTranslateText("Capture")}
          </button>
        </div>
        <div>
          <p>
            {getTranslateText("If you cannot find the VIN, then continue.")}
          </p>
          <button
            onClick={() => {
              availableVin = availableVin.filter((vin) => vin !== vinPosition);
              console.log(
                "vinPosition",
                vinPosition,
                "availableVin",
                availableVin
              );
              readGuide = false;
              setEnableVInCanvas(false);
              stopGuidingUser = "differentLocation";
              askUserPopup.current.style.display = "none";
              vinModalContainer.current.style.display = "flex";
            }}
          >
            {getTranslateText("Continue")}
          </button>
        </div>
      </div>

      <div ref={vinModalContainer} className="vinModalContainer">
        <div className="vinModalSubContainer">
          {availableVin.length === 0 ? (
            <>
              <p className="vinModalHeader">
                {getTranslateText(
                  "Sorry, but we have tried to find the VIN in all possible positions. You can now exit this process."
                )}
              </p>
              <button
                onClick={() => {
                  handleNext();
                }}
                className="vinModalButton"
              >
                {getTranslateText("Continue")}
              </button>
            </>
          ) : (
            <>
              <p className="vinModalHeader">
                {getTranslateText(
                  "Choose other location to capture the VIN No."
                )}
              </p>
              <div className="vinTypeContainer">
                {availableVin.map((e) => (
                  <div
                    className="vinModalSquare"
                    onClick={() => {
                      setVinPosition(e);
                      vinGuidePopup.current.style.display = "flex";
                      vinModalContainer.current.style.display = "none";
                    }}
                    key={e} // Adding a unique key to avoid React warnings
                  >
                    <img
                      className="vinModalMainImage"
                      src={
                        e === "windshieldVin"
                          ? windshieldVin
                          : e === "rightFrontDoorVin"
                          ? rightFrontDoorVin
                          : e === "engineVin"
                          ? engineVin
                          : e === "underbodyRightFrontWheelVin"
                          ? underbodyRightFrontWheelVin
                          : ""
                      }
                      alt="123"
                    />
                    <p className="vinModalSubHeader">
                      {e === "windshieldVin"
                        ? "Windshield"
                        : e === "rightFrontDoorVin"
                        ? "Door Frame"
                        : e === "engineVin"
                        ? "Engine Compartment"
                        : e === "underbodyRightFrontWheelVin"
                        ? "Underbody Front Wheel"
                        : ""}
                    </p>
                    <img className="vinModalArrow" src={vinArrow} alt="123" />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>

      <div
        ref={vinDetecting}
        style={
          vinDetected
            ? { border: "3px solid rgb(5, 220, 81)" }
            : { border: "3px solid #006cc9" }
        }
        className="vinDetecting"
      ></div>

      <div ref={retryPopupVin} className="retryPopupVin">
        <p>{getTranslateText("VIN not detected please retry capturing")}</p>
        <button
          onClick={() => {
            stopGuidingUser = "detectVin";
            retryPopupVin.current.style.display = "none";
            intervalVin = setInterval(() => {
              startCapturingVin();
            }, 1000);
            timeoutVin = setTimeout(() => {
              clearInterval(intervalVin);
              stopGuidingUser = "retryPopup";
              if (retryPopupVin.current)
                retryPopupVin.current.style.display = "flex";
            }, 10000);
          }}
        >
          {getTranslateText("Retry")}
        </button>
        <p>{getTranslateText("If you want to end the process")}</p>
        <button
          onClick={() => {
            retryPopupVin.current.style.display = "none";
            handleNext();
          }}
        >
          {getTranslateText("Continue")}
        </button>
      </div>

      {vinDetected && (
        <div className="successMssg">
          <p>{getTranslateText("Good Job Done! Moving to next task..")}</p>
        </div>
      )}
      {/* VIN END  */}
    </div>
  );
};

export default Video;
